import { FC } from "react"
import { ISummaryUnlisted, SummaryUnlisted } from "./summary-unlisted"
import { ISummaryUnlistedMatched, SummaryUnlistedMatched } from "./summary-unlisted-matched"
import { ISummaryPublic, SummaryPublic } from "./summary-public"
import { ISummaryPrivate, SummaryPrivate } from "./summary-private"

interface StaticComponents {
  Unlisted?: FC<ISummaryUnlisted>
  UnlistedMatched?: FC<ISummaryUnlistedMatched>
  Public?: FC<ISummaryPublic>
  Private?: FC<ISummaryPrivate>
}

export const Summary: FC & StaticComponents = () => null

Summary.Unlisted = SummaryUnlisted
Summary.UnlistedMatched = SummaryUnlistedMatched
Summary.Public = SummaryPublic
Summary.Private = SummaryPrivate
