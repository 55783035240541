import { FC, useMemo, useState } from "react"
import { useRouter } from "next/router"
import isMatch from "lodash/isMatch"
import round from "lodash/round"
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  NumberInput,
  NumberInputField,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react"
import { FaHome, FaInfoCircle, FaQuestionCircle } from "react-icons/fa"
import { useDisclosure } from "@chakra-ui/hooks"
import { Form, Formik, FormikHelpers } from "formik"
import { DefaultLayout } from "~/components/layouts"
import { SubmitListingsSteps } from "~/components/layouts/submit-listing/submit-listings-steps"
import { MEASUREMENT_UNITS, MEASUREMENT_UNITS_TEXTS } from "~/utils/constants"
import { Yup } from "~/utils/validation"
import { ButtonVariants, CardVariants } from "~/theme/components"
import { convertMeasurement } from "~/utils/helpers"
import { WithSessionProps } from "~/components/hocs/withSession"
import {
  FileType,
  GetPrivateListingQuery,
  HouseStyle,
  ListingBasement,
  PrivateListingHdStatus,
  PropertyFullQuery,
  useUpdatePrivateListingMutation,
} from "~/generated/graphql"
import { TermsAndConditionsModal } from "~/components/layouts/submit-listing/terms-and-conditions.modal"
import { IconHonestdoor } from "~/components/icons"
import { formatCurrency } from "~/utils"
import { useUser } from "~/components/hooks"
import { S3Dropzone, Tooltip } from "~/components/modules"
import theme from "~/theme"
import { PRIVATE_LISTING_ADDITIONAL_INFO } from "~/utils/constants/private-listing-additional-info"

export const submitPrivateListingValidationSchema = Yup.object().shape({
  // User
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().phoneNumber().required(),

  // Property
  houseStyle: Yup.string().required(),
  bedroomsTotal: Yup.number().integer().min(0).max(50).required(),
  bathroomsTotal: Yup.number().min(0).max(50).required(),
  basement: Yup.string(),
  yearBuiltActual: Yup.number().min(1650).max(2030),
  livingArea: Yup.number().min(100).max(1000000),
  lotSizeAreaValue: Yup.number().min(1).max(1000000),
  lotSizeAreaUnit: Yup.string().when(["lotSizeAreaValue"], {
    is: value => !!value,
    then: schema => schema.required("Land Size measurement is required"),
    otherwise: schema => schema.notRequired(),
  }),
  description: Yup.string().max(5000).required(),
  askingPrice: Yup.number().required().typeError("Asking price is not valid"),
  agreeWithTerms: Yup.boolean().oneOf([true], "Field must be checked"),
})

interface ISubmitListingDetailsLayout extends WithSessionProps {
  privateListing: GetPrivateListingQuery["getPrivateListing"]
  property?: PropertyFullQuery["property"]
}

export const SubmitListingDetailsLayout: FC<ISubmitListingDetailsLayout> = ({
  privateListing,
  property,
}) => {
  const [images, setImages] = useState([])
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  const { user } = useUser()
  const router = useRouter()
  const toast = useToast()
  const { isOpen: isOpenTsModal, onToggle: onToggleTsModal } = useDisclosure()
  const { mutateAsync: updatePrivateListing, isLoading } = useUpdatePrivateListingMutation()
  // const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } = useUpdateUserMutation()


  const additionalInfoKeys = useMemo(() => {
    const currentKeys = Object.keys(PRIVATE_LISTING_ADDITIONAL_INFO)
    const existingPrevKeys = privateListing.additionalInfo ? Object.keys(privateListing.additionalInfo).filter((key) => {
      return !currentKeys.includes(key) && !!privateListing.additionalInfo[key]
    }) : []
    return [...currentKeys, ...existingPrevKeys]
  }, [privateListing.additionalInfo])

  const initialValues = {
    // user
    name: privateListing.contactName || user?.name || "",
    phone: privateListing.contactPhone || user?.phone || "",
    email: privateListing.contactEmail || user?.email || "",

    // property
    houseStyle: privateListing.houseStyle || ("" as HouseStyle),
    bedroomsTotal: privateListing.bedroomsTotal || "",
    bathroomsTotal: privateListing.bathroomsTotal || "",
    basement: privateListing.basement || ("" as ListingBasement),
    yearBuiltActual: privateListing.yearBuiltActual || "",
    livingArea: privateListing.livingArea || "",
    lotSizeAreaValue: privateListing.lotSizeArea || "",
    lotSizeAreaUnit: privateListing.lotSizeArea
      ? MEASUREMENT_UNITS.SQM
      : ("" as ValueOf<typeof MEASUREMENT_UNITS>),
    description: privateListing.description || "",
    askingPrice: privateListing.askingPrice || "",
    agreeWithTerms: false,
    ...(additionalInfoKeys.reduce((acc, key) => {
      acc[`additionalInfo-${key}`] = privateListing.additionalInfo?.[key] || ''
      return acc
    }, {})),
  }

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    try {
      setSubmitting(true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, phone, email, agreeWithTerms, lotSizeAreaValue, lotSizeAreaUnit, ...rest } = values

      const additionalInfo = additionalInfoKeys.reduce((acc, key) => {
        if (rest[`additionalInfo-${key}`]) {
          acc[key] = rest[`additionalInfo-${key}`]
        }
        delete rest[`additionalInfo-${key}`]
        return acc
      }, {})

      await Promise.all([
        updatePrivateListing({
          id: privateListing.id,
          input: {
            ...rest,
            additionalInfo,
            contactName: values.name,
            contactPhone: values.phone,
            contactEmail: values.email,
            askingPrice: round(+values.askingPrice, 2),
            livingArea: +values.livingArea,
            yearBuiltActual: +values.yearBuiltActual,
            bathroomsTotal: +values.bathroomsTotal,
            bedroomsTotal: +values.bedroomsTotal,
            lotSizeArea: lotSizeAreaValue ? (
                lotSizeAreaUnit === MEASUREMENT_UNITS.SQM
                  ? +lotSizeAreaValue
                  : convertMeasurement(+lotSizeAreaValue, lotSizeAreaUnit, MEASUREMENT_UNITS.SQM)
              ) : null,
            basement: values.basement || null,
            hdListingStatus: privateListing.hdListingStatus === PrivateListingHdStatus.Na
              ? PrivateListingHdStatus.Pending
              : undefined,
            images:
              images.length > 0
                ? images.map(image => ({
                    privateListingId: privateListing.id,
                    url: image.url,
                    order: image.order,
                  }))
                : undefined,
          },
        }),
        // updateUser({ id: user.id, input: { name, phone } }),
      ])

      setSubmitting(false)
      toast({
        status: "success",
        title: "Success!",
        description: "Your form has been submitted!",
        position: "top-right",
        isClosable: true,
      })
      // await router.push(`/property/${privateListing.slug}`)
      await router.push("/profile?page=listings")
    } catch (e) {
      setSubmitting(false)
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  return (
    <DefaultLayout>
      <Box
        bgColor="white"
        p={6}
        borderRadius="lg"
        borderColor="#C9CDD9"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      >
        <Heading as="h1">List on Realtor.ca</Heading>
        <SubmitListingsSteps step={2} my={8} />

        <Stack direction="row" alignItems="center">
          <FaHome size={20} color={theme.colors.gold["500"]} />
          <Text fontWeight="bold">
            {[
              privateListing.streetAddress,
              privateListing.city,
              privateListing.province,
              "Canada",
            ].join(", ")}
          </Text>
        </Stack>

        <Card variant={CardVariants.CallToAction} my={6}>
          <Box
            position="absolute"
            top={0}
            left={0}
            backgroundColor="gold.500"
            width={1}
            height="100%"
          />

          <Stack direction="row" alignItems="center">
            <Flex px="32px" alignItems="center">
              <Icon as={FaInfoCircle} fontSize={32} color="gold.500" />
            </Flex>
            <Box>
              <Text>
                Please try and fill out the information and press submit. Don&apos;t worry if it
                needs to be changed.
              </Text>
              <Text>
                Feel free to email us at{" "}
                <Link href="mailto:support@honestdoor.com" textDecoration="underline">
                  support@honestdoor.com
                </Link>{" "}
                or call us at{" "}
                <Link href="tel:780-860-8400" textDecoration="underline">
                  780-860-8400
                </Link>
              </Text>
            </Box>
          </Stack>
        </Card>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={submitPrivateListingValidationSchema}
          enableReinitialize={true}
        >
          {({ handleSubmit, getFieldProps, errors, setFieldValue, touched, isSubmitting, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                  <SimpleGrid columns={[1, 1, 2, 4]} gap={4}>
                    <FormControl
                      label="houseStyle"
                      isInvalid={errors.houseStyle && touched.houseStyle}
                    >
                      <FormLabel>Property type</FormLabel>
                      <Select
                        {...getFieldProps("houseStyle")}
                        id="houseStyle"
                        placeholder="Select option"
                      >
                        {Object.entries(HouseStyle).map(([key, value]) => (
                          <option key={key} value={value}>
                            {key}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.houseStyle?.replace("houseStyle", "Property type")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="bedroomsTotal"
                      isInvalid={errors.bedroomsTotal && touched.bedroomsTotal}
                    >
                      <FormLabel>Bedrooms</FormLabel>
                      <NumberInput
                        {...getFieldProps("bedroomsTotal")}
                        inputMode="numeric"
                        onChange={val => setFieldValue("bedroomsTotal", val)}
                        id="bedroomsTotal"
                      >
                        <NumberInputField />
                      </NumberInput>
                      <FormErrorMessage>
                        {errors.bedroomsTotal?.replace("bedroomsTotal", "Bedrooms")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="bathroomsTotal"
                      isInvalid={errors.bathroomsTotal && touched.bathroomsTotal}
                    >
                      <FormLabel>Bathrooms</FormLabel>
                      <NumberInput
                        {...getFieldProps("bathroomsTotal")}
                        onChange={val => setFieldValue("bathroomsTotal", val)}
                        id="bathroomsTotal"
                      >
                        <NumberInputField />
                      </NumberInput>
                      <FormErrorMessage>
                        {errors.bathroomsTotal?.replace("bathroomsTotal", "Bathrooms")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl label="basement" isInvalid={errors.basement && touched.basement}>
                      <FormLabel>Basement</FormLabel>
                      <Select
                        {...getFieldProps("basement")}
                        id="basement"
                        placeholder="Select option"
                      >
                        {Object.entries(ListingBasement).map(([key, value]) => (
                          <option key={key} value={value}>
                            {key}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.basement?.replace("basement", "Basement")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="yearBuiltActual"
                      isInvalid={errors.yearBuiltActual && touched.yearBuiltActual}
                    >
                      <FormLabel>Year Built</FormLabel>
                      <NumberInput
                        {...getFieldProps("yearBuiltActual")}
                        onChange={val => setFieldValue("yearBuiltActual", val)}
                        id="yearBuiltActual"
                      >
                        <NumberInputField maxLength={4} inputMode="numeric" />
                      </NumberInput>
                      <FormErrorMessage>
                        {errors.yearBuiltActual?.replace("yearBuiltActual", "Year Built")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="livingArea"
                      isInvalid={errors.livingArea && touched.livingArea}
                    >
                      <FormLabel>Square Ft (ft²)</FormLabel>
                      <NumberInput
                        {...getFieldProps("livingArea")}
                        onChange={val => setFieldValue("livingArea", val)}
                        id="livingArea"
                        inputMode="numeric"
                      >
                        <NumberInputField />
                      </NumberInput>
                      <FormErrorMessage>
                        {errors.livingArea?.replace("livingArea", "Square Ft (ft²)")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="lotSizeAreaValue"
                      isInvalid={errors.lotSizeAreaValue && touched.lotSizeAreaValue}
                    >
                      <FormLabel>Land Size</FormLabel>
                      <NumberInput
                        {...getFieldProps("lotSizeAreaValue")}
                        onChange={val => setFieldValue("lotSizeAreaValue", val)}
                        id="lotSizeAreaValue"
                      >
                        <NumberInputField />
                      </NumberInput>
                      <FormErrorMessage>
                        {errors.lotSizeAreaValue?.replace("lotSizeAreaValue", "Land Size")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="lotSizeAreaMeasure"
                      isInvalid={errors.lotSizeAreaUnit && touched.lotSizeAreaUnit}
                    >
                      <FormLabel>Land Size measurement</FormLabel>
                      <Select
                        {...getFieldProps("lotSizeAreaUnit")}
                        id="lotSizeAreaUnit"
                        placeholder="Select unit"
                      >
                        {Object.entries(MEASUREMENT_UNITS).map(([key, value]) => {
                          return (
                            <option key={key} value={value}>
                              {MEASUREMENT_UNITS_TEXTS[value]}
                            </option>
                          )
                        })}
                      </Select>
                      <FormErrorMessage>
                        {errors.lotSizeAreaUnit?.replace(
                          "lotSizeAreaUnit",
                          "Land Size measurement",
                        )}
                      </FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>

                  <FormControl
                    label="description"
                    isInvalid={errors.description && touched.description}
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea {...getFieldProps("description")} id="description" />
                    <FormErrorMessage>
                      {errors.description?.replace("description", "Description")}
                    </FormErrorMessage>
                  </FormControl>

                  <Stack
                    spacing={4}
                    direction={["column", "column", "row"]}
                    alignItems={["start", "start", "center"]}
                  >
                    <FormControl
                      label="askingPrice"
                      isInvalid={errors.askingPrice && touched.askingPrice}
                      maxW={["100%", "100%", "50%"]}
                    >
                      <FormLabel>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Text>Asking Price</Text>
                          <Tooltip
                            label="As an example, if you wanted to list your home for 1.5 million, please enter 1500000"
                            placement="right"
                            hasArrow
                          >
                            <Text as="span" color="gray.500">
                              <FaQuestionCircle size={15} />
                            </Text>
                          </Tooltip>
                        </Stack>
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                          $
                        </InputLeftElement>
                        <Input {...getFieldProps("askingPrice")} id="askingPrice" />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.askingPrice?.replace("askingPrice", "Asking Price")}
                      </FormErrorMessage>
                    </FormControl>

                    {property?.predictedValue > 0 && (
                      <Box alignItems="center" ml={[0, 0, 3]}>
                        <FormLabel display={["none", "none", "initial"]}>&nbsp;</FormLabel>
                        <Flex alignItems="center">
                          <Box height={7} width={7} mr={2}>
                            <IconHonestdoor />
                          </Box>
                          <Box>
                            <Text as="span" fontWeight="bold" mr={2}>
                              {formatCurrency(property?.predictedValue)}
                            </Text>
                            <Text as="span">HonestDoor Price</Text>
                          </Box>
                          <Box>
                            <Tooltip
                              label={
                                "The HonestDoor Price is our estimate determined by our proprietary advanced statistical & machine learning methods. It is a starting point in determining a home's value and is not an official appraisal. Comparing your home to recently sold properties can also help you understand what your home is worth."
                              }
                              placement="right"
                              hasArrow
                            >
                              <Box ml={2} color="gray.500">
                                <FaQuestionCircle size={15} />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                  </Stack>

                  <Stack
                    spacing={4}
                    direction={["column", "column", "row"]}
                    alignItems={["start", "start", "center"]}
                  >
                    <FormControl
                      label="name"
                      isInvalid={errors.name && touched.name}
                      maxW={["100%", "100%", "50%"]}
                    >
                      <FormLabel>Contact Name</FormLabel>
                      <Input {...getFieldProps("name")} id="name" />
                      <FormErrorMessage>
                        {errors.name?.replace("name", "Contact Name")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="email"
                      isInvalid={errors.email && touched.email}
                      maxW={["100%", "100%", "50%"]}
                    >
                      <FormLabel>Contact Email</FormLabel>
                      <Input {...getFieldProps("email")} id="email" />
                      <FormErrorMessage>
                        {errors.email?.replace("email", "Contact Email")}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      label="phone"
                      isInvalid={errors.phone && touched.phone}
                      maxW={["100%", "100%", "50%"]}
                    >
                      <FormLabel>Contact Phone</FormLabel>
                      <Input {...getFieldProps("phone")} id="phone" />
                      <FormErrorMessage>{errors.phone?.replace("phone", "Contact Phone")}</FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Stack spacing={4} direction="row" my={4}>
                    <S3Dropzone
                      files={privateListing.images.map(image => ({ ...image, preview: image.url }))}
                      options={{
                        accept: {
                          "image/png": [".png"],
                          "image/jpeg": [".jpeg", ".jpg"],
                        },
                      }}
                      fileType={FileType.PrivateListingPhoto}
                      metadata={{ privateListingId: privateListing.id }}
                      onChange={setImages}
                      onLoadingChange={setIsLoadingImages}
                    />
                  </Stack>

                  {(
                    [PrivateListingHdStatus.Active, PrivateListingHdStatus.Pending, PrivateListingHdStatus.Na].includes(privateListing.hdListingStatus)
                    && additionalInfoKeys.length > 0
                  ) && (
                    <Card mb={3}>
                      <Heading as="h5" fontSize="18">
                        Additional information needed{" "}
                        <Text fontWeight="normal" color="gray.500" as="span">
                          (Feel free to fill this info in now or at a later date)
                        </Text>
                      </Heading>
                      <Flex gap={4} mt={4} wrap="wrap">
                        {additionalInfoKeys.map((key) => {
                          if (
                            PRIVATE_LISTING_ADDITIONAL_INFO[key].condition &&
                            !isMatch(values, PRIVATE_LISTING_ADDITIONAL_INFO[key].condition)
                          ) return null
                          return (
                            <FormControl
                              w={[
                                '100%',
                                '100%',
                                `calc(50% * ${PRIVATE_LISTING_ADDITIONAL_INFO[key].size} - 1rem)`,
                                `calc(25% * ${PRIVATE_LISTING_ADDITIONAL_INFO[key].size} - 1rem)`,
                              ]}
                              label={`additionalInfo-${key}`}
                              key={key}
                            >
                              <FormLabel>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  <Text>{PRIVATE_LISTING_ADDITIONAL_INFO[key].label}</Text>
                                  {PRIVATE_LISTING_ADDITIONAL_INFO[key].description ? (
                                    <Tooltip label={PRIVATE_LISTING_ADDITIONAL_INFO[key].description} placement="right"
                                             hasArrow>
                                      <Text as="span" color="gray.500"><FaQuestionCircle size={15} /></Text>
                                    </Tooltip>
                                  ) : null}
                                </Stack>
                              </FormLabel>
                              {!PRIVATE_LISTING_ADDITIONAL_INFO[key].inputType && (
                                <Input {...getFieldProps(`additionalInfo-${key}`)} id={key} />
                              )}
                              {PRIVATE_LISTING_ADDITIONAL_INFO[key].inputType === 'textarea' && (
                                <Textarea {...getFieldProps(`additionalInfo-${key}`)} id={key} />
                              )}
                              {PRIVATE_LISTING_ADDITIONAL_INFO[key].inputType === 'select' && (
                                <Select {...getFieldProps(`additionalInfo-${key}`)} id={key}
                                        placeholder="Select option">
                                  {PRIVATE_LISTING_ADDITIONAL_INFO[key].options?.map((option: string) => (
                                    <option key={`${key}-${option}`} value={option}>{option}</option>
                                  ))}
                                </Select>
                              )}
                            </FormControl>
                          )
                        })}
                      </Flex>
                    </Card>
                  )}

                  <Stack spacing={4} direction="row">
                    <FormControl
                      label="agreeWithTerms"
                      isInvalid={errors.agreeWithTerms && touched.agreeWithTerms}
                    >
                      <Checkbox {...getFieldProps("agreeWithTerms")} id="agreeWithTerms">
                        I agree with{" "}
                        <Link onClick={onToggleTsModal} textDecoration="underline">
                          terms & conditions
                        </Link>
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.agreeWithTerms?.replace("agreeWithTerms", "terms & conditions")}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Box textAlign="center" mt={6}>
                    <Button
                      type="submit"
                      variant={ButtonVariants.Secondary}
                      isLoading={isSubmitting || isLoading /* || isLoadingUpdateUser */}
                      isDisabled={isLoadingImages}
                      size="lg"
                      fontSize="16px"
                    >
                      Submit
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </Box>

      <TermsAndConditionsModal isOpen={isOpenTsModal} onClose={onToggleTsModal} />
    </DefaultLayout>
  )
}
