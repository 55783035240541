import React, { FC } from "react"
import { Box, Tag } from "@chakra-ui/react"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"
import { TextVariants } from "~/theme/components"

interface IValueHighlightProps {
  children: React.ReactNode
  /**
   * Determines what color scheme and icon are shown
   */
  highlight: "positive" | "negative" | "neutral"
  /**
   * If true an icon indicator is shown to the left of the value. Modify
   * `indicatorIcons` to change the icons that are displayed
   */
  showIndicator?: boolean
  /**
   * A dictionary of icons to use for the indicator
   */
  indicatorIcons?: { positive: JSX.Element; negative: JSX.Element; neutral: JSX.Element }
  /**
   * A dictionary of colors to use for the background of the highlight
   */
  backgroundColors?: { positive: string; negative: string; neutral: string }
  /**
   * A dictionary of colors to use for the text of the highlight
   */
  textColors?: { positive: string; negative: string; neutral: string }
}
/**
 * Inline text with a colorful highlight depending on whether it indicates a
 * positive, negative, or neutral change
 */
export const ValueHighlight: FC<IValueHighlightProps> = (props) => {
  const background = (highlight: IValueHighlightProps["highlight"]) => {
    switch (highlight) {
      case "negative":
        return props.backgroundColors ? props.backgroundColors.negative : "red.100"
      case "positive":
        return props.backgroundColors ? props.backgroundColors.positive : "green.100"
      default:
        return props.backgroundColors ? props.backgroundColors.neutral : "gray.300"
    }
  }
  const text = (highlight: IValueHighlightProps["highlight"]) => {
    switch (highlight) {
      case "negative":
        return props.textColors ? props.textColors.negative : "red.900"
      case "positive":
        return props.textColors ? props.textColors.positive : "green.900"
      default:
        return props.textColors ? props.textColors.neutral : "gray.900"
    }
  }

  const icon = (highlight: IValueHighlightProps["highlight"]) => {
    if (!props.showIndicator) return null

    switch (highlight) {
      case "negative":
        return props.indicatorIcons ? props.indicatorIcons.negative : <VscTriangleDown />

      case "positive":
        return props.indicatorIcons ? props.indicatorIcons.positive : <VscTriangleUp />

      default:
        return props.indicatorIcons ? props.indicatorIcons.neutral : null
    }
  }

  return (
    // uses tag as workaround to get component to work inline
    <Tag
      py={1}
      px={2}
      height={7}
      borderRadius="base"
      color={text(props.highlight)}
      bgColor={background(props.highlight)}
      variant={TextVariants.body2}
      fontWeight="500"
    >
      {!icon(props.highlight) ? null : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={6}
          width={6}
          ml={-2}
        >
          {icon(props.highlight)}
        </Box>
      )}
      {props.children}
    </Tag>
  )
}
