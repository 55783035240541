import { FC } from "react"
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  ModalBody,
  ModalHeader,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react"

const items = [
  "to provide truthful and accurate information about your property.",
  "to disclose to us and to any interested parties material facts affecting the value of the property, including history of growth or manufacture of illegal substances, any unusual detriment or limitation of uses, pending or ongoing litigation or foreclosure, or any hidden defects or any stigma or adverse history affecting the property.",
  "that your listing is for genuine purpose of a sale, and the listed property must be available for showings and for immediate registration of new offers. Using an MLS® listing as means of “illustrating” property value, i.e. to satisfy the lender when your property has already been sold, or for any other improper purpose, cannot be permitted.",
  "to let us know when the property becomes unavailable for sale for any reason.",
  "to report a sale to us at the time when you accept an offer to sell, even if the offer is still subject to conditions (i.e. financing/inspection), you must forward the offer to us within 24 hours.",
]

export const TermsAndConditionsModal: FC<Omit<ModalProps, "children">> = ({
  onClose = () => {},
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Terms and conditions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            <Text>In order to post on the MLS® System, you must agree:</Text>
            <UnorderedList>
              {items.map((item, key) => (
                <ListItem key={key}>{item}</ListItem>
              ))}
            </UnorderedList>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
