import { FC, useState } from "react"
import { Button, Text } from "@chakra-ui/react"
import { SectionHeader } from "~/components/layouts/property/sections"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { isPrivateListing } from "~/utils/helpers"
import { CreaListing, PrivateListing } from "~/generated/graphql"

interface IDescriptionProps {
  property: PrivateListing | CreaListing
}
export const Description: FC<IDescriptionProps> = ({ property }) => {
  // TODO: This would probably be better limiting by lines instead of characters
  const MAX_LENGTH = 600
  const [showMore, setShowMore] = useState(false)
  const description = isPrivateListing(property) ? property.description : property.publicRemarks

  let shownText = description
  if (description.length > MAX_LENGTH && !showMore) {
    shownText = description.slice(0, MAX_LENGTH) + "..."
  }

  if (!description) return null

  return (
    <>
      <SectionHeader title="Description" />
      <Text variant={TextVariants.body2}>{shownText}</Text>
      {description.length > MAX_LENGTH && (
        <Button mt={6} variant={ButtonVariants.Link} onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show Less" : "Show More"}
        </Button>
      )}
    </>
  )
}
