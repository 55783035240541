import { FC, useMemo } from "react"
import { SimpleGrid } from "@chakra-ui/react"
import { SectionContainer } from "~/components/elements"
import {
  Navbar,
  Description,
  MoreProperties,
  MortgageCTA,
  Brokerage,
  Rooms,
  PropertyDetails,
  BuyingOrSellingCTA,
  NeverMissAListingCTA,
  Summary,
  ImageGallery,
  ContactOwner,
} from "~/components/layouts/property/sections"
import { CreaListing, EsProperty, GetPrivateListingsQuery, PrivateListing } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

interface IPublicListingLayout {
  publicListing: CreaListing
  property?: EsProperty
  privateListing?: PrivateListing | GetPrivateListingsQuery['getPrivateListings'][0]
}

export const PublicListingLayout: FC<IPublicListingLayout> = ({
  property,
  publicListing,
  privateListing,
}) => {

  const show = useMemo(
    () => ({
      neverMissAListingCTA: publicListing?.address.neighbourhood,
    }),
    [publicListing, property],
  )

  return (
    <>
      <Navbar board={PROPERTY_BOARDS.CREA} property={publicListing} />
      <ImageGallery property={publicListing} />

      <SectionContainer showDivider={false}>
        <Summary.Public publicListing={publicListing} property={property} />
      </SectionContainer>

      {privateListing ? (
        <SectionContainer showDivider={false}>
          <ContactOwner board={PROPERTY_BOARDS.CREA} privateListing={privateListing} />
        </SectionContainer>
      ) : null}

      <SectionContainer>
        <Description property={publicListing} />
      </SectionContainer>

      <SectionContainer>
        <PropertyDetails board={PROPERTY_BOARDS.CREA} property={publicListing} />
      </SectionContainer>

      <SectionContainer>
        <MortgageCTA board={PROPERTY_BOARDS.CREA} property={publicListing} />
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid columns={{ base: 1, md: show.neverMissAListingCTA ? 2 : 1 }} gap={6}>
          <Rooms property={publicListing} />
          {show.neverMissAListingCTA && (
            <NeverMissAListingCTA
              board={PROPERTY_BOARDS.CREA}
              neighbourhoodId={publicListing.address.neighbourhoodId}
              neighbourhoodName={publicListing.address.neighbourhood}
            />
          )}
        </SimpleGrid>
      </SectionContainer>

      <SectionContainer>
        <Brokerage property={publicListing} />
      </SectionContainer>

      <SectionContainer>
        <BuyingOrSellingCTA
          board={PROPERTY_BOARDS.CREA}
          province={publicListing?.address.province}
          address={property?.unparsedAddress}
        />
      </SectionContainer>

      <SectionContainer>
        <MoreProperties board={PROPERTY_BOARDS.CREA} property={publicListing} />
      </SectionContainer>
    </>
  )
}
