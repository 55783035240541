import { FC, useMemo, useState } from "react"
import { Box, Button, Divider, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react"
import { FaEdit, FaStreetView } from "react-icons/fa"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { getAmenities, getLocation, getPrices } from "~/utils/helpers"
import { WatchPriceButton, StreetViewModal, CmaButton } from "~/components/modules"
import { InternalLink } from "~/components/elements"
import { SummaryAmenities } from "./summary-amenities"
import theme from "~/theme"
import { EsProperty } from "~/generated/graphql"
import { CreaListing } from "~/generated/graphql"
import gtag from "~/utils/lib/gtag"
import { provinces } from "~/utils/constants"
import router from "next/router"

export interface ISummaryUnlistedMatched {
  property: EsProperty
  publicListing: CreaListing
}

export const SummaryUnlistedMatched: FC<ISummaryUnlistedMatched> = ({
  property,
  publicListing,
}) => {
  const isMobile = useBreakpointValue([true, true, false])
  const [streetViewOpen, setStreetViewOpen] = useState(false)

  const {
    addressFormattedFull,
    addressFormattedMain,
    coordinates,
    estimatedPrice,
    month,
    bedrooms,
    bathrooms,
    floorSpace,
  } = useMemo(() => {
    const { addressFormatted, addressFormattedFull, addressFormattedMain, coordinates } =
      getLocation(property)
    const { bedrooms, bathrooms, floorSpace } = getAmenities(property)
    const { estimatedPrice, estimatedPriceDate } = getPrices(property)

    return {
      addressFormattedFull,
      addressFormattedMain,
      addressFormatted,
      coordinates,
      bedrooms,
      bathrooms,
      floorSpace,
      estimatedPrice,
      month: estimatedPriceDate
        ? new Date(estimatedPriceDate).toLocaleString("default", { month: "long" })
        : undefined,
    }
  }, [property])

  const onStreetViewClick = () => {
    gtag.event({
      action: "Property_google_streetview",
    })
    setStreetViewOpen(true)
  }

  return (
    <Box
      bgColor="#282F45"
      color="white"
      p={6}
      borderRadius="lg"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="gold.500"
        width="8px"
        height="100%"
      />

      <Flex justifyContent={["center", "center", "space-between"]}>
        <Flex
          alignItems="center"
          justifyContent={["center", "center", "start"]}
          marginTop={["8px", "8px", 0]}
        >
          <Box
            width={2}
            height={2}
            borderRadius="full"
            bgColor="green.500"
            marginRight={2}
            flexShrink={0}
          />
          <Text noOfLines={1} color="hdBlack.faded">
            This property is for sale
          </Text>
        </Flex>

        <Flex justifyContent="end" display={["none", "none", "flex"]}>
          {publicListing && (
            <Box>
              <InternalLink href={`/listing/${publicListing.slug}`} linkProps={{ mr: 2 }}>
                <Button variant="secondary">View listing</Button>
              </InternalLink>
            </Box>
          )}
          <Box>
            <WatchPriceButton
              property={property}
              unwatchedText="Track Home Value"
              watchedText="Home Value is Tracked"
              onClick={() => {
                gtag.event({
                  action: "Property_banner_track_value",
                })
              }}
            />
          </Box>
        </Flex>
      </Flex>

      <Flex
        direction={["column", "row", "row"]}
        justifyContent={["center", "center", "space-between", "space-between"]}
        alignItems={"center"}
      >
        <Text
          as="div"
          marginY="16px"
          variant={TextVariants.heading1}
          color="gold.500"
          fontSize={48}
          lineHeight="54px"
        >
          {publicListing.price
            ? `$${publicListing.price.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
            : "Coming Soon"}
        </Text>

        {!isMobile && (
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        )}
      </Flex>

      {estimatedPrice && (
        <Flex
          direction={["column", "column", "row"]}
          alignItems={["center", "center", "center"]}
          marginBottom={[0, 0, "12px"]}
          marginTop={["16px", "16px", 0, 0]}
        >
          <Box>
            <Text as="span" fontWeight="bold">
              {month}
            </Text>
            <Text as="span" color="hdBlack.faded" ml={2}>
              Estimated Market Value
            </Text>
          </Box>

          <Flex alignItems="center" direction={["column", "column", "row"]}>
            <Text as="span" color="gold.500" ml={[0, 0, 2, 2]} fontWeight="bold">
              ${estimatedPrice.toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Text>

            {[provinces.AB, provinces.BC].includes(property?.province) ? (
              <CmaButton property={property} buttonProps={{ ml: [0, 0, 4], mt: [3, 3, 0] }} />
            ) : (
              <Button
                variant={ButtonVariants.Link}
                color="gold.500"
                onClick={() => {
                  gtag.event({
                    action: "Property_banner_update_price",
                  })
                  router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
                }}
                leftIcon={<FaEdit size={14} />}
                ml={[0, 0, 4]}
                mt={[3, 3, 0]}
              >
                <Text display={["block", "block", "block", "block"]}>Update</Text>
              </Button>
            )}
          </Flex>
        </Flex>
      )}

      <Divider marginY={6} display={["block", "block", "none"]} />

      <Flex
        justifyContent="space-between"
        direction={["column", "column", "row", "row"]}
        alignItems={"center"}
      >
        <Box>
          <Flex
            direction={["column", "column", "column", "row"]}
            alignItems={["center", "center", "start", "start"]}
            textAlign="center"
          >
            <Heading as="h1" fontSize="17px" fontWeight="normal">
              {addressFormattedMain}
            </Heading>
          </Flex>
        </Box>

        <Divider marginY={6} display={["block", "block", "none"]} />
        {isMobile && (
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        )}

        {!isMobile && (
          <Box>
            <Button
              variant="outline"
              ml={[0, 2]}
              mt={[2, 0]}
              minW={["167px", 0]}
              leftIcon={<FaStreetView color={theme.colors.gold["500"]} size={16} />}
              onClick={onStreetViewClick}
              _hover={{ color: "inherit" }}
              color="inherit"
            >
              Street View
            </Button>
          </Box>
        )}

        <Divider marginY={6} display={["block", "block", "none"]} />

        {isMobile && (
          <Flex direction={["column", "row"]} justifyContent="center" alignItems="center">
            {publicListing && (
              <Box>
                <InternalLink href={`/listing/${publicListing.slug}`} linkProps={{ mr: [0, 2] }}>
                  <Button variant="secondary" mb={[2, 0]} minW={["167px", 0]}>
                    View listing
                  </Button>
                </InternalLink>
              </Box>
            )}
            <Box>
              <WatchPriceButton
                property={property}
                unwatchedText="Track Home Value"
                watchedText="Home Value is Tracked"
                onClick={() => {
                  gtag.event({
                    action: "Property_banner_track_value",
                  })
                }}
              />
            </Box>

            <Box>
              <Button
                variant="outline"
                ml={[0, 2]}
                mt={[2, 0]}
                minW={["167px", 0]}
                leftIcon={<FaStreetView color={theme.colors.gold["500"]} size={16} />}
                onClick={onStreetViewClick}
                _hover={{ color: "inherit" }}
                color="inherit"
              >
                Street View
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>

      <StreetViewModal
        address={addressFormattedFull}
        coordinates={coordinates}
        isOpen={streetViewOpen}
        onClose={() => setStreetViewOpen(false)}
      />
    </Box>
  )
}
