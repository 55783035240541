import { FC, useState } from "react"
import { useRouter } from "next/router"
import NextLink from "next/link"
import { useLazyQuery } from "@apollo/client"
import kebabCase from "lodash/kebabCase"
import gql from "graphql-tag"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { FaArrowRight, FaCheck, FaBell } from "react-icons/fa"
import { DefaultLayout } from "~/components/layouts"
import { SubmitListingsSteps } from "~/components/layouts/submit-listing/submit-listings-steps"
import { PropertiesAutocomplete } from "~/components/modules"
import { ButtonVariants } from "~/theme/components"
import { getAddressFormatted } from "~/utils/helpers"
import { provinceLongToProvinceAbbr } from "~/utils"
import {
  CustomAddress,
  MlsNotFoundAddressModal,
} from "~/components/layouts/submit-listing/mls-not-found-address.modal"
import {
  EsProperty,
  GetMlsListingPriceDocument,
  PrivateListingAddressStatus,
  useCreatePrivateListingMutation,
} from "~/generated/graphql"
import { WithSessionProps } from "~/components/hocs/withSession"
import gtag from "~/utils/lib/gtag"

export const SubmitListingLayout: FC<WithSessionProps> = ({ session }) => {
  const router = useRouter()
  const toast = useToast()
  const {
    user: { id: userId },
  } = session

  const [selectedProperty, setSelectedProperty] = useState<EsProperty | null>()
  const [customAddress, setCustomAddress] = useState<CustomAddress | null>(null)

  const [isEligible, setIsEligible] = useState<boolean | undefined>()
  const [isNotified, setIsNotified] = useState(false)

  const {
    isOpen: isOpenNotFoundModal,
    onOpen: onOpenNotFoundModal,
    onClose: onCloseNotFoundModal,
  } = useDisclosure()

  const [getMlsListingPrice, { loading: mlsListingPriceLoading }] = useLazyQuery(
    gql(GetMlsListingPriceDocument),
  )
  const { mutateAsync: createPrivateListing } = useCreatePrivateListingMutation()

  function onPropertySelect(property) {
    reset()
    setSelectedProperty(property)
    setCustomAddress(null)
  }

  function onCustomAddress(address: CustomAddress) {
    reset()
    setCustomAddress(address)
    setSelectedProperty(null)
  }

  async function checkEligibility() {
    try {
      // window.rdt("track", "Lead", { value: 100 })
      setIsEligible(undefined)

      if (selectedProperty?.creaListing?.listingId) {
        setIsEligible(false)
        return
      }

      const {
        data: { getMLSListingPrice: mlsListingPrice },
      } = await getMlsListingPrice({
        variables: {
          address: {
            province: selectedProperty ? selectedProperty.province : customAddress.province,
            city: selectedProperty ? selectedProperty.city : customAddress.city,
            streetAddress: selectedProperty
              ? selectedProperty.unparsedAddress
              : customAddress.streetAddress,
            postalCode: selectedProperty ? selectedProperty.postal : customAddress.postalCode,
          },
        },
      })
      setIsEligible(!!mlsListingPrice)
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  async function submit() {
    return createPrivateListing({
      input: {
        userId,
        propertyId: selectedProperty?.id || null,
        province: selectedProperty?.province || customAddress?.province || null,
        city: selectedProperty?.city || customAddress?.city || null,
        streetAddress: selectedProperty?.unparsedAddress || customAddress?.streetAddress || null,
        postalCode: selectedProperty?.postal || customAddress?.postalCode || null,
        latitude: selectedProperty?.location?.lat || null,
        longitude: selectedProperty?.location?.lon || null,
        addressStatus: isEligible
          ? PrivateListingAddressStatus.Eligible
          : PrivateListingAddressStatus.Uneligible,
        // TODO: improve slug generation
        slug:
          selectedProperty?.slug ||
          kebabCase(
            [
              customAddress?.streetAddress,
              customAddress?.city,
              provinceLongToProvinceAbbr(customAddress?.province),
              // TODO: random part?
            ].join("-"),
          ),
        bathroomsTotal: selectedProperty?.bathroomsTotal,
        bedroomsTotal: selectedProperty?.bedroomsTotal,
        livingArea: selectedProperty?.livingArea,
        lotSizeArea: selectedProperty?.lotSizeArea,
        yearBuiltActual: selectedProperty?.yearBuiltActual,

        // @TODO: string to ENUM
        // houseStyle: selectedProperty?.houseStyle,

        // @TODO: Enum mismatch???
        // basement: selectedProperty?.basement,
      },
    })
  }

  async function handleNextStep() {
    try {
      const {
        createPrivateListing: { id: privateListingId },
      } = await submit()

      gtag.event({
        category: "MLS",
        action: "list_on_mls_go_to_payment",
      })

      router.push(`/submit-listing/${privateListingId}/payment`)
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  async function notify() {
    if (isNotified) return

    try {
      await submit()
      setIsNotified(true)
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  function reset() {
    setIsNotified(false)
    setIsEligible(undefined)
    setSelectedProperty(undefined)
  }

  return (
    <DefaultLayout>
      <Box
        bgColor="white"
        p={8}
        mb={4}
        borderRadius="lg"
        borderColor="#C9CDD9"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      >
        <Heading as="h1">List on Realtor.ca</Heading>
        <SubmitListingsSteps step={0} my={8} />
        <Box maxWidth={720} margin="0 auto">
          <PropertiesAutocomplete
            onSelect={onPropertySelect}
            actions={[
              <Text
                key="not-found-link"
                color="hdBlack.300"
                fontWeight="normal"
                cursor="pointer"
                fontSize="sm"
                onClick={onOpenNotFoundModal}
              >
                Address not found?
              </Text>,
            ]}
          />

          {(selectedProperty || customAddress) && (
            <Card my={6} boxShadow="none">
              <Stack
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Box>
                    <Text color="hdBlack.300">Selected Address</Text>
                  </Box>
                  <Box>
                    {selectedProperty
                      ? getAddressFormatted(selectedProperty, "full")
                      : [
                          customAddress.streetAddress,
                          customAddress.city,
                          customAddress.province,
                          "Canada",
                        ].join(", ")}
                  </Box>
                </Box>
                <Box textAlign="center">
                  <Button
                    variant={ButtonVariants.Secondary}
                    rightIcon={<FaCheck />}
                    onClick={checkEligibility}
                    isDisabled={isEligible !== undefined}
                    isLoading={mlsListingPriceLoading}
                  >
                    This is my property&nbsp;
                  </Button>
                </Box>
              </Stack>
            </Card>
          )}

          {(selectedProperty || customAddress) && (
            <>
              {isEligible === true && (
                <Box textAlign="center">
                  <Text color="green.700" align="center" fontWeight="bold" mb={6}>
                    Great! Your address is eligible to be listed!
                  </Text>

                  <Flex justify="center" gap={6}>
                    <Button variant="link" onClick={reset}>
                      Cancel
                    </Button>
                    <Button
                      variant={ButtonVariants.Secondary}
                      rightIcon={<FaArrowRight />}
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  </Flex>
                </Box>
              )}

              {isEligible === false && (
                <>
                  {!selectedProperty?.creaListing?.listingId ? (
                    <>
                      {isNotified ? (
                        <Alert status="success" mt={6}>
                          <AlertIcon />
                          <AlertTitle>
                            Greet! We&apos;ll notify you as soon as we launch!
                          </AlertTitle>
                          <AlertDescription>
                            Back to{" "}
                            <Link as={NextLink} href="/" textDecoration="underline">
                              HonestDoor
                            </Link>
                          </AlertDescription>
                        </Alert>
                      ) : (
                        <Box textAlign="center">
                          <Text color="red.700" align="center" fontWeight="bold">
                            Shoot! Sorry, we are not able to list your property yet.
                            <br />
                            We are working on it!
                          </Text>

                          <Button
                            variant={ButtonVariants.Primary}
                            rightIcon={<FaBell />}
                            mt={6}
                            onClick={notify}
                            whiteSpace="normal"
                            wordBreak="break-word"
                            size="lg"
                          >
                            <Text as="span">
                              Get notified when we launch in
                              <Text as="span" fontWeight="bold" ml={1}>
                                {selectedProperty ? selectedProperty.city : customAddress.city}
                              </Text>
                            </Text>
                          </Button>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box textAlign="center">
                      <Text color="red.700" align="center" fontWeight="bold">
                        Sorry, this property appears to already be listed.
                        <br />
                        If this is a mistake, please reach out to us at{" "}
                        <a href="mailto:support@honestdoor.com">support@honestdoor.com</a>
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      <MlsNotFoundAddressModal
        isOpen={isOpenNotFoundModal}
        onClose={onCloseNotFoundModal}
        onSubmit={onCustomAddress}
      />
    </DefaultLayout>
  )
}
