import { FC, useEffect } from "react"
import dynamic from "next/dynamic"
import { Heading } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { Breadcrumbs, StickyToolbar } from "~/components/modules"
import { DefaultLayout } from "~/components/layouts"
import { useMapFiltersStore } from "~/store/StoreProvider"
import MapDrawerContent from "~/components/modules/Map/MapDrawer"
import gtag from "~/utils/lib/gtag"

const PageModal = dynamic(() => import("~/components/modules/page-modal"), {
  ssr: false,
})

const MapFilters = dynamic(() => import("~/components/modules/Map/MapFilters"), {
  ssr: false,
})

export const RealEstateLayout: FC = observer(() => {
  const { activeCategoryPageTitle } = useMapFiltersStore()

  useEffect(() => {
    gtag.event({
      action: "CREA_listings_loaded",
    })
  }, [])

  return (
    <>
      <StickyToolbar>
        <MapFilters />
      </StickyToolbar>

      <DefaultLayout>
        <Breadcrumbs />
        <Heading as="h1">{activeCategoryPageTitle}</Heading>
        <MapDrawerContent />
      </DefaultLayout>
      <PageModal />
    </>
  )
})
