import { FC, Fragment, useMemo, useState } from "react"
import router from "next/router"
import { Box, Button, Divider, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { compareDesc, format } from "date-fns"
import { IconPermit } from "~/components/icons"
import { SectionHeader } from "~/components/layouts/property/sections"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { PermitModal } from "./permit.modal"
import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { EsProperty, usePropertyPermitsQuery } from "~/generated/graphql"

// @TODO: move to some config file
const MAX_LENGTH = 3

interface IPermitsProps {
  property: EsProperty
}
export const Permits: FC<IPermitsProps> = ({ property }) => {
  const [showMore, setShowMore] = useState(false)
  const [permit, setPermit] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data } = usePropertyPermitsQuery({ slug: property.slug })

  const openPermit = permit => {
    setPermit(permit)
    onOpen()
  }

  const itemsData = useMemo(() => {
    const arr = data?.property?.permits || []
    return arr.sort((a, b) => {
      return compareDesc(new Date(a?.issuedDate), new Date(b?.issuedDate))
    })
  }, [data])
  const items = useMemo(() => {
    return showMore ? itemsData : itemsData.slice(0, MAX_LENGTH)
  }, [itemsData, showMore])

  if (itemsData?.length === 0) return null

  return (
    <div>
      <SectionHeader
        title="Permits"
        primaryButtonLabel={property.neighbourhoodName
          ? `Explore Nearby Permits`
          : `Explore City Permits`}
        onClickPrimaryButton={() =>
          router.push(
            createUrl({
              variant: "permits",
              city: property.cityName,
              provinceAbbr: provinceLongToProvinceAbbr(property.province),
              neighbourhood: property?.neighbourhoodName,
            }),
          )
        }
      />
      <Box>
        {items?.map((permit, i) => (
          <Fragment key={i}>
            {i > 0 && <Divider marginY={6} />}
            <Flex alignItems="center">
              <Box marginRight={8} cursor="pointer" onClick={() => openPermit(permit)}>
                <IconPermit />
              </Box>
              <Flex flexDirection="column">
                <Text variant={TextVariants.body2} marginBottom={2}>
                  {format(new Date(permit?.issuedDate), "MMM do yyyy")}
                </Text>
                <Flex>
                  <Text variant={TextVariants.body2} fontWeight="400" noOfLines={1}>
                    {permit?.jobDescription}
                  </Text>
                  <Button ml={2} variant={ButtonVariants.Link} onClick={() => openPermit(permit)}>
                    More
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Fragment>
        ))}
        {itemsData?.length > MAX_LENGTH && (
          <Button mt={6} variant={ButtonVariants.Link} onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </Button>
        )}
      </Box>
      <PermitModal isOpen={isOpen} onClose={onClose} permit={permit} />
    </div>
  )
}
