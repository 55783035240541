import { chakra, Box } from "@chakra-ui/react"

export const DescriptionList = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element
}): JSX.Element => (
  <chakra.dl m="0" display="flex" sx={{ "& > *": { width: "50%" } }}>
    {children}
  </chakra.dl>
)

export const DescriptionGroup = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element
}): JSX.Element => <Box overflowWrap="break-word">{children}</Box>

export const DescriptionTerm = ({
  children,
  uppercase = false,
  style = {},
}: {
  children: JSX.Element[] | JSX.Element | string
  uppercase?: boolean
  style?: Record<string, any>
}): JSX.Element => (
  <chakra.dt
    margin="0"
    color="hdBlack.300"
    textTransform={uppercase ? "uppercase" : "none"}
    textAlign="left"
    sx={style}
  >
    {children}
  </chakra.dt>
)

export interface DescriptionDetailProps {
  children: any
  big?: boolean
  multiline?: boolean
  center?: boolean
}

export const DescriptionDetail = ({
  children,
  big,
  multiline,
  center,
}: DescriptionDetailProps): JSX.Element => (
  <chakra.dd
    m="0"
    fontSize={big ? "24px" : "18px"}
    fontWeight={big ? "500" : "normal"}
    textAlign={center ? "center" : "left"}
    whiteSpace={multiline ? "pre" : "normal"}
    sx={multiline ? { span: { whiteSpace: "pre" } } : {}}
  >
    {children}
  </chakra.dd>
)
