import { FC, useMemo } from "react"
import { useRouter } from "next/router"
import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react"
import { compareAsc, isAfter, startOfMonth } from "date-fns"
import { SectionHeader } from "~/components/layouts/property/sections"
import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { PriceLineGraph } from "./price-line-graph"
import {
  EsProperty,
  useGetCityStatsQuery,
  useGetNeighbourhoodStatsQuery,
  Valuation,
} from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"
import gtag from "~/utils/lib/gtag"

export type ChartData = {
  date: Date
  value: number
}

interface IPriceHistoryProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty
}
export const PriceHistory: FC<IPriceHistoryProps> = ({ property, board }) => {
  const router = useRouter()
  const isMobile = useBreakpointValue([true, true, false])

  const {
    data: { getNeighbourhoodStats: neighbourhoodStats } = {},
    isLoading: neighbourhoodLoading,
  } = useGetNeighbourhoodStatsQuery({
    filter: {
      neighbourhoodId: property?.neighbourhood,
      assessmentClass: property?.assessmentClass,
      yearRange: {
        from: null,
        to: new Date().getFullYear(),
      },
    },
    take: 200,
  })

  const { data: { getCityStats: cityStats = [] } = {}, isLoading: cityLoading } =
    useGetCityStatsQuery({
      filter: {
        yearRange: {
          from: null,
          to: new Date().getFullYear(),
        },
        cityId: property?.city,
        assessmentClass: property?.assessmentClass,
      },
      take: 200,
    })

  const data = useMemo(() => {
    let valuations: Valuation[] = []
    if (property?.valuations?.length > 0 && property?.assessmentClass !== "Commercial") {
      // Filter out all valuations that happened before current model
      valuations = property.valuations
        .filter(
          v => isAfter(new Date(v.predictedDate), new Date(2019, 6, 1)), // July 1, 2019 (Months are 0-indexed)
        )
        .filter(
          v => v.predictedValue >= 50_000, // filtering outliers (bounds determined by data team)
        )
    }

    const honestDoorChart: ChartData[] = valuations
      .sort((a, b) => compareAsc(new Date(a.predictedDate), new Date(b.predictedDate)))
      .slice(-13)
      .map(({ predictedValue, predictedDate }) => {
        return {
          date: startOfMonth(new Date(predictedDate)),
          value: predictedValue,
        }
      })

    const cityChart: ChartData[] = property?.assessments
      .sort((a, b) => compareAsc(new Date(a.year), new Date(b.year)))
      .slice(-13)
      .map(({ value, year }) => {
        return {
          date: new Date(year, 1, 1),
          value: value,
        }
      })

    const cityAverage: ChartData[] = cityStats
      ?.sort((a, b) => compareAsc(new Date(a.year), new Date(b.year)))
      .map(({ year, avgAssessedValue }) => {
        return {
          date: new Date(year, 1, 1),
          value: avgAssessedValue,
        }
      })

    const neighbourhoodAverage: ChartData[] = neighbourhoodStats
      ?.sort((a, b) => compareAsc(new Date(a.year), new Date(b.year)))
      .map(({ year, avgAssessedValue }) => {
        return {
          date: new Date(year, 1, 1),
          value: avgAssessedValue,
        }
      })

    return { honestDoorChart, cityChart, cityAverage, neighbourhoodAverage }
  }, [neighbourhoodStats, cityStats, property])

  if (neighbourhoodLoading || cityLoading) return null
  if (data.honestDoorChart.length < 2 && data.cityChart.length < 2) return null

  return (
    <SimpleGrid columns={[1, 1, 1, 1, 2]} gridGap={10}>
      {data.honestDoorChart.length > 2 && (
        <Box>
          <SectionHeader
            title="HonestDoor Price"
            tootTip="The HonestDoor Price is our estimate determined by our proprietary advanced statistical & machine learning methods. It is a starting point in determining a home's value and is not an official appraisal. Comparing your home to recently sold properties can also help you understand what your home is worth."
            showHonestDoorLogo={!isMobile}
            showUpdateButton
            onClickUpdateButton={() => {
              if (board === PROPERTY_BOARDS.UNLISTED) {
                gtag.event({
                  action: "Property_graph_update_price",
                })
              }
              router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
            }}
          />
          <PriceLineGraph
            data={data.honestDoorChart}
            name="HonestDoor Price"
            returnsPeriod={[1, 3, 6, 12]}
            returnsRange="Month"
            displayStacked={data.cityChart.length > 2}
          />
        </Box>
      )}
      {data.cityChart.length > 2 && (
        <Box flexGrow={1}>
          <SectionHeader
            title="City Assessment"
            primaryButtonLabel={`Explore ${property?.cityName} Data`}
            onClickPrimaryButton={() => {
              router.push(
                createUrl({
                  variant: "cities",
                  city: property.cityName,
                  provinceAbbr: provinceLongToProvinceAbbr(property.province),
                }),
              )
            }}
          />
          <PriceLineGraph
            data={data.cityChart}
            cityData={data.cityAverage}
            neighbourhoodData={data.neighbourhoodAverage}
            name="City Assessment"
            returnsPeriod={[1, 3, 5, 10]}
            returnsRange="Year"
            displayStacked={data.honestDoorChart.length > 2}
          />
        </Box>
      )}
    </SimpleGrid>
  )
}
