import { chakra } from "@chakra-ui/system"

export const PageHeader = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element
}): JSX.Element => (
  <chakra.header textAlign="center" p="3em">
    {children}
  </chakra.header>
)
