import { FC, useMemo } from "react"
import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  StepperProps,
  useBreakpointValue,
} from "@chakra-ui/react"

const steps = [
  { title: "Property Address" },
  { title: "Listing Payment" },
  { title: "Property Details" },
]

interface ISubmitListingsSteps {
  step: number
}

export const SubmitListingsSteps: FC<ISubmitListingsSteps & Partial<StepperProps>> = ({
  step,
  ...props
}) => {
  const isMobile = useBreakpointValue([true, false, false])
  const { activeStep } = useSteps({
    index: step,
    count: steps.length,
  })

  const responsiveProps = useMemo<Partial<StepperProps>>(() => {
    if (isMobile) {
      return {
        orientation: "vertical",
      }
    }

    return { orientation: "horizontal" }
  }, [isMobile])

  return (
    <Stepper index={activeStep} {...responsiveProps} {...props}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  )
}
