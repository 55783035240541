import { FC, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { Box, Button, Grid, Text, useBreakpointValue } from "@chakra-ui/react"
import {
  FaBath,
  FaBed,
  FaRuler,
  FaWrench,
  FaClock,
  FaAddressCard,
  FaBuilding,
  FaRegBuilding,
} from "react-icons/fa"
import { BsGridFill } from "react-icons/bs"
import { MdHouse, MdHouseSiding } from "react-icons/md"
import { TextVariants } from "~/theme/components"
import { SectionHeader } from "~/components/layouts/property/sections"
import {
  getBathrooms,
  getBedrooms,
  getBuilt,
  getFloorSpace,
  calcAreaFromSqft,
  getGarage,
  getZoning,
  getPropertyType,
  isPublicListing,
  calcLotSizeArea,
} from "~/utils/helpers"
import { PROPERTY_BOARDS, PROPERTY_TYPES } from "~/utils/constants"
import { timeAgo } from "~/utils"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"

import {} from "~/components/hooks"
import gtag from "~/utils/lib/gtag"

const MAX_DETAILS = 7

interface IPropertyDetailsProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty | PrivateListing | CreaListing
}
export const PropertyDetails: FC<IPropertyDetailsProps> = ({ property, board }) => {
  const router = useRouter()
  const [showMore, setShowMore] = useState(false)
  const numberOfColumns = useBreakpointValue([1, 1, 2, 4])
  const templateColumns = `repeat(${numberOfColumns}, 1fr)`

  const { details } = useMemo(() => {
    const { value: bedrooms, estimate: isBedroomsEstimate } = getBedrooms(property)
    const { value: bathrooms, estimate: isBathroomsEstimate } = getBathrooms(property)
    const { value: built, isRounded: isBuiltRounded } = getBuilt(property)
    const { value: garage } = getGarage(property)
    const { value: floorSpace, estimate: isFloorSpaceEstimate } = getFloorSpace(property)
    const { value: zoning } = getZoning(property)
    const propertyType = getPropertyType(property)
    const { value: landSpace, estimate: isLandSpaceEstimate } = calcLotSizeArea(property)
    const createdTimeAgo = property.createdAt ? timeAgo(property.createdAt, "") : undefined
    const mlsNumber = isPublicListing(property) ? property.listingId : undefined
    const buildingType = isPublicListing(property) ? property?.building?.type : undefined

    const details = [
      {
        label: propertyType && `Property Type: ${propertyType}`,
        icon: <FaBuilding />,
      },
      {
        label: buildingType && `Building Type: ${buildingType}`,
        icon: <FaRegBuilding />,
      },
      {
        label: bedrooms && `${bedrooms} Bedroom${bedrooms > 1 ? "s" : ""}`,
        icon: <FaBed />,
        estimate: isBedroomsEstimate,
      },
      {
        label: bathrooms && `${bathrooms} Bathroom${bathrooms > 1 ? "s" : ""}`,
        icon: <FaBath />,
        estimate: isBathroomsEstimate,
      },
      {
        label: built && `Built in ${built}${isBuiltRounded ? "'s" : ""}`,
        icon: <FaWrench />,
      },
      {
        label: garage && `Garage space`,
        icon: <MdHouseSiding size={23} />,
      },
      {
        label: floorSpace && `${calcAreaFromSqft(floorSpace)} floor space`,
        icon: <FaRuler />,
        estimate: isFloorSpaceEstimate,
      },
      ...(![PROPERTY_TYPES.CONDO].includes(propertyType)
        ? [
            {
              label: landSpace && `${landSpace} lot size`,
              icon: <MdHouse size={23} />,
              estimate: isLandSpaceEstimate,
            },
          ]
        : []),
      {
        label: zoning && `Zoning: ${zoning}`,
        icon: <BsGridFill />,
      },
      {
        label: mlsNumber && `MLS number: ${mlsNumber}`,
        icon: <FaAddressCard />,
      },
      {
        label: createdTimeAgo && `Time on HonestDoor: ${createdTimeAgo}`,
        icon: <FaClock />,
      },
    ].filter(detail => !!detail.label)

    return { details }
  }, [property])

  const items = useMemo(() => {
    return showMore ? details : details.slice(0, MAX_DETAILS)
  }, [details, showMore])

  if (details.length === 0) return null

  return (
    <>
      <SectionHeader
        title="Property Details"
        {...((board === PROPERTY_BOARDS.UNLISTED || board === PROPERTY_BOARDS.PRIVATE) && {
          onClickUpdateButton: () => {
            if (board === PROPERTY_BOARDS.UNLISTED) {
              gtag.event({
                action: "Property_details_update",
              })
            }
            router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
          },
          showUpdateButton: true,
        })}
      />
      <Grid templateColumns={templateColumns} gap={[2, 2, 4]}>
        {items.map((detail, i) => (
          <Box key={i} minHeight={9} display="flex" alignItems="center">
            <Box width={6} height={6} display="flex" alignItems="center" justifyContent="center">
              {detail.icon}
            </Box>
            <Text variant={TextVariants.body2} marginLeft={2} noOfLines={2}>
              {detail.label}
              {detail.estimate && (
                <Text as={"span"} color="gold.500" ml={2}>
                  Estimate
                </Text>
              )}
            </Text>
          </Box>
        ))}

        {details?.length > MAX_DETAILS && (
          <Box display="flex" alignItems="center" minHeight={9}>
            <Button variant="link" onClick={() => setShowMore(!showMore)}>
              {showMore ? "Show Less" : "Show More"}
            </Button>
          </Box>
        )}
      </Grid>
    </>
  )
}
