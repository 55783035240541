import React, { FC, useEffect, useRef, useState } from "react"
import { DefaultLayout } from "~/components/layouts"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ButtonVariants, CardVariants } from "~/theme/components"
import { ExclusiveListings } from "~/components/layouts/landing/sections/ExclusiveListings"
import { PrivateListing, CreaListing } from "~/generated/graphql"
import { CashBackCalculator, MultiCarousel } from "~/components/modules"
import { PropertyCard } from "~/components/helpers/cards"
import { SortOption } from "~/store/creaFilters"
import { AskAboutPropertyModal } from "~/components/modules/private-lead-button/ask-about-property.modal"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import gtag from "~/utils/lib/gtag"

// const bulletPoints = [
//   {
//     title: "Save big on commission fees",
//     desc: "",
//   },
//   {
//     title: "No hidden costs",
//     desc: "",
//   },
//   {
//     title: "Advertised everywhere online",
//     desc: "",
//   },
//   {
//     title: "Full transparency",
//     desc: "",
//   },
// ]

interface IBuyLayout {
  privateListings: PrivateListing[]
  creaListings: CreaListing[]
}

export const BuyPropertyLayout: FC<IBuyLayout> = ({ privateListings, creaListings }) => {
  const [scrollTo, setScrollTo] = useState<string>()
  const faqRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (faqRef && faqRef.current && scrollTo) {
      faqRef.current.scrollIntoView({ behavior: "smooth" })
      setScrollTo(undefined)
    }
  }, [scrollTo])

  useEffect(() => {
    gtag.event({
      action: "cashback_page_loaded",
    })
  }, [])

  const onScroll = (el: string) => {
    setScrollTo(el)
  }

  const qa = [
    {
      question: "How does this work?",
      answer: (
        <>
          When you buy a home, the seller typically pays commission to both their agent and the
          buyer’s agent. We’ll give you 70% of the commission that we, as the buyer’s agent, will
          receive upon closing.
        </>
      ),
    },
    {
      question: "Will you help me find a property to buy?",
      answer: (
        <>
          You can view open houses, show homes or view homes independently. When you&apos;re ready to put
          an offer in, contact us to write it!
        </>
      ),
    },
    {
      question: "What types of property can I purchase and still receive cash back?",
      answer: (
        <>
          Any! It doesn’t matter what type of property you’re interested in. However, we only offer
          cash back if the seller offers the buyer’s agent commission (or other remuneration).
        </>
      ),
    },
    {
      question: "I’m already working with an agent. Can I still get cash back?",
      answer: (
        <>
          Maybe! Ask your agent if they’ll give you some cash back from their commission.
          Unfortunately, you won’t be able to get cash back from HonestDoor if you’re already
          represented by an agent to purchase a property.
        </>
      ),
    },
    {
      question: "Will you help me negotiate?",
      answer: (
        <>
          If we submit the formal offer on your behalf, we’ll be there to support you if there is
          any back-and-forth!
        </>
      ),
    },
    {
      question: "I’m selling my home and want to buy a new one. Will you help me?",
      answer: (
        <>
          Yes! If you aren’t already working with an agent, we can help you list your home on the
          MLS®/REALTOR.ca. While you sell, if you want to search for a new home, you can do that!
          If you find a place you want to put an offer on, then we can give you cash back.
        </>
      ),
    },
    {
      question: "Do I really get cash?",
      answer: <>Yes! We’ll give you cash (in the form of a cheque).</>,
    },
    {
      question: "When will I get the cash?",
      answer: (
        <>
          You’ll receive the cash at closing. The commission is typically paid out 3-7 business days
          from the lawyers.
        </>
      ),
    },
    {
      question: "How do I sign the paperwork",
      answer: (
        <>
          We use Docusign for all the paperwork. The contracts are sent to your email and you can
          sign on your computer, phone or tablet. It’s very straight forward and means you can make
          an offer from anywhere!
        </>
      ),
    },
    {
      question: "Are you a real estate agent?",
      answer: <>We are a licensed real estate brokerage in Canada.</>,
    },
    {
      question: "What happens if my offer is not accepted?",
      answer: (
        <>You can either submit a new offer to purchase or find another property to purchase.</>
      ),
    },
    {
      question: "Do you know lawyers and home inspectors I can use?",
      answer: (
        <>
          <Text>
            Yes! If you need recommendations for great real estate lawyers and home inspectors (or
            any other real estate service provider), we’re happy to facilitate an introduction.
          </Text>

          <Text mt={3}>
            If you need an agent to be present during a home inspection for a property that you're
            planning on making an offer on, we can help set that up. There may be a fee associated
            with this, but we will be up front with you if there is.
          </Text>
        </>
      ),
    },
    {
      question: "Do I have to pay taxes on the cash?",
      answer: (
        <>
          You may not be required to pay income tax on the cash you receive if, for example, the
          property is used as your principal residence. There may be other circumstances, too,
          however, we recommend consulting a tax professional or accountant to triple check!
        </>
      ),
    },
  ]

  return (
    <DefaultLayout>
      <Card
        variant={CardVariants.Property}
        p={[0, 0, "32px"]}
        borderTopRadius={["40px", "40px", "lg"]}
        backgroundColor={"hdBlack.base"}
        color="white"
        _hover={{}}
      >
        <Flex direction={["column", "column", "row"]} gap="48px" justifyContent="space-between">
          <Box mr={[0, 0, "16px"]} p={["32px", "32px", 0]}>
            <Heading as="h1" fontSize="34px" lineHeight="41px" fontWeight="600">
              Buy your next home with HonestDoor and receive cashback
            </Heading>

            <Box mt={["16px", "16px", "24px"]}>Have a property you want to put an offer on?</Box>

            <Box mt={["16px", "16px", "24px"]}>
              Let us do it for you. You&apos;ll get 70% cashback of the buyers&apos; commission.
              <br />
              Use the calculator to determine how much you can receive.
            </Box>

            <Box mt={["16px", "16px", "24px"]}>
              Call or Text{" "}
              <Link href="tel:780-860-8400" textDecoration="underline">
                780-860-8400
              </Link>{" "}
              or email{" "}
              <Link href="mailto:sales@honestdoor.com" textDecoration="underline">
                sales@honestdoor.com
              </Link>{" "}
              to learn more.
            </Box>

            <Flex marginTop="24px" gap="4" justifyContent={["center", "center", "start"]}>
              <Button
                onClick={() => {
                  gtag.event({
                    action: "cashback_page_contact_us",
                  })
                  onOpen()
                }}
                variant={ButtonVariants.Secondary}
                fontSize="16px"
                _hover={{}}
                size="lg"
                border="none"
              >
                Contact Us
              </Button>

              <Button
                onClick={() => {
                  gtag.event({
                    action: "cashback_page_faq",
                  })
                  onScroll("faq")
                }}
                variant={ButtonVariants.Secondary}
                fontSize="16px"
                _hover={{}}
                size="lg"
                border="none"
              >
                FAQ
              </Button>
            </Flex>

            {/*
            <Box mt={["24px", "24px", "40px"]}>
              <Flex wrap="wrap" mt="-16px">
                {bulletPoints.map((item, i) => (
                  <Flex
                    key={i}
                    direction="column"
                    mr={[0, 0, "24px"]}
                    width={["50%", "50%", "auto"]}
                    mt="16px"
                  >
                    <Flex
                      direction={["column", "column", "row"]}
                      alignItems={["center", "center", "start"]}
                    >
                      <Img
                        src="/icons/verified.svg"
                        h={["52px", "52px", "24px"]}
                        w={["52px", "52px", "24px"]}
                      />
                      <Text
                        mt={["8px", "8px", 0]}
                        ml={[0, 0, "8px"]}
                        fontSize={["18px", "18px", "18px"]}
                        lineHeight={["22px", "22px", "24px"]}
                        fontWeight={["600", "600", "400"]}
                        textAlign={["center", "center", "left"]}
                      >
                        {item.title}
                      </Text>
                    </Flex>
                    <Text mt="6px" display={["none", "none", "block"]}>
                      {item.desc}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Box>
            */}
          </Box>

          <Box w={["100%", "100%", "40%"]} p={["32px", "32px", 0]}>
            <CashBackCalculator />
          </Box>
        </Flex>
      </Card>

      {privateListings.length > 0 && (
        <ExclusiveListings
          privateListings={privateListings}
          containerProps={{
            px: 0,
          }}
        />
      )}

      {privateListings.length > 0 && (
        <Box mb={4}>
          <MultiCarousel
            title="Most Recent Listings"
            link={{
              label: "See More Listings",
              href: `/real-estate?filters=${encodeURIComponent(
                JSON.stringify({
                  crea: {
                    sort: SortOption.MOST_RECENT,
                  },
                }),
              )}`,
            }}
            loop={true}
            sizes={{ "3xl": 4, "2xl": 3, xl: 3, lg: 2, md: 2, sm: 1 }}
          >
            {creaListings?.map((listing, i) => {
              return <PropertyCard.ForSale key={i} publicListing={listing} />
            })}
          </MultiCarousel>
        </Box>
      )}

      <Heading ref={faqRef} as="h3" size="lg" mt={["24px", "24px", 20]} mb={3}>
        Frequently Asked Questions
      </Heading>
      <Accordion allowMultiple>
        {qa.map(({ question, answer }, i) => (
          <AccordionItem key={i} py="10px">
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize={["1.2em", "1.2em", "1.5em"]}>
                {question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel fontSize={["1em", "1em", "1.2em"]} px={["1em", "1em", "2em", "3em"]}>
              {answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <AskAboutPropertyModal
        input={{
          source: PrivateLeadSource.BuyHomepageContactUs,
          type: PrivateLeadType.Buy,
          message: null,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </DefaultLayout>
  )
}
