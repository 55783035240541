import React, { FC, useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"
import gtag from "~/utils/lib/gtag"
import { ExclusiveListings } from "~/components/layouts/landing/sections/ExclusiveListings"
import { PrivateListing } from "~/generated/graphql"
import { useAuth, useUser } from "~/components/hooks"
import { DefaultLayout } from "~/components/layouts"
import { ListOnMlsPromo, TestimonialsSlider } from "~/components/modules"

interface IListLayout {
  privateListings: PrivateListing[]
}

export const ListLayout: FC<IListLayout> = ({ privateListings }) => {
  const router = useRouter()
  const [scrollTo, setScrollTo] = useState<string>()
  const faqRef = useRef(null)
  const { openSignInModal } = useAuth()
  const { user } = useUser()

  const onGetStartedClick = () => {
    gtag.event({
      category: "MLS",
      action: "list_on_mls_get_started",
    })

    // @ts-ignore
    if (window.$crisp) {
      // @ts-ignore
      window.$crisp.push(["set", "session:segments", [["list", "list-warmlead"]]])
    }

    if (user) {
      router.push("/submit-listing")
    } else {
      openSignInModal({
        callbackUrl: "/submit-listing",
      })
    }
  }

  useEffect(() => {
    gtag.event({
      category: "MLS",
      action: "view_list_on_MLS_page",
    })
  }, [])

  useEffect(() => {
    if (faqRef && faqRef.current && scrollTo) {
      faqRef.current.scrollIntoView({ behavior: "smooth" })
      setScrollTo(undefined)
    }
  }, [scrollTo])

  const onScroll = (el: string) => {
    setScrollTo(el)
  }

  const qa = [
    {
      question: "How does it work?",
      answer: (
        <>
          <Text>
            HonestDoor will list your property on the MLS® System, on REALTOR.ca (and many other
            websites). We list it as a flat-fee &quot;Mere Posting&quot;.
          </Text>
          <Text mt={3}>
            With a Mere Posting potential buyers and buyers agents will contact you directly for
            showings and will present offers directly to you.
          </Text>
        </>
      ),
    },
    {
      question: "How do I get my listing on Realtor.ca and other real estate websites?",
      answer: (
        <>
          <Text>
            It’s simple! Shoot us a quick message by clicking{" "}
            <Link onClick={onGetStartedClick} textDecoration="underline">
              here
            </Link>
            . We’ll contact you for further details. It takes an average of 1 day for us to get your
            listing onto Realtor.ca + all syndicated websites.
          </Text>
        </>
      ),
    },
    {
      question: "How long will it take to list my home?",
      answer: (
        <>
          <Text>
            You can list your home in as little as 10 minutes! Once you fill out our online form,
            we’ll contact you for a few more details about your property.
          </Text>
        </>
      ),
    },
    {
      question:
        "What is the difference between For Sale By Owner (FSBO) and what HonestDoor is offering (flat-fee listing)?",
      answer: (
        <>
          <Text>
            For sale by owner (FSBO) means that you — the homeowner — are representing yourself.
            That means you’re responsible for preparing your home for sale, marketing your property,
            and showing your home to buyers. When you opt to sell your home exclusively by yourself,
            you must advertise that your home is for sale on your own and your listing does not go
            on any MLS® System. Typically, FSBO homes are marketed for free on social media or
            Craiglist/Kijiji.
          </Text>
          <Text mt={3}>
            HonestDoor’s listing service on the MLS® System helps you get millions more eyeballs to
            your property. Instead of advertising/marketing your property on your own, you can use
            our service to list on REALTOR.ca (and many other websites). Unlike a full-service
            listing, you’ll be showing your home to buyers.
          </Text>
        </>
      ),
    },
    {
      question: "Will my listing look the same as other listings?",
      answer: (
        <>
          <Text>
            Yes, your listing will be identical to the other homes that are for sale (by real estate
            agents). It will show all photos and all listing information, including the commission
            offered to an agent who successfully sells the home, and instructions for showing the
            home.
          </Text>
        </>
      ),
    },

    {
      question: "Can I cancel my listing?",
      answer: (
        <>
          <Text>
            Of course. You can cancel your listing at any time. You just have to contact us so that
            we can remove it from the MLS® System.
          </Text>
        </>
      ),
    },
    {
      question: "How long will my property be listed on the MLS® for the flat fee?",
      answer: (
        <>
          <Text>
            The MLS® flat fee listing is good for 6 months. In the unlikely event that your home has
            still not sold within that time, you can always renew it!
          </Text>
        </>
      ),
    },
    {
      question: "Do I have to pay real estate commissions?",
      answer: (
        <>
          <Text>
            You don’t have to pay any real estate commissions to HonestDoor when you sell. The only
            fee we charge is the flat-fee for listing.
          </Text>
          <Text mt={3}>
            When you list your property, you can decide what commission you’d like to offer a buyers
            agent (if a buyer is already working with an agent).
          </Text>
        </>
      ),
    },
    {
      question: "Do I need to offer to pay a buyer’s agent commission?",
      answer: (
        <>
          <Text>
            No, however, we recommend it. If you’re trying to attract interested buyers who are
            already working with a real estate agent, it’s recommended to offer a commission.
          </Text>

          <Text mt={3}>
            That being said, you don’t have to offer a buyer’s agent commission if you don’t want.
            When you list with HonestDoor, we’ll ask whether you’d like to offer a buyer’s agent
            commission and you can decide what you’d like to do.
          </Text>
        </>
      ),
    },
    {
      question: "What price should I list my home at?",
      answer: (
        <>
          <Text>
            Check your home’s value at honestdoor.com to see how much it is estimated to be worth.
            We update our HonestDoor Prices every month!
          </Text>
          <Text mt={3}>
            At honestdoor.com, you can also check out homes that have recently sold or ones that are
            currently on the market. Do your research and check to see which homes are similar to
            yours. You can always change your list price!
          </Text>
          <Text mt={3}>
            Alternatively, you can get an official appraisal done. An appraiser normally comes to
            your home to determine its value. These typically cost between $200-$400 to complete. If
            you want an appraisal recommendation, let us know!
          </Text>
        </>
      ),
    },
    {
      question: "Are there any additional fees for changes and extensions?",
      answer: (
        <>
          <Text>
            We do not charge any additional fees for changes to your MLS® Listing. You may make
            changes by simply sending us an email (
            <Link href="mailto:support@honestdoor.com">support@honestdoor.com</Link>).
          </Text>
          <Text mt={3}>To extend your listing (past 6 months), the fee is $250.</Text>
        </>
      ),
    },
    {
      question: "If I decide to upgrade to a Full Service Listing, can the flat fee be refunded?",
      answer: (
        <>
          <Text>No… but we can upgrade you to a full service listing!</Text>
        </>
      ),
    },
    {
      question: "Do I have to manage showings for interested buyers?",
      answer: (
        <>
          <Text>
            Yes. You’ll have to manage showings, negotiate with prospective buyers, answer
            questions, etc.
          </Text>
          <Text mt={3}>
            However, if you have any questions, don’t hesitate to reach out!{" "}
            <Link href="mailto:support@honestdoor.com">support@honestdoor.com</Link> or call us at
            780-860-8400
          </Text>
        </>
      ),
    },
    {
      question: "I’m a builder. Can I list multiple properties for sale?",
      answer: (
        <>
          <Text>
            Yes. If you are a builder, developer or real estate mogul and would like to list more
            than one property, please connect with us:{" "}
            <Link href="mailto:sales@honestdoor.com">sales@honestdoor.com</Link> or 780-860-8400
          </Text>
        </>
      ),
    },
  ]

  return (
    <DefaultLayout>
      <Flex flexDirection="column">
        <ListOnMlsPromo variant="list" onScroll={onScroll} />

        {privateListings.length > 0 && (
          <ExclusiveListings
            privateListings={privateListings}
            containerProps={{
              px: 0,
            }}
          />
        )}

        <Box mt="24px">
          <TestimonialsSlider />
        </Box>

        <Heading ref={faqRef} as="h3" size="lg" mt={["24px", "24px", 20]} mb={3}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowMultiple>
          {qa.map(({ question, answer }, i) => (
            <AccordionItem key={i} py="10px">
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={["1.2em", "1.2em", "1.5em"]}>
                  {question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel fontSize={["1em", "1em", "1.2em"]} px={["1em", "1em", "2em", "3em"]}>
                {answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </DefaultLayout>
  )
}
