import { FC } from "react"
import { useRouter } from "next/router"
import { Alert, AlertIcon, Box, Container, Heading, VStack } from "@chakra-ui/react"
import { LoginBox } from "~/components/modules"

const ERRORS = {
  Default: "Error",
  OAuthSignin: "Error in constructing an authorization URL",
  OAuthCallback: "Error in handling the response from an OAuth provider",
  OAuthCreateAccount: "Could not create a oauth user",
  EmailCreateAccount: "Could not create a user",
  Callback: "Verification link is expired",
  OAuthAccountNotLinked: "Email on the account is already linked, but not with this OAuth account",
  EmailSignin: "Sending the e-mail verification failed",
  CredentialsSignin: "Credentials sign-in error",
  SessionRequired: "The content of this page requires you to be signed",
}

export const SignInLayout: FC = () => {
  const { query } = useRouter()
  const { callbackUrl, error } = query

  const errorMessage = ERRORS[error as string]

  return (
    <Container my={["20px", "40px", "75px"]}>
      <VStack spacing={4}>
        <Heading size="lg">Sign in to your account</Heading>

        {errorMessage && (
          <Alert status="error" maxW="sm">
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}

        <Box minW="sm" bgColor="white" p={6} rounded="md" borderWidth={1} shadow="sm">
          <LoginBox callbackUrl={callbackUrl as string} />
        </Box>
      </VStack>
    </Container>
  )
}
