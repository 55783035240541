import { FC, useEffect } from "react"
import { useRouter } from "next/router"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  Heading,
  Stack,
  Text,
  VStack,
  Center,
  useToast,
  AlertDescription,
} from "@chakra-ui/react"
import { DefaultLayout } from "~/components/layouts"
import { SubmitListingsSteps } from "~/components/layouts/submit-listing/submit-listings-steps"
import { ButtonVariants } from "~/theme/components"
import { formatCurrency } from "~/utils"
import { WithSessionProps } from "~/components/hocs/withSession"
import {
  PrivateListingSubscriptionStatus,
  useCreateProductCheckoutSessionMutation,
} from "~/generated/graphql"
import { GetPrivateListingQuery, GetMlsListingPriceQuery } from "~/generated/graphql"
import gtag from "~/utils/lib/gtag"

interface ISubmitListingPaymentLayout extends WithSessionProps {
  privateListing: GetPrivateListingQuery["getPrivateListing"]
  mlsListingPrice?: GetMlsListingPriceQuery["getMLSListingPrice"]
}

export const SubmitListingPaymentLayout: FC<ISubmitListingPaymentLayout> = ({
  session,
  privateListing,
  mlsListingPrice,
}) => {
  const toast = useToast()
  const {
    query: { id: privateListingId, checkout_success },
    push,
  } = useRouter()
  const { mutateAsync: createProductCheckoutSession, isLoading: productCheckoutSessionLoading } =
    useCreateProductCheckoutSessionMutation()

  useEffect(() => {
    if (checkout_success === "true") {
      gtag.event({
        category: "MLS",
        action: "list_on_mls_subscribed",
      })

      // window.rdt('track', 'Purchase', { "value": 100 });

      // @ts-ignore
      if (window.$crisp) {
        // @ts-ignore
        window.$crisp.push(["set", "session:segments", [["list-subscribed"]]])
      }
    }
  }, [checkout_success])

  async function handlePay() {
    if (!mlsListingPrice) return

    // window.rdt("track", "AddToCart", { value: 100 })

    try {
      const {
        createProductCheckoutSession: { url },
      } = await createProductCheckoutSession({
        input: {
          // @TODO: get auth user from backend request
          userId: session?.user?.id,
          customerEmail: session?.user?.email,

          productId: mlsListingPrice.productId,
          priceId: mlsListingPrice.id,
          productReferenceId: privateListingId as string,
          successUrl: `${window.location.origin}/submit-listing/${privateListingId}/payment?checkout_success=true`,
          cancelUrl: `${window.location.origin}/submit-listing/${privateListingId}/payment?checkout_success=false`,
        },
      })

      gtag.event({
        category: "MLS",
        action: "list_on_mls_stripe",
      })

      window.location.href = url
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong. Please try again later...",
        position: "top-right",
        isClosable: true,
      })
    }
  }

  return (
    <DefaultLayout>
      <Box
        bgColor="white"
        p={6}
        borderRadius="lg"
        borderColor="#C9CDD9"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      >
        <Heading as="h1">List on Realtor.ca</Heading>
        <SubmitListingsSteps step={1} my={8} />

        {privateListing && privateListing.userId === session?.user?.id && (
          <Box maxWidth={720} margin="0 auto">
            <Card my={6} boxShadow="none">
              <Stack
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Box>
                    <Text color="hdBlack.300">Property address</Text>
                  </Box>
                  <Box>
                    {[
                      privateListing.streetAddress,
                      privateListing.city,
                      privateListing.province,
                      "Canada",
                    ].join(", ")}
                  </Box>
                </Box>
              </Stack>
            </Card>

            <Center>
              {privateListing.subscriptionStatus === PrivateListingSubscriptionStatus.Unpaid &&
                mlsListingPrice && (
                  <VStack>
                    <Text my={4} fontWeight="bold">
                      The fee to list your property on MLS is:
                    </Text>
                    <Heading as="h2" color="green.700" mb={10}>
                      {formatCurrency(mlsListingPrice.amount / 100)}
                    </Heading>

                    <Stack direction="row" spacing={6}>
                      <Button variant="link" onClick={() => push(`/submit-listing`)}>
                        Cancel
                      </Button>
                      <Button
                        variant={ButtonVariants.Secondary}
                        onClick={handlePay}
                        isLoading={productCheckoutSessionLoading}
                      >
                        Pay & Get Started
                      </Button>
                    </Stack>
                  </VStack>
                )}

              {privateListing.subscriptionStatus === PrivateListingSubscriptionStatus.Active && (
                <Stack alignItems="center">
                  <Alert status="success" mb={4}>
                    <AlertIcon />
                    <AlertTitle>We&apos;ve received your payment.</AlertTitle>
                    <AlertDescription>
                      Next, fill out the form to start your listing.
                    </AlertDescription>
                  </Alert>

                  {checkout_success === "true" && (
                    <Button
                      w="fit-content"
                      padding="8px 32px"
                      color="#fff"
                      backgroundColor="#C4985F"
                      borderRadius="8px"
                      _hover={{}}
                      onClick={() => push(`/submit-listing/${privateListingId}/details`)}
                    >
                      Complete your listing details
                    </Button>
                  )}
                </Stack>
              )}
            </Center>
          </Box>
        )}
      </Box>
    </DefaultLayout>
  )
}
