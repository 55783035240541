import { FC } from "react"
import { useRouter } from "next/router"
import { Box, Text } from "@chakra-ui/react"
import { MdHouse } from "react-icons/md"
import { FaBuilding, FaSign } from "react-icons/fa"
import { RiBarChart2Fill } from "react-icons/ri"
import { TextVariants } from "~/theme/components"
import { SectionContainer, DividedGrid } from "~/components/elements"
import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { ValueHighlight } from "./value-highlight"
import { NeighbourhoodStats as Stats } from "~/utils/classes/NeighbourhoodStats"
import { SectionHeader } from "~/components/layouts/property/sections"
import {
  EsProperty,
  useGetNeighbourhoodStatsQuery,
} from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

const defaultIcons = {
  housePrice: <MdHouse size={30} />,
  condoPrice: <FaBuilding />,
  yearGrowth: <RiBarChart2Fill size={20} />,
  transactions: <FaSign size={18} />,
}

export type Stat = {
  icon?: JSX.Element
  text?: string
  currentValue?: string
  previousValue?: string
  previousYear?: number
  /**
   * Whether the new value is positive, negative, or neutral relative to the
   * previous value
   */
  changeDirection?: "positive" | "negative" | "neutral"
  /**
   * If true an icon will be shown to indicate the changeDirection
   */
  showIndicator?: boolean
}

export interface INeighbourhoodStatsProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty
}
export const NeighbourhoodStats: FC<INeighbourhoodStatsProps> = ({  property }) => {
  const router = useRouter()
  const currentYear = new Date().getFullYear()
  const { data, isLoading } = useGetNeighbourhoodStatsQuery({
    filter: {
      neighbourhoodId: property.neighbourhood,
      yearRange: {
        from: currentYear - 2,
        to:currentYear
      }
    },
    take: 20,
  })
  if (isLoading) return null

  const neighbourhoodStats = new Stats(data?.getNeighbourhoodStats || [])
  const neighbourhood = property.neighbourhoodName
  const housePrice = getHousePrices(neighbourhoodStats, currentYear)
  const condoPrice = getCondoPrices(neighbourhoodStats, currentYear)
  const yearGrowth = getYearGrowth(neighbourhoodStats, currentYear)
  const transactions = getTransactions(neighbourhoodStats, currentYear)

  // TODO: This is a hacky way of filtering
  let stats: Stat[] = [
    {
      icon: defaultIcons?.transactions,
      text:
        transactions?.current &&
        transactions?.previous &&
        `In ${currentYear}, ${neighbourhood} transactions have totalled`,
      currentValue: transactions?.current?.toString(),
      previousYear: currentYear - 1,
      previousValue: transactions?.previous?.toString(),
      changeDirection:
        transactions?.current > transactions?.previous
          ? "negative"
          : transactions?.current < transactions?.previous
          ? "positive"
          : "neutral",
    },
    {
      icon: defaultIcons?.housePrice,
      text:
        housePrice?.current &&
        housePrice?.previous &&
        `The average price of a house in ${neighbourhood} is`,
      currentValue: toDollarString(housePrice?.current),
      previousYear: currentYear - 1,
      previousValue: toDollarString(housePrice?.previous),
      changeDirection:
        housePrice?.current > housePrice?.previous
          ? "positive"
          : housePrice?.current < housePrice?.previous
          ? "negative"
          : "neutral",
    },
    {
      icon: defaultIcons?.condoPrice,
      text:
        condoPrice?.current &&
        condoPrice?.previous &&
        `The average price of a condo in ${neighbourhood} is`,
      currentValue: toDollarString(condoPrice?.current),
      previousYear: currentYear - 1,
      previousValue: toDollarString(condoPrice?.previous),
      changeDirection:
        condoPrice?.current > condoPrice?.previous
          ? "positive"
          : condoPrice?.current < condoPrice?.previous
          ? "negative"
          : "neutral",
    },
    {
      icon: defaultIcons?.yearGrowth,
      text:
        yearGrowth?.current && yearGrowth?.previous && `Year-over-year, ${neighbourhood} has grown`,
      currentValue: toPercentageString(yearGrowth?.current),
      previousYear: currentYear - 1,
      previousValue: toPercentageString(yearGrowth?.previous),
      showIndicator: true,
      changeDirection:
        yearGrowth?.current > yearGrowth?.previous
          ? "negative"
          : yearGrowth?.current < yearGrowth?.previous
          ? "positive"
          : "neutral",
    },
  ]
  stats = stats.filter(stat => !!stat.text)

  if (!stats.length) return null

  return (
    <SectionContainer>
      <SectionHeader
        title="Neighbourhood Stats"
        primaryButtonLabel={`Explore ${property?.neighbourhoodName || "More"} Data`}
        onClickPrimaryButton={
          !property
            ? undefined
            : () => {
              router.push(
                createUrl({
                  variant: "cities",
                  city: property.cityName,
                  provinceAbbr: provinceLongToProvinceAbbr(property.province),
                  neighbourhood: property?.neighbourhoodName,
                }),
              )
            }

        }
      />
      <DividedGrid columns={[1, 1, 2, 2, 4]} gap={[12]}>
        {stats.map((stat, i) => (
          <Box key={i}>
            <Box
              width={6}
              height={6}
              marginBottom={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {stat.icon}
            </Box>
            <Text noOfLines={3} marginBottom={4}>
              {stat.text}
            </Text>
            <Text variant={TextVariants.heading3} marginBottom={5}>
              {stat.currentValue}
            </Text>
            <Box display="flex" alignItems="center">
              <Text variant={TextVariants.body2} color="gray.600">
                In {stat.previousYear}, it was
              </Text>
              &nbsp;
              <ValueHighlight highlight={stat.changeDirection} showIndicator={stat.showIndicator}>
                {stat.previousValue}
              </ValueHighlight>
            </Box>
          </Box>
        ))}
      </DividedGrid>
    </SectionContainer>
  )
}

// @TODO move to helpers

function getNeighbourhoodData(data, type, year, field) {
  if (!data) return null
  const value = data.filter(data => data.assessmentClass == type && data.year == year)[0]
  if (!value) return null
  return value[field]
}

// convert a number to a string representing dollar value
function toDollarString(value) {
  if (!value) return null
  return "$" + value.toLocaleString()
}

// convert a number to a string representing percentage value
function toPercentageString(value) {
  if (!value) return null
  return (value > 0 ? "+" : "") + (value * 100).toFixed(2) + "%"
}

function getHousePrices(neighbourhoodStats, currentYear): { current: number; previous: number } {
  const current = getNeighbourhoodData(
    neighbourhoodStats.rawAvgAssessed,
    "Residential",
    currentYear,
    "avgAssessedValue",
  )
  const previous = getNeighbourhoodData(
    neighbourhoodStats.rawAvgAssessed,
    "Residential",
    currentYear - 1,
    "avgAssessedValue",
  )

  return { current, previous }
}

function getCondoPrices(neighbourhoodStats, currentYear): { current: number; previous: number } {
  const current = getNeighbourhoodData(
    neighbourhoodStats.rawAvgAssessed,
    "Condo",
    currentYear,
    "avgAssessedValue",
  )
  const previous = getNeighbourhoodData(
    neighbourhoodStats.rawAvgAssessed,
    "Condo",
    currentYear - 1,
    "avgAssessedValue",
  )

  return { current, previous }
}

function getYearGrowth(neighbourhoodStats, currentYear): { current: number; previous: number } {
  const current = getNeighbourhoodData(
    neighbourhoodStats.rawGrowth,
    "Both",
    currentYear,
    "growthRate",
  )
  const previous = getNeighbourhoodData(
    neighbourhoodStats.rawGrowth,
    "Both",
    currentYear - 1,
    "growthRate",
  )

  return { current, previous }
}

function getTransactions(neighbourhoodStats, currentYear) {
  const current = getNeighbourhoodData(
    neighbourhoodStats.rawTransactions,
    "Both",
    currentYear,
    "totalTransactionCount",
  )
  const previous = getNeighbourhoodData(
    neighbourhoodStats.rawTransactions,
    "Both",
    currentYear - 1,
    "totalTransactionCount",
  )

  return { current, previous }
}
