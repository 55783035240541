import { FC, PropsWithChildren } from "react"
import { Box } from "@chakra-ui/react"

export const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box maxWidth="1600px" minHeight="calc(100vh - 619px)" marginY={6} marginX={[6, 6, 20, 20, 40]}>
      {children}
    </Box>
  )
}
