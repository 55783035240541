import React, { Fragment } from "react"
import { Grid, GridItem, GridProps, ResponsiveValue, useBreakpointValue } from "@chakra-ui/react"
import repeat from "lodash/repeat"

type DividedGridProps = GridProps & {
  /**
   * Number of columns to display
   */
  columns: ResponsiveValue<number>
  /**
   * Whether to draw a divider between elements at each breakpoint
   */
  hasDivider?: ResponsiveValue<boolean>
  children?: React.ReactNode
}
/**
 * A grid with dividers between elements
 */
const DividedGrid = ({
  columns = [1],
  gap = [0],
  hasDivider: drawDivider = [true],
  ...props
}: DividedGridProps): JSX.Element => {
  const columnsAtCurrentBreakpoint = useBreakpointValue(columns as any, "3xl")
  const drawDividerAtCurrentBreakpoint = useBreakpointValue(drawDivider as any, "3xl")
  const templateColumns =
    "1fr" +
    repeat(drawDividerAtCurrentBreakpoint ? " 1px 1fr" : " 1fr", columnsAtCurrentBreakpoint - 1)
  const children = React.Children.toArray(props.children)

  return (
    <Grid templateColumns={templateColumns} gap={gap} {...props}>
      {children.map((child, i) => (
        <Fragment key={i}>
          {child}
          {drawDividerAtCurrentBreakpoint &&
          shouldDrawDivider(i, columnsAtCurrentBreakpoint, children.length) ? (
            <GridItem
              key={`divider-${i}`}
              bgColor="#C9CDD9"
              height={columnsAtCurrentBreakpoint === 1 ? "1px" : undefined}
            />
          ) : null}
        </Fragment>
      ))}
    </Grid>
  )
}

export default DividedGrid

function shouldDrawDivider(index: number, columns: number, length: number) {
  // never draw a divider for the last item
  if (index === length - 1) return false

  // if we stack vertically we can always separate
  if (columns < 2) return true

  // if we have multiple columns, dont draw at the end
  if ((index + 1) % columns === 0) return false

  return true
}
