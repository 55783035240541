import { FC, useMemo } from "react"
import { Divider, HStack, Text } from "@chakra-ui/react"
import { FaBath, FaBed, FaRuler } from "react-icons/fa"
import { formatSizeValue } from "~/utils/helpers"

interface ISummaryAmenities {
  bedrooms?: number
  bathrooms?: number
  floorSpace?: number
}

export const SummaryAmenities: FC<ISummaryAmenities> = ({ bedrooms, bathrooms, floorSpace }) => {
  const floorSpaceFormatted = useMemo(() => (floorSpace ? formatSizeValue(floorSpace) : null), [
    floorSpace,
  ])

  if (!bedrooms && !bathrooms && !floorSpace) return null

  return (
    <HStack
      divider={<Divider orientation="vertical" height="24px" display={["none", "initial"]} />}
      flexDirection={["column", "row", "row"]}
      flexWrap="wrap"
    >
      {bedrooms && (
        <HStack>
          <FaBed />
          <Text>{bedrooms}</Text>
          <Text>beds</Text>
        </HStack>
      )}
      {bathrooms && (
        <HStack>
          <FaBath />
          <Text>{bathrooms}</Text>
          <Text>baths</Text>
        </HStack>
      )}
      {floorSpace && (
        <HStack>
          <FaRuler />
          <Text>{floorSpaceFormatted}</Text>
          <Text>sqft</Text>
        </HStack>
      )}
    </HStack>
  )
}
