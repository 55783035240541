import { FC, useMemo } from "react"
import {
  BuyingOrSellingCTA,
  CallsToAction,
  ContactOwner,
  Description,
  Disclaimer,
  EstimatedRentalInformation,
  ImageGallery,
  MoreProperties,
  Navbar,
  NeighbourhoodStats,
  NeverMissAListingCTA,
  Permits,
  PriceHistory,
  PropertyAnalysis,
  SaleTaxHistory,
  Summary,
} from "~/components/layouts/property/sections"
import { AdPageType, EsProperty, PrivateListing, AdType, CreaListing } from "~/generated/graphql"
import { SectionContainer } from "~/components/elements"
import { VendorAd } from "~/components/helpers/ads/VendorAd"
import { SimpleGrid } from "@chakra-ui/react"
import { PROPERTY_BOARDS, PROPERTY_TYPES } from "~/utils/constants"

interface IPrivateListingLayout {
  privateListing: PrivateListing
  property: EsProperty
  publicListing?: CreaListing
}

export const PrivateListingLayout: FC<IPrivateListingLayout> = ({
  privateListing,
  property,
  publicListing,
}) => {
  const show = useMemo(
    () => ({
      callsToAction: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      estimatedRental: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      neverMissAListingCTA:
        property.neighbourhood &&
        [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(property.assessmentClass),
      neighbourhoodStats: [
        PROPERTY_TYPES.RESIDENTIAL,
        PROPERTY_TYPES.CONDO,
        PROPERTY_TYPES.COMMERCIAL,
      ].includes(property.assessmentClass),
      moreProperties: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      propertyAnalysis: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
    }),
    [property, privateListing],
  )

  return (
    <>
      <Navbar board={PROPERTY_BOARDS.PRIVATE} property={privateListing} />
      <ImageGallery property={privateListing} />

      <SectionContainer showDivider={false}>
        <Summary.Private
          privateListing={privateListing}
          property={property}
          publicListing={publicListing}
        />
      </SectionContainer>

      <SectionContainer showDivider={false}>
        <ContactOwner board={PROPERTY_BOARDS.PRIVATE} privateListing={privateListing} />
      </SectionContainer>

      <SectionContainer>
        <Description property={privateListing} />
      </SectionContainer>

      {/*
      <SectionContainer>
        <PropertyDetails property={privateListing} board={PROPERTY_BOARDS.PRIVATE} />
      </SectionContainer>
      */}

      {show.callsToAction && (
        <SectionContainer>
          <CallsToAction board={PROPERTY_BOARDS.PRIVATE} property={property} />
        </SectionContainer>
      )}

      <SectionContainer>
        <PriceHistory board={PROPERTY_BOARDS.PRIVATE} property={property} />
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid columns={[1, 1, 1, 2]} gap={[6, 6, 6, 12]}>
          <SaleTaxHistory property={property} />
          <Permits property={property} />
        </SimpleGrid>
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: show.estimatedRental ? 2 : 1 }}
          gap={[6, 6, 6, 12]}
        >
          {show.estimatedRental ? (
            <>
              <EstimatedRentalInformation property={property} />
              <BuyingOrSellingCTA
                board={PROPERTY_BOARDS.PRIVATE}
                province={property.province}
                address={property.unparsedAddress}
                variant="inline"
              />
            </>
          ) : (
            <BuyingOrSellingCTA
              board={PROPERTY_BOARDS.PRIVATE}
              province={property.province}
              address={property.unparsedAddress}
              variant="full"
            />
          )}
        </SimpleGrid>
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid columns={{ base: 1, md: show.neverMissAListingCTA ? 2 : 1 }} gap={[6, 6, 0]}>
          {show.neverMissAListingCTA && (
            <NeverMissAListingCTA
              board={PROPERTY_BOARDS.PRIVATE}
              neighbourhoodId={property.neighbourhood}
              neighbourhoodName={property.neighbourhoodName}
            />
          )}
          <VendorAd
            variant={AdType.InlineRectangle}
            targeting={{
              cityNames: [property.cityName],
              provinceNames: [property.province],
              pageTypes: [AdPageType.UnlistedProperty],
            }}
          />
        </SimpleGrid>
      </SectionContainer>

      {show.neighbourhoodStats && (
        <NeighbourhoodStats board={PROPERTY_BOARDS.PRIVATE} property={property} />
      )}

      {show.moreProperties && (
        <SectionContainer>
          <MoreProperties board={PROPERTY_BOARDS.PRIVATE} property={property} />
        </SectionContainer>
      )}

      {show.propertyAnalysis && (
        <SectionContainer>
          <PropertyAnalysis property={property} />
        </SectionContainer>
      )}

      <SectionContainer>
        <Disclaimer property={property} privateListing={privateListing} />
      </SectionContainer>
    </>
  )
}
