import { useRouter } from "next/router"
import { NotificationSubscriptionEvent, useGetNotificationSubscriptionsQuery } from "~/generated/graphql"
import { PropertyUpdatsPageProps } from "~/pages/notifications/updates"

import { Box, Button, Container, Heading, Spinner, Stack, Text, VStack } from "@chakra-ui/react"

const PropertyUpdates = (props: PropertyUpdatsPageProps): JSX.Element => {
  const { base64email, propertyIds } = props
  const router = useRouter()
  const email = Buffer.from(base64email, "base64").toString("ascii")

  const { data, isLoading } = useGetNotificationSubscriptionsQuery({
    filter: { email, eventType: NotificationSubscriptionEvent.PropertyUpdated },
  })

  if (isLoading) return <Spinner />

  const updatedProperties = data?.getNotificationSubscriptions?.filter(sub =>
    propertyIds?.includes(sub?.propertyId),
  )

  return (
    <Container my={["12", null, "24"]} maxW="container.md">
      <VStack spacing={14} align={"start"} w={"full"}>
        <VStack spacing={8} align={"start"} w={"full"}>
          <Stack spacing="5" w={"100%"}>
            <Stack spacing="1">
              <Heading as={"h2"} fontSize={"lg"}>
                Updated Properties
              </Heading>
            </Stack>
            <VStack align={"start"} spacing={2}>
              {updatedProperties?.map(sub => (
                <Box
                  key={sub.id}
                  borderWidth={"1px"}
                  p={{ base: 2, md: 4 }}
                  borderRadius="lg"
                  w={"100%"}
                  shadow={"sm"}
                >
                  <Stack justify="space-between" direction={"row"} spacing="5" align={"center"}>
                    <Box fontSize="sm">
                      <Text fontSize={"md"} fontWeight="medium" noOfLines={1}>
                        {sub.name}
                      </Text>
                    </Box>
                    <Button
                      size={"sm"}
                      colorScheme={"hdMoney"}
                      onClick={() => router.push(`/property?id=${sub.propertyId}`)}
                    >
                      View
                    </Button>
                  </Stack>
                </Box>
              ))}
            </VStack>
          </Stack>
        </VStack>
      </VStack>
    </Container>
  )
}

export default PropertyUpdates
