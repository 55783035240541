import { FC, useMemo, useState } from "react"
import router, { useRouter } from "next/router"
import capitalize from "lodash/capitalize"
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { FaEdit, FaStreetView } from "react-icons/fa"
import { getAmenities, getLocation, getPrices } from "~/utils/helpers"
import { ButtonVariants, TextVariants } from "~/theme/components"
import {
  WatchPriceButton,
  StreetViewModal,
  TrackNeighbourhoodButton,
  PrivateLeadButton,
  CmaButton,
} from "~/components/modules"
import { SummaryAmenities } from "./summary-amenities"
import theme from "~/theme"
import { EsProperty } from "~/generated/graphql"
import gtag from "~/utils/lib/gtag"
import { GiFamilyHouse } from "react-icons/gi"
import { PROPERTY_BOARDS, provinces } from "~/utils/constants"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"

export interface ISummaryUnlisted {
  property: EsProperty
}

export const SummaryUnlisted: FC<ISummaryUnlisted> = ({ property }) => {
  const { push } = useRouter()
  const isMobile = useBreakpointValue([true, true, false])
  const condenseSaleLabel = useBreakpointValue([false, false, true, false])
  const [streetViewOpen, setStreetViewOpen] = useState(false)

  const {
    addressFormattedFull,
    addressFormattedMain,
    coordinates,
    estimatedPrice,
    month,
    bedrooms,
    bathrooms,
    floorSpace,
  } = useMemo(() => {
    const { addressFormattedFull, addressFormattedMain, coordinates } = getLocation(property)
    const { bedrooms, bathrooms, floorSpace } = getAmenities(property)
    const { estimatedPrice, estimatedPriceDate } = getPrices(property)

    return {
      addressFormattedFull,
      addressFormattedMain,
      coordinates,
      bedrooms,
      bathrooms,
      floorSpace,
      estimatedPrice,
      month: estimatedPriceDate
        ? new Date(estimatedPriceDate).toLocaleString("default", { month: "long" })
        : undefined,
    }
  }, [property])

  const onStreetViewClick = () => {
    gtag.event({
      action: "Property_google_streetview",
    })
    setStreetViewOpen(true)
  }

  const sellWithHDButtonInput = {
    source: PrivateLeadSource.SellPropPage,
    type: PrivateLeadType.Sell,
    propertyId: property.id,
    slug: property.slug,
    streetAddress: property.unparsedAddress,
    cityId: property.city,
    cityName: property.cityName,
    neighbourhoodId: property.neighbourhood,
    neighbourhoodName: property.neighbourhoodName,
    province: property.province,
    hdPrice: property.predictedValue,
  }

  return (
    <Box
      bgColor="#282F45"
      color="white"
      p={6}
      borderRadius="lg"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="gold.500"
        width="8px"
        height="100%"
      />

      <Flex justifyContent={["center", "center", "space-between"]}>
        <Flex
          direction={["column", "column", "row"]}
          alignItems="center"
          justifyContent={["center", "center", "start"]}
          marginTop={["8px", "8px", 0]}
        >
          <Box>
            <Text as="div" variant={TextVariants.heading4} marginRight={[0, 0, 4, 4]}>
              HonestDoor Price
            </Text>
          </Box>
          <Flex alignItems="center" marginTop={["8px", "8px", 0]}>
            <Box
              width={2}
              height={2}
              borderRadius="full"
              bgColor="red.500"
              marginRight={2}
              flexShrink={0}
            />
            <Text noOfLines={1} color="hdBlack.faded">
              {capitalize((condenseSaleLabel ? "" : "this property is ") + "not for sale")}
            </Text>
          </Flex>
        </Flex>

        <Flex justifyContent="end" display={["none", "none", "flex"]}>
          <HStack spacing="6px">
            <PrivateLeadButton
              text="Sell with HonestDoor"
              input={sellWithHDButtonInput}
              buttonProps={{
                leftIcon: <GiFamilyHouse size={20} color={theme.colors.green["500"]} />,
              }}
              afterClick={() => push("/sell-with-honestdoor")}
            />
            <WatchPriceButton
              property={property}
              unwatchedText="Track Home Value"
              watchedText="Home Value is Tracked"
              onClick={() => {
                gtag.event({
                  action: "Property_banner_track_value",
                })
              }}
            />
          </HStack>
        </Flex>
      </Flex>

      <Flex
        direction={["column", "row", "row"]}
        justifyContent={["center", "center", "space-between", "space-between"]}
        alignItems={"center"}
      >
        <Flex direction={["column", "column", "row"]} alignItems={["center"]}>
          <Box>
            <Text
              as="div"
              marginY="16px"
              variant={TextVariants.heading1}
              color="gold.500"
              fontSize={48}
              lineHeight="54px"
            >
              {estimatedPrice
                ? `$${estimatedPrice.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
                : "Coming Soon"}
            </Text>
          </Box>

          <Box ml={6}>

            {[provinces.AB, provinces.BC].includes(property?.province) ? (
              <CmaButton property={property} />
            ) : (
              <Button
                variant={ButtonVariants.Link}
                color="gold.500"
                onClick={() => {
                  gtag.event({
                    action: "Property_banner_update_price",
                  })
                  router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
                }}
                leftIcon={<FaEdit size={14} />}
                ml={[0, 0, 4]}
              >
                <Text display={["block", "block", "none", "block"]}>Update</Text>
              </Button>
            )}
          </Box>
        </Flex>

        {!isMobile && (
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        )}
      </Flex>

      {estimatedPrice && (
        <Flex
          direction={["column", "column", "row"]}
          alignItems={["center", "center", "start"]}
          marginBottom={[0, 0, "12px"]}
          marginTop={["16px", "16px", 0, 0]}
        >
          <Box>
            <Text as="span" fontWeight="bold">
              {month}
            </Text>
            <Text as="span" color="hdBlack.faded" ml={2}>
              Estimated Market Value
            </Text>
          </Box>
        </Flex>
      )}

      <Divider marginY={6} display={["block", "block", "none"]} />

      <Flex
        justifyContent="space-between"
        direction={["column", "column", "row", "row"]}
        alignItems="center"
      >
        <Box>
          <Flex
            direction={["column", "column", "column", "row"]}
            alignItems={["center", "center", "start", "start"]}
            textAlign="center"
          >
            <Heading as="h1" fontSize="17px" fontWeight="normal">
              {addressFormattedMain}
            </Heading>
          </Flex>
        </Box>

        <Divider marginY={6} display={["block", "block", "none"]} />
        {isMobile && (
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        )}

        {!isMobile && (
          <HStack spacing="6px">
            <TrackNeighbourhoodButton
              board={PROPERTY_BOARDS.UNLISTED}
              neighbourhoodId={property?.neighbourhood}
              neighbourhoodName={property?.neighbourhoodName}
            />
            <Button
              variant="outline"
              mt={[2, 0]}
              minW={["167px", 0]}
              leftIcon={<FaStreetView color={theme.colors.gold["500"]} size={16} />}
              onClick={onStreetViewClick}
              _hover={{ color: "inherit" }}
              color="inherit"
            >
              Street View
            </Button>
          </HStack>
        )}

        <Divider marginY={6} display={["block", "block", "none"]} />

        {isMobile && (
          <>
            <Flex
              direction={["column", "row"]}
              justifyContent="center"
              alignItems="center"
              minW={["249px", 0]}
            >
              <WatchPriceButton
                property={property}
                unwatchedText="Track Home Value"
                watchedText="Home Value is Tracked"
                onClick={() => {
                  gtag.event({
                    action: "Property_banner_track_value",
                  })
                }}
                buttonProps={{
                  minW: ["100%", 0],
                }}
              />

              <PrivateLeadButton
                text="Sell with HonestDoor"
                input={sellWithHDButtonInput}
                buttonProps={{
                  mt: [2, 0],
                  ml: [0, 2],
                  minW: ["100%", 0],
                  leftIcon: <GiFamilyHouse size={20} color={theme.colors.green["500"]} />,
                }}
                afterClick={() => push("/sell-with-honestdoor")}
              />
            </Flex>

            <Flex direction={["column", "row"]} justifyContent="center" alignItems="center" mt={2}>
              <Button
                variant="outline"
                mr={[0, 2]}
                mb={[2, 0]}
                minW={["100%", 0]}
                leftIcon={<FaStreetView color={theme.colors.gold["500"]} size={16} />}
                onClick={onStreetViewClick}
                _hover={{ color: "inherit" }}
                color="inherit"
              >
                Street View
              </Button>

              <TrackNeighbourhoodButton
                board={PROPERTY_BOARDS.UNLISTED}
                neighbourhoodId={property?.neighbourhood}
                neighbourhoodName={property?.neighbourhoodName}
              />
            </Flex>
          </>
        )}
      </Flex>

      <StreetViewModal
        address={addressFormattedFull}
        coordinates={coordinates}
        isOpen={streetViewOpen}
        onClose={() => setStreetViewOpen(false)}
      />
    </Box>
  )
}
