import { FC } from "react"
import { Flex, Link, Text } from "@chakra-ui/react"
import { RealtorLogo } from "~/components/shared"
import { TextVariants } from "~/theme/components"
import { CreaListing } from "~/generated/graphql"

interface IBrokerageProps {
  property: CreaListing
}
export const Brokerage: FC<IBrokerageProps> = ({ property }) => {
  const office = property?.agentDetails?.[0]?.office?.name

  return (
    <Flex alignItems="center" gridGap={4} flexDirection={["column", null, "row"]}>
      <Link href={property.moreInformationLink} target="_blank" rel="noreferrer" cursor="pointer">
        <RealtorLogo />
      </Link>
      <Text variant={TextVariants.body6} color="gray.500" ml={2}>
        <span>
          {office ? `Courtesy of ${office}. ` : ""}This listing content provided by REALTOR.ca has
          been licensed by REALTOR® members of the&nbsp;
          <Link target="_blank" href="https://www.crea.ca/" cursor="pointer" rel="noreferrer">
            Canadian Real Estate Association
          </Link>
          .
        </span>
      </Text>
    </Flex>
  )
}
