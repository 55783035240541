import { FC } from "react"
import orderBy from "lodash/orderBy"
import { useRouter } from "next/router"
import { FaArrowLeft } from "react-icons/fa"
import { Box, Button, Flex, Grid, Card } from "@chakra-ui/react"
import InternalLink from "~/components/elements/InternalLink"
import { Pagination } from "components/modules"
import { ButtonVariants } from "~/theme/components/Button"
import { createUrl } from "~/utils"
import { AllPropertyForSitemapQuery } from "~/generated/graphql"
import { GetCitiesQuery, GetNeighbourhoodsQuery } from "~/generated/graphql"

interface ISitemapNeighbourhoodLayout {
  province: { name: string; abbr: string }
  city: GetCitiesQuery["getCities"][number]
  data: AllPropertyForSitemapQuery
  neighbourhood: GetNeighbourhoodsQuery["getNeighbourhoods"][number]
}

export const SitemapNeighbourhoodLayout: FC<ISitemapNeighbourhoodLayout> = ({
  data,
  province,
  city,
  neighbourhood,
}) => {
  const router = useRouter()

  return (
    <>
      <Flex align={"center"} m={["20px 20px 0px 20px", "20px 20px 0px 20px", "24px 80px 0px 80px"]}>
        <Button
          leftIcon={<FaArrowLeft />}
          variant={ButtonVariants.BlackRounded}
          mr={"16px"}
          // onClick={() => router.push(`/sitemap/${province}/${encodeURIComponent(city)}`)}
          onClick={() =>
            router.push(
              createUrl({
                variant: "sitemap",
                city: city.name,
                provinceAbbr: province.abbr,
              }),
            )
          }
        >
          Back to {city.name}
        </Button>
        <InternalLink
          // href={`/cities/${provinceLongToProvinceAbbr(province)}/${encodeURIComponent(
          //   city,
          // )}/${neighbourhood}`}

          href={createUrl({
            variant: "cities",
            city: city.name,
            provinceAbbr: province.abbr,
            neighbourhood: neighbourhood.name,
          })}
        >
          {neighbourhood.name} Neighbourhood Stats
        </InternalLink>
      </Flex>
      <Card id="property_list" m={["20px", "20px", "80px"]} mt={["20px", "24px"]} w={null}>
        <h3 style={{ fontSize: "18px", marginBottom: "1em" }}>
          {neighbourhood.name} Neighbourhood Property Data
        </h3>
        <h6 style={{ fontSize: "16px", marginBottom: "1em" }}>Properties</h6>
        {data?.allProperty?.items?.length > 0 ? (
          <Grid
            templateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
          >
            {orderBy(data?.allProperty?.items, [property => property.unparsedAddress], ["asc"]).map(
              (property, i) => {
                return (
                  <Box key={i} mb={"0.3em"} fontSize={"14px"}>
                    <InternalLink href={`/property/${encodeURIComponent(property.slug)}`}>
                      {property.unparsedAddress}, {property.cityName}
                    </InternalLink>
                  </Box>
                )
              },
            )}
          </Grid>
        ) : (
          <h6 style={{ fontSize: "16px", marginBottom: "16px", textAlign: "center" }}>
            There are no properties available for this city yet!
          </h6>
        )}
      </Card>
      <Flex w="100%" justify="center" mb="20">
        <Pagination
          defaultCurrent={router.query.page ? Number(router.query.page as string) : 1}
          defaultPageSize={100}
          total={data?.allProperty?.meta?.total}
          onChange={page => {
            router.push({
              pathname: "/sitemap/[province]/[city]/[neighbourhood]",
              query: {
                page,
                province: router.query.province,
                city: router.query.city,
                neighbourhood: router.query.neighbourhood,
              },
            })
          }}
        />
      </Flex>
    </>
  )
}
