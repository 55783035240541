import { FC } from "react"
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Select,
  Stack,
  // useToast,
  Text,
  FormLabel,
  ModalBody,
  ModalFooter, ModalHeader
} from "@chakra-ui/react";
import { Form, Formik } from "formik"
import { Yup } from "~/utils/validation"
import { ButtonVariants } from "~/theme/components"
import { provinces } from "~/utils/constants"

const validationSchema = Yup.object().shape({
  province: Yup.string().required(),
  city: Yup.string().required(),
  streetAddress: Yup.string().required(),
  postalCode: Yup.string().required(),
})

export type CustomAddress = {
  province: string;
  city: string;
  streetAddress: string;
  postalCode?: string;
}

interface MlsNotFoundAddressModalProps {
  onSubmit: (address: CustomAddress) => void;
}

export const MlsNotFoundAddressModal: FC<Omit<ModalProps, "children"> & MlsNotFoundAddressModalProps> = ({
  onClose,
  onSubmit,
  ...modalProps
}) => {
  // const toast = useToast()
  return (
    <Modal {...modalProps} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Address not found?</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            province: "",
            city: "",
            streetAddress: "",
            postalCode: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm()
            onClose()
          }}
          // onSubmit={async (values, { setSubmitting, resetForm }) => {
          //   console.log(values)
          //   try {
          //     setSubmitting(true)
          //     // await mutation
          //     console.log(values)
          //     setSubmitting(false)
          //     toast({
          //       status: "success",
          //       title: "Success!",
          //       description: "Your request has been sent!",
          //       position: "top",
          //       isClosable: true,
          //     })
          //     resetForm()
          //     onClose()
          //   } catch (e) {
          //     setSubmitting(false)
          //     toast({
          //       status: "error",
          //       title: "Oops!",
          //       description: "Something went wrong. Please try again later...",
          //       position: "top",
          //       isClosable: true,
          //     })
          //   }
          // }}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleSubmit, getFieldProps, errors, touched, isSubmitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <Text mb={4}>
                    Hmm. Seems we can&apos;t find your address in our database.
                  </Text>
                  <Text mb={4}>
                    Please enter your address manually!
                  </Text>
                  <Stack spacing={4}>
                    <FormControl label="province" isInvalid={errors.province && touched.province}>
                      <FormLabel>Province</FormLabel>
                      <Select
                        {...getFieldProps("province")}
                        id="province"
                        placeholder="Select option"
                      >
                        {Object.values(provinces).map((province, key) => (
                          <option key={key} value={province}>
                            {province}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.province?.replace("province", "Province")}</FormErrorMessage>
                    </FormControl>

                    <Stack direction={["column", "column", "row"]} spacing={4}>
                      <FormControl label="city" isInvalid={errors.city && touched.city}>
                        <FormLabel>City</FormLabel>
                        <Input
                          {...getFieldProps("city")}
                          id="city"
                          type="text"
                          placeholder="City"
                        />
                        <FormErrorMessage>{errors.city?.replace("city", "City")}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        label="postalCode"
                        isInvalid={errors.postalCode && touched.postalCode}
                        w={["100%", "100%", "300px"]}
                      >
                        <FormLabel>Postal Code</FormLabel>
                        <Input
                          {...getFieldProps("postalCode")}
                          id="postalCode"
                          type="text"
                          placeholder="Postal Code"
                        />
                        <FormErrorMessage>{errors.postalCode?.replace("postalCode", "Postal Code")}</FormErrorMessage>
                      </FormControl>
                    </Stack>
                    <FormControl label="streetAddress" isInvalid={errors.streetAddress && touched.streetAddress}>
                      <FormLabel>Street Address</FormLabel>
                      <Input
                        {...getFieldProps("streetAddress")}
                        id="streetAddress"
                        type="text"
                        placeholder="Street Address"
                      />
                      <FormErrorMessage>{errors.streetAddress?.replace("streetAddress", "Street Address")}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    variant={ButtonVariants.Secondary}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )
          }}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
