export const PRIVATE_LISTING_ADDITIONAL_INFO = {


  buyers_commission: {
    label: 'Buyer’s Commission',
    description: 'Provide % willing to pay buyer’s agent',
    size: 1,
  },
  lot_frontage: {
    label: 'Lot Frontage',
    description: 'Please specify whether in sqm or sqft',
    size: 1,
  },
  property_taxes: {
    label: 'Property Taxes',
    description: 'Provide amount and which year it relates to',
    size: 1,
  },
  additional_owners: { // ???
    label: 'Additional Owners on Title?',
    description: 'If yes, please provide names',
    size: 1,
  },
  legal_description: {
    label: 'Legal Description',
    description: '',
    size: 1,
  },
  room_measurements: {
    label: 'Room Measurements',
    description: 'Meters or feet',
    size: 1,
  },
  neighbourhood_name: {
    label: 'Neighbourhood Name',
    size: 1,
  },
  currently_occupant: {
    label: 'Currently Occupant',
    description: 'Owner, tenant, or unoccupied',
    size: 1,
  },
  front_facing_exposure: {
    label: 'Front Facing Exposure',
    description: 'What direction does your home face?',
    size: 1,
    inputType: 'select',
    options: ['N', 'NE', 'NW', 'E', 'S', 'SW', 'SE', 'W'],
  },
  rms_square_footage: {
    label: 'RMS Square Footage',
    description: 'We will send you the measuring requirements. You can do this yourself or you can hire a professional. Many sellers use ListSimple.',
    size: 1,
  },
  total_lot_square_footage: {
    label: 'Total Lot Square Footage',
    size: 1,
  },
  type_of_house: {
    label: 'Type of House',
    description: 'Examples are 2 storey, bungalow, four level split?',
    size: 1,
  },
  heating_cooling_type: {
    label: 'Heating and Cooling Type?',
    size: 1,
  },
  fireplaces: {
    label: 'Fireplaces?',
    description: 'What type? (Electric, gas)',
    size: 1,
  },
  parking: {
    label: 'Parking',
    description: 'Double or single garage, driveway?',
    size: 1,
  },
  building_defects: {
    label: 'Any Building Defects',
    description: 'If yes, please provide information related to it',
    size: 1,
  },
  owners_on_title: {
    label: 'Owners on Title / Emails',
    description: 'Please provide all owners on title and their contact info',
    size: 2,
  },

  property_management_company: {
    label: 'Property Management Company and Phone Number',
    size: 2,
    condition: { houseStyle: 'CONDO' },
  },
  condo_plan: {
    label: 'Condo Plan',
    description: 'Legal unit number and unit factor',
    size: 1,
    condition: { houseStyle: 'CONDO' },
  },
  condo_fees: {
    label: 'Condo Fees',
    description: 'Amount and what do they include',
    size: 1,
    condition: { houseStyle: 'CONDO' },
  },
  building_amenities: {
    label: 'Building amenities?',
    size: 1,
    condition: { houseStyle: 'CONDO' },
  },
  condo_parking: {
    label: 'Parking Condo',
    description: 'Titled, assigned, is it underground or outdoor stall',
    size: 1,
    condition: { houseStyle: 'CONDO' },
  },
  storage: {
    label: 'Storage',
    description: 'Yes or no',
    size: 1,
    condition: { houseStyle: 'CONDO' },
  },
  condo_type: {
    label: 'Condo Type',
    size: 1,
    inputType: 'select',
    options: ['Conventional', 'Bareland'],
    condition: { houseStyle: 'CONDO' },
  },

  custom_text : {
    label: 'Custom Text',
    description: 'Provide any important information related to the property that was not asked',
    size: 4,
    inputType: 'textarea'
  },
}
