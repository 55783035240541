import { FC } from "react"
import { Button } from "@chakra-ui/button"
import { Box, Flex, Grid, Card } from "@chakra-ui/react"
import orderBy from "lodash/orderBy"
import { useRouter } from "next/router"
import { FaArrowLeft } from "react-icons/fa"
import InternalLink from "~/components/elements/InternalLink"
import { Pagination } from "components/modules"
import theme from "~/theme"
import { ButtonVariants } from "~/theme/components/Button"
import { createUrl } from "~/utils"
import { GetCitiesQuery, GetNeighbourhoodsQuery } from "~/generated/graphql"
import { AllPropertyForSitemapQuery } from "~/generated/graphql"

interface ISitemapCityLayout {
  province: { name: string; abbr: string }
  city: GetCitiesQuery["getCities"][number]
  data: GetNeighbourhoodsQuery | AllPropertyForSitemapQuery
}

export const SitemapCityLayout: FC<ISitemapCityLayout> = ({ data, city, province }) => {
  const router = useRouter()
  const total = "allProperty" in data ? data?.allProperty?.meta?.total : 0

  return (
    <>
      <Flex
        align="center"
        m={["20px 20px 0px 20px", "20px 20px 0px 20px", "24px 80px 0px 80px"]}
        textTransform="capitalize"
      >
        <Button
          leftIcon={<FaArrowLeft />}
          variant={ButtonVariants.BlackRounded}
          mr={"16px"}
          onClick={() => router.push("/sitemap")}
        >
          Back to Cities
        </Button>
        <InternalLink
          href={createUrl({
            variant: "cities",
            city: city.name,
            provinceAbbr: province.abbr,
          })}
        >
          {city.name} Stats
        </InternalLink>
      </Flex>
      {"allProperty" in data ? (
        <>
          <Card id="property_list" m={["20px", "20px", "80px"]} mt={["20px", "24px"]} w={null}>
            <h3 style={{ fontSize: "18px", marginBottom: "1em", textTransform: "capitalize" }}>
              {city.name} City Property Data
            </h3>
            <h6 style={{ fontSize: "16px", marginBottom: "1em" }}>Properties</h6>
            {data?.allProperty?.items?.length > 0 ? (
              <Grid
                templateColumns={[
                  "1fr",
                  "1fr",
                  "1fr 1fr",
                  "1fr 1fr",
                  "1fr 1fr 1fr",
                  "1fr 1fr 1fr 1fr",
                ]}
              >
                {orderBy(
                  data?.allProperty?.items,
                  [property => property.unparsedAddress],
                  ["asc"],
                ).map((property, i) => {
                  return (
                    <Box key={i} mb={"0.3em"} fontSize={"14px"} textTransform="capitalize">
                      <InternalLink href={`/property/${encodeURIComponent(property.slug)}`}>
                        {property.unparsedAddress}, {property.cityName}
                      </InternalLink>
                    </Box>
                  )
                })}
              </Grid>
            ) : (
              <h6 style={{ fontSize: "16px", marginBottom: "16px", textAlign: "center" }}>
                There are no properties available for this city yet!
              </h6>
            )}
          </Card>
          <Flex w={"100%"} justify={"center"} mb={"20"}>
            <Pagination
              defaultCurrent={router.query.page ? Number(router.query.page as string) : 1}
              defaultPageSize={100}
              total={total}
              onChange={page => {
                router.push({
                  pathname: "/sitemap/[province]/[city]",
                  query: { page, province: router.query.province, city: router.query.city },
                })
              }}
            />
          </Flex>
        </>
      ) : (
        <Card id="property_list" m={["20px", "20px", "80px"]} mt={["20px", "24px"]} w={null}>
          <h3 style={{ fontSize: "18px", marginBottom: "1em" }}>
            {city.name} City Property Data&nbsp;
            <span style={{ color: theme.colors.hdBlack["300"], fontStyle: "italic" }}>
              (# of properties)
            </span>
          </h3>
          <h6 style={{ fontSize: "16px", marginBottom: "1em" }}>Neighbourhoods</h6>
          {"getNeighbourhoods" in data && data?.getNeighbourhoods?.length > 0 ? (
            <Grid
              templateColumns={[
                "1fr",
                "1fr",
                "1fr 1fr",
                "1fr 1fr",
                "1fr 1fr 1fr",
                "1fr 1fr 1fr 1fr",
              ]}
            >
              {orderBy(data?.getNeighbourhoods, [neighbourhood => neighbourhood.name], ["asc"]).map(
                (neighbourhood, i) => {
                  return (
                    <Box key={i} mb={"0.3em"} fontSize={"14px"} textTransform="capitalize">
                      <InternalLink
                        href={createUrl({
                          variant: "sitemap",
                          city: neighbourhood.cityName,
                          provinceAbbr: city.provinceAbbr,
                          neighbourhood: neighbourhood.name,
                        })}
                      >
                        {neighbourhood.name}, {neighbourhood.cityName}
                        <span style={{ color: theme.colors.hdBlack["300"], fontStyle: "italic" }}>
                          &nbsp;({Number(neighbourhood.propertyCount)?.toLocaleString()})
                        </span>
                      </InternalLink>
                    </Box>
                  )
                },
              )}
            </Grid>
          ) : (
            <h6 style={{ fontSize: "16px", marginBottom: "16px", textAlign: "center" }}>
              There are no neighbourhoods available for this city yet!
            </h6>
          )}
        </Card>
      )}
    </>
  )
}
