import { FC } from "react"
import { Box, Button, Flex, Text, useDisclosure, Card } from "@chakra-ui/react"
import { FiArrowUpRight } from "react-icons/fi"
import { RiShieldCheckFill } from "react-icons/ri"
import { TextVariants, ButtonVariants, CardVariants } from "~/theme/components"
import { ProvinceName } from "~/utils"
import { BuyingOrSellingCTAModal } from "./buying-or-selling-CTA.modal"
import House from "~/public/images/houseplaceholder.jpg"
import { PROPERTY_BOARDS } from "~/utils/constants"
import gtag from "~/utils/lib/gtag"

interface IBuyingOrSellingCTAProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  province: ProvinceName | string
  address: string
  variant?: "full" | "inline"
}
export const BuyingOrSellingCTA: FC<IBuyingOrSellingCTAProps> = ({
  board,
  province,
  address,
  variant = "full",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = () => {
    if (board === PROPERTY_BOARDS.UNLISTED) {
      gtag.event({
        action: "Property_ask_for_intro",
      })
    }
    onOpen()
  }

  if (!province) return null

  return (
    <Card variant={CardVariants.CallToAction} flexGrow={1} position="relative">
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="blue.500"
        width={1}
        height="100%"
      />
      <Flex
        paddingX={2}
        alignItems="center"
        direction={["column", null, null, "row"]}
        gridGap={[5, null, null, 0]}
      >
        <Box flexGrow={1}>
          <Box
            w="104px"
            h="104px"
            backgroundImage={House.src}
            backgroundSize="cover"
            backgroundPosition="center"
            borderRadius="full"
            position="relative"
          >
            <Box position="absolute" top={0} right={0} color="blue.500">
              <RiShieldCheckFill size={40} />
            </Box>
          </Box>
        </Box>

        <Flex direction="column" marginLeft={{ base: 0, md: 0, lg: 10 }} flexGrow={1} width="100%">
          <Text variant={TextVariants.heading3} marginBottom={4}>
            Buying or Selling?
          </Text>
          <Text>HonestDoor has partnered with verified real estate agents.</Text>

          {variant === "inline" && (
            <Box marginTop={5}>
              <Button
                rightIcon={<FiArrowUpRight size={20} />}
                variant={ButtonVariants.Primary}
                onClick={handleClick}
              >
                Ask Us for an Intro
              </Button>
            </Box>
          )}
        </Flex>

        {variant === "full" && (
          <Box marginTop={5}>
            <Button
              rightIcon={<FiArrowUpRight size={20} />}
              variant={ButtonVariants.Primary}
              onClick={handleClick}
            >
              Ask Us for an Intro
            </Button>
          </Box>
        )}
      </Flex>

      <BuyingOrSellingCTAModal
        province={province}
        address={address}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  )
}
