import { FC } from "react"
import { Box, Button, Flex, Text, Card } from "@chakra-ui/react"
import { FiArrowUpRight } from "react-icons/fi"
// import { RocketMortgageLogoSvg } from "~/components/logos"
import { ButtonVariants, CardVariants, TextVariants } from "~/theme/components"
import { InternalLink } from "~/components/elements"
// import { getProvince } from "~/utils/helpers"
// import HomewiseLogoSvg from "~/components/logos/HomewiseLogoSvg"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"
import gtag from "~/utils/lib/gtag"

interface IMortgageCTA {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty | PrivateListing | CreaListing
}
export const MortgageCTA: FC<IMortgageCTA> = ({ board }) => {
  // const province = useMemo(() => getProvince(property), [property])

  return (
    <Card variant={CardVariants.CallToAction} flexGrow={1} position="relative">
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="gold.500"
        width={1}
        height="100%"
      />
      <Flex
        paddingX={2}
        justifyContent="space-between"
        alignItems="center"
        direction={["column", "column", "column", "row"]}
        gridGap={6}
      >
        {/*<Box flexGrow={1}>*/}
        {/*  {province === "Ontario" ? <HomewiseLogoSvg /> : <RocketMortgageLogoSvg />}*/}
        {/*</Box>*/}

        <Flex direction="column" flexGrow={1}>
          <Text variant={TextVariants.heading3} marginBottom={4}>
            Need a mortgage?
          </Text>
          <Text>
            {/*We&apos;ve partnered with {province === "Ontario" ? "Homewise" : "Rocket Mortgage"} for*/}
            We&apos;ve partnered with {"Rocket Mortgage"} for
            mortgages, renewals, and lines of credit.
          </Text>
        </Flex>

        <Box width={["100%", null, null, "auto"]}>
          <InternalLink
            // href={province === "Ontario" ? "/homewise-mortgage" : "/rocket-mortgage"}
            href={"/rocket-mortgage"}
            onClick={() => {
              if (board === PROPERTY_BOARDS.UNLISTED) {
                gtag.event({
                  action: "Property_rocket_banner",
                })
              }
            }}
          >
            <Button
              width={["100%", null, null, "auto"]}
              rightIcon={<FiArrowUpRight size={20} />}
              variant={ButtonVariants.Primary}
            >
              Complete the 1-Minute Form
            </Button>
          </InternalLink>
        </Box>
      </Flex>
    </Card>
  )
}
