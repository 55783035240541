import { FC, useEffect, useMemo, useRef } from "react"
import { Box, Text, useTheme } from "@chakra-ui/react"
import { format } from "date-fns"
import { EChartsOption } from "echarts"
import ReactEChartsCore from "echarts-for-react/lib/core"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import { SVGRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import * as echarts from "echarts/core"
import { TextVariants } from "~/theme/components"
import { DividedGrid } from "~/components/elements"
import { ValueHighlight } from "~/components/layouts/property/sections/analysis/value-highlight"
import { ChartData } from "./price-history"

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  SVGRenderer,
  LineChart,
])

interface IPriceLineGraphProps {
  data?: ChartData[]
  cityData?: ChartData[]
  neighbourhoodData?: ChartData[]
  name?: string
  returnsRange?: "Month" | "Year"
  returnsPeriod?: number[]
  displayStacked?: boolean
}
export const PriceLineGraph: FC<IPriceLineGraphProps> = props => {
  const { data, cityData, neighbourhoodData } = props
  const ref = useRef<any>()
  const theme = useTheme()
  const colorPalette = [theme.colors.gold[500], theme.colors.green[500], theme.colors.blue[500]]

  useEffect(() => {
    window.addEventListener("resize", resizeCharts)
    return () => {
      window.removeEventListener("resize", resizeCharts)
    }
  }, [ref])

  const { values, dates, cityValues, neighbourhoodValues, mostRecent } = useMemo(() => {
    const dates = data?.map(d => format(d.date, props?.returnsRange === "Year" ? "yyyy" : "MMM yy"))
    const values = data?.map(d => d.value)
    const searchFormat = props?.returnsRange === "Year" ? "yyyy" : "MMM yy"
    const cityValues = dates.map(date => {
      return cityData?.find(
        city => format(new Date(date), searchFormat) === format(new Date(city.date), searchFormat),
      )?.value
    })
    const neighbourhoodValues = dates.map(
      date =>
        neighbourhoodData?.find(
          neighbourhood =>
            format(new Date(date), searchFormat) ===
            format(new Date(neighbourhood.date), searchFormat),
        )?.value,
    )

    const mostRecent = format(
      data?.slice(-1)[0]?.date,
      props?.returnsRange === "Year" ? "yyyy" : "MMMM",
    )

    return { values, dates, cityValues, neighbourhoodValues, mostRecent }
  }, [data])

  const returns = useMemo(() => {
    return props?.returnsPeriod
      ?.map(n => {
        return {
          position: n,
          value: cumulativeReturn(n),
        }
      })
      .filter(_return => !isNaN(_return.value))
  }, [props])

  const resizeCharts = () => {
    if (ref.current) {
      const echartInstance = ref.current.getEchartsInstance()
      echartInstance?.resize()
    }
  }

  // Looks back over `n` previous values and calculates the return as a percentage
  function cumulativeReturn(n) {
    return (
      Math.round(
        ((values[values.length - 1] - values[values.length - (n + 1)]) /
          values[values.length - (n + 1)]) *
          10000,
      ) / 100
    )
  }

  const option: EChartsOption = {
    animationDuration: 100,
    theme: "light",

    legend: {
      bottom: 5,
      data: [
        props.name,

        // @TODO null throw a warning, investigate
        // neighbourhoodData ? "Neighbourhood Average" : null,
        // cityData ? "City Average" : null,

        // @TODO: R18
        neighbourhoodData ? "Neighbourhood Average" : "",
        cityData ? "City Average" : "",
      ],
      selected: {
        [props.name]: true,
        "Neighbourhood Average": false,
        "City Average": false,
      },
    },

    tooltip: {
      trigger: "axis",
      confine: true,
    },

    grid: {
      left: 30,
      right: 10,
      bottom: 40,
      top: 10,
      containLabel: true,
    },

    xAxis: {
      type: "category",
      data: dates,
      axisTick: { show: false },
      axisLabel: {
        color: "#282F45",
        margin: 8,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      boundaryGap: [0, 0.01],
      offset: 30,
      axisLabel: {
        margin: 0,
        align: "left",
        inside: true,
        padding: [0, 8, 0, 0],
        backgroundColor: "#F7FAFC",
        formatter: function (value) {
          return value >= 1000 ? "$" + value / 1000 + "K" : "$" + value
        },
      },
      min: function (value) {
        return Math.floor((value.min - (value.max - value.min) / 2) / 10000) * 10000 > 0
          ? Math.floor((value.min - (value.max - value.min) / 2) / 10000) * 10000
          : 0
      },
      max: function (value) {
        return Math.ceil((value.max + (value.max - value.min) / 2) / 10000) * 10000
      },
    },

    series: [
      {
        name: props.name,
        type: "line",
        color: colorPalette,
        data: values,
      },
      {
        name: "Neighbourhood Average",
        type: "line",
        color: colorPalette[1],
        data: neighbourhoodValues,
      },
      {
        name: "City Average",
        type: "line",
        color: colorPalette[2],
        data: cityValues,
      },
    ],
  }

  return (
    <Box
      width="100%"
      minWidth={0}
      display="flex"
      flexDirection={props.displayStacked ? "column" : ["column", null, null, null]}
      alignItems="stretch"
      gridRowGap={4}
    >
      <Box>
        <Box mb={6} display="flex" alignItems="center">
          <Text variant={TextVariants.heading2}>
            {`$${data[data.length - 1]?.value?.toLocaleString()}`}
          </Text>
          <Text ml={4} variant={TextVariants.body2} color="gray.600" fontWeight="bold">
            {`(${mostRecent})`}
          </Text>
        </Box>

        <Text mb={6} variant={TextVariants.heading4} fontWeight="400">
          Estimated returns over time
        </Text>
        <DividedGrid columns={[4]} width={0} minWidth="auto">
          {returns?.map((_return, i) => (
            <Box
              mr={[1, 3, 6]}
              ml={i > 0 ? [1, 3, 6] : 0}
              key={i}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text variant={TextVariants.heading5} mb={3} whiteSpace="nowrap">
                {`${_return.position} ${props.returnsRange}`}
              </Text>
              <ValueHighlight
                highlight={
                  _return.value > 0 ? "positive" : _return.value < 0 ? "negative" : "neutral"
                }
                showIndicator
              >
                {_return.value}%
              </ValueHighlight>
            </Box>
          ))}
        </DividedGrid>
      </Box>

      <Box>
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          ref={e => {
            ref.current = e
          }}
        />
      </Box>
    </Box>
  )
}
