import { FC, useMemo, useRef } from "react"
import { observer } from "mobx-react-lite"
import dynamic from "next/dynamic"
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react"
import { DefaultLayout, FullScreenLayout } from "~/components/layouts"
import { Breadcrumbs, StickyToolbar, Tooltip } from "~/components/modules"
import {
  useCreaFiltersStore,
  useMapFiltersStore,
  useMapStore,
  usePropertyFilterStore,
  useRecentlySoldFilterStore,
} from "~/store/StoreProvider"
import MapDrawerContent from "~/components/modules/Map/MapDrawer"
import { MapView } from "~/store/map"
import { FaChevronLeft, FaChevronRight, FaMapMarkedAlt } from "react-icons/fa"
import { BsGrid3X3GapFill } from "react-icons/bs"

const PageModal = dynamic(() => import("components/modules/page-modal"), {
  ssr: false,
})

const Map = dynamic(() => import(/* webpackPrefetch: 0 */ "~/components/modules/Map/Map"), {
  ssr: false,
})

const MapFilters = dynamic(() => import("~/components/modules/Map/MapFilters"), {
  ssr: false,
})

export const MapLayout: FC = observer(() => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const { view, toggleView, drawerOpen, toggleDrawer } = useMapStore()
  const { activeCategoryPageTitle } = useMapFiltersStore()
  const { filters: listingFilters } = useCreaFiltersStore()
  const { filters: recentlySoldFilters } = useRecentlySoldFilterStore()
  const { filters: propertyFilters } = usePropertyFilterStore()
  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false }, "lg")
  const isListView = view === MapView.LIST

  useMemo(() => {
    if (drawerRef.current) {
      drawerRef.current.scrollTop = 0
    }
  }, [listingFilters, recentlySoldFilters, propertyFilters])

  const showListView = isMobile ? isListView : true

  return (
    <>
      <StickyToolbar>
        <MapFilters />
      </StickyToolbar>

      <Flex width="100%" height="100%">
        {!isListView && (
          <Flex w="100%" height="100%" position="relative">
            <Map />
            <Tooltip placement="left" label={`${drawerOpen ? "Close" : "Open"} drawer`} hasArrow>
              <IconButton
                display={["none", "none", "none", "flex"]}
                size={"sm"}
                colorScheme={"hdGold"}
                shadow={"md"}
                zIndex={999}
                rounded={"full"}
                onClick={toggleDrawer}
                position="absolute"
                right={drawerOpen ? "-16px" : 4}
                top="50%"
                transform={`translateY(-50%)`}
                aria-label="toggle drawer"
                icon={drawerOpen ? <Icon as={FaChevronRight} /> : <Icon as={FaChevronLeft} />}
              />
            </Tooltip>
          </Flex>
        )}

        {showListView && (drawerOpen || isListView) && !isListView && (
          <FullScreenLayout>
            <Flex
              width="100%"
              height="100%"
              minH="85vh"
              maxW={["100%", "100%", "100%", "675px"]}
              display={["none", "none", "none", "flex"]}
              borderLeftWidth="1px"
              position="relative"
            >
              <Box
                ref={drawerRef}
                maxW={"1600px"}
                py={[5, 7, 7, 10]}
                px={[2, 6, 6, 10]}
                position="absolute"
                top={0}
                right={0}
                left={0}
                bottom={0}
                overflowY={"scroll"}
              >
                <Heading as="h1">{activeCategoryPageTitle}</Heading>
                <MapDrawerContent />
              </Box>
            </Flex>
          </FullScreenLayout>
        )}
      </Flex>

      {showListView && (drawerOpen || isListView) && isListView && (
        <DefaultLayout>
          <Breadcrumbs />
          <Heading as="h1">{activeCategoryPageTitle}</Heading>
          <MapDrawerContent />
        </DefaultLayout>
      )}

      <Box
        position="fixed"
        bottom={7}
        left="50%"
        transform="translateX(-50%)"
        zIndex={999}
        display={["initial", null, null, "none"]}
      >
        <Button
          shadow="md"
          leftIcon={view === MapView.MAP ? <BsGrid3X3GapFill /> : <FaMapMarkedAlt />}
          size="md"
          variant="white-rounded"
          onClick={toggleView}
        >
          {view === MapView.MAP ? "List" : "Map"}
        </Button>
      </Box>
      <PageModal />
    </>
  )
})
