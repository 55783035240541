import { FC } from "react"
import { SectionContainer } from "~/components/elements"
import SubNavbar, { TCrumbItem } from "~/components/modules/SubNavbar"
import { createUrl } from "~/utils"
import { formatStreet } from "~/utils/lib/string"
import { getLocation, isPrivateListing, isPublicListing, isUnlisted } from "~/utils/helpers"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

interface INavbarProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty | PrivateListing | CreaListing
}
export const Navbar: FC<INavbarProps> = ({ board, property }) => {
  const publicListing = isPublicListing(property) ? property : null
  const privateListing = isPrivateListing(property) ? property : null
  const unlisted = isUnlisted(property) ? property : null
  const { provinceAbbreviated, city, neighbourhood, address } = getLocation(property)
  const street = formatStreet(address)
  const routeBase = privateListing ? "real-estate" : "cities"

  const crumbs: TCrumbItem[] = [
    {
      value: privateListing ? "Real Estate" : "Cities",
      href: `/${routeBase}`,
    },
    {
      value: provinceAbbreviated,
      noLink: true,
    },
    {
      value: city,
      href: createUrl({
        variant: routeBase,
        city: city,
        provinceAbbr: provinceAbbreviated,
      }),
    },

    {
      value: neighbourhood,
      href: createUrl({
        variant: routeBase,
        city: city,
        provinceAbbr: provinceAbbreviated,
        neighbourhood,
      }),
    },

    { value: street, noLink: true },
  ]

  return (
    <SectionContainer showDivider={false} mt={8}>
      <SubNavbar
        board={board}
        crumbItems={crumbs}
        slug={property.slug}
        privateListing={privateListing}
        property={unlisted}
        creaListing={publicListing}
      />
    </SectionContainer>
  )
}
