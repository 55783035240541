import { FC } from "react"
import orderBy from "lodash/orderBy"
import { Box, Grid, Card } from "@chakra-ui/react"
import InternalLink from "~/components/elements/InternalLink"
import theme from "~/theme"
import { createUrl } from "~/utils"
import { GetCitiesQuery } from "~/generated/graphql"

interface ISitemapLayout {
  cities: GetCitiesQuery["getCities"]
}

export const SitemapLayout: FC<ISitemapLayout> = ({ cities }) => {
  return (
    <Card m={["20px", "20px", "80px"]} w={null}>
      <h3 style={{ fontSize: "18px", marginBottom: "1em" }}>
        Canada Property Data&nbsp;
        <span style={{ color: theme.colors.hdBlack["300"], fontStyle: "italic" }}>
          (# of properties)
        </span>
      </h3>
      <h6 style={{ fontSize: "16px", marginBottom: "1em" }}>Cities</h6>

      <Grid
        templateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
      >
        {orderBy(cities, [city => city.name], ["asc"]).map((city, i) => {
          return (
            <Box key={i} fontSize="14px" mb="0.3em" textTransform="capitalize">
              <InternalLink
                href={createUrl({
                  variant: "sitemap",
                  city: city.name,
                  provinceAbbr: city.provinceAbbr,
                })}
              >
                {city.name}, {city.provinceAbbr}
                <Box as="span" fontStyle="italic" color="hdBlack.300">
                  &nbsp;({city.propertyCount?.toLocaleString()})
                </Box>
              </InternalLink>
            </Box>
          )
        })}
      </Grid>
    </Card>
  )
}
