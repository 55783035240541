import { FC, useEffect, useRef, useState } from "react"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import {
  Box,
  Button,
  Grid,
  GridItem,
  IconButton,
  useBreakpointValue,
  useDimensions,
} from "@chakra-ui/react"
import { IoMapSharp } from "react-icons/io5"
import { FaChevronLeft, FaChevronRight, FaImages, FaStreetView } from "react-icons/fa"
import { getCoordinates, getLocation, getPhotos } from "~/utils/helpers"
import { SectionContainer } from "~/components/elements"
import { ButtonVariants } from "~/theme/components"
// import { ImageCarouselMobileModal } from "./image-carousel-mobile.modal"
import { Image as LazyImage, MapModal, StreetViewModal } from "~/components/modules"
import { CreaListing, PrivateListing } from "~/generated/graphql"
import NoImage from "~/public/images/placeholders/no-image.jpg"
import "photoswipe/style.css"

interface IImageGallery {
  property?: PrivateListing | CreaListing
  height?: number
  gap?: number
  columnsShown?: number[]
}

export const ImageGallery: FC<IImageGallery> = ({
  property,
  height = 440,
  gap = 4,
  columnsShown = [1, 1, 3, 4, 4],
}) => {
  // @ts-ignore
  const lightboxRef = useRef<PhotoSwipeLightbox | null>(null)
  const galleryRef = useRef()
  const coordinates = getCoordinates(property)
  const { addressFormattedFull } = getLocation(property)
  const photos = getPhotos(property)
  const noImages = photos.length === 0
  const images = noImages ? [NoImage.src] : photos
  const [isMapModalOpen, setIsMapModelOpen] = useState(false)
  const [isStreetViewOpen, setIsStreetViewOpen] = useState(false)
  // const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [columnWidth, setColumnWidth] = useState(0)
  const carouselRef = useRef<HTMLDivElement>()
  const carouselWidth = useDimensions(carouselRef, true)?.borderBox?.width
  const columnsToShow = useBreakpointValue(columnsShown)
  const columnsShownCount = Math.min(columnsToShow, images.length)

  // If we have enough images to show two rows, do so (but don't if we only have 1 column)
  const rows = columnsToShow === 1 ? 1 : images.length >= columnsToShow * 2 - 3 ? 2 : 1
  const columns = Math.ceil((rows === 2 ? images.length + 3 : images.length) / rows)
  const maxIndex = columns - columnsShownCount
  const hasLeadingImage = rows === 2
  const isMobile = columnsToShow === 1

  // Resize images if the container size changes
  useEffect(() => {
    setColumnWidth(carouselWidth / columnsShownCount)
  }, [carouselWidth, columnsShownCount])

  useEffect(() => {
    if (index > maxIndex) setIndex(maxIndex)
    const offset = index * (columnWidth + gap)
    carouselRef.current?.scroll({ left: offset, behavior: "smooth" })
  }, [columnWidth, index])

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
      wheelToZoom: true,
      showAnimationDuration: 10,
      hideAnimationDuration: 10,
      preloadFirstSlide: true,
      bgClickAction: "close",
      tapAction: "close",
    })
    lightbox.addFilter("itemData", itemData => {
      const img = new Image()
      img.onload = function () {
        return {
          src: itemData.src,
          width: img.width,
          height: img.height,
        }
      }
      img.src = itemData.src
      return img
    })
    lightbox.init()
    lightboxRef.current = lightbox

    return () => {
      lightbox.destroy()
      lightbox = null
      lightboxRef.current = null
    }
  }, [])

  const scrollLeft = () => {
    // If we have a leading double wide image, we need to scroll past it
    const scrollAmount = index === 2 && hasLeadingImage ? 2 : 1
    setIndex(Math.max(index - scrollAmount, 0))
  }

  const canScrollLeft = () => {
    return index > 0
  }

  const scrollRight = () => {
    // If we have a leading double wide image, we need to scroll past it
    const scrollAmount = index === 0 && hasLeadingImage ? 2 : 1
    setIndex(Math.min(index + scrollAmount, maxIndex))
  }

  const canScrollRight = () => {
    return index < maxIndex
  }

  const shouldSkipLazyLoad = (idx: number) => {
    return idx <= 2 || (idx >= columns - 1 && idx <= columns)
  }

  const openPopup = (index: number) => {
    if (noImages) return
    lightboxRef.current.loadAndOpen(index, {
      gallery: galleryRef.current,
    })
  }

  const getRange = () => {
    let start = index * rows + 1
    let end = start + columnsShownCount * rows - 1
    if (hasLeadingImage) {
      start = Math.max(start - 3, 1)
      end -= 3
    }
    end = Math.min(end, images.length)
    return start === end ? start : `${start}-${end}`
  }

  const leftFloatButton = (
    <IconButton
      aria-label="scroll carousel left"
      pointerEvents="auto"
      icon={<FaChevronLeft />}
      variant="ghost"
      onClick={scrollLeft}
      disabled={!canScrollLeft()}
      mr={isMobile ? 0 : 4}
      ml={isMobile ? 2 : 0}
      isActive={isMobile ? true : undefined}
    />
  )

  const rightFloatButton = (
    <IconButton
      aria-label="scroll carousel right"
      pointerEvents="auto"
      icon={<FaChevronRight />}
      variant="ghost"
      onClick={scrollRight}
      disabled={!canScrollRight()}
      ml={isMobile ? 0 : 4}
      mr={isMobile ? 2 : 0}
      isActive={isMobile ? true : undefined}
    />
  )

  return (
    <SectionContainer
      showDivider={false}
      floatLeft={!isMobile && leftFloatButton}
      floatRight={!isMobile && rightFloatButton}
    >
      {isMobile && (
        <Box display="flex" alignItems="center" justifyContent="space-around">
          <Button isActive my={4} variant={ButtonVariants.Link} leftIcon={<FaImages />}>
            Pictures
          </Button>
          <Button
            my={4}
            variant={ButtonVariants.Link}
            leftIcon={<IoMapSharp />}
            onClick={() => setIsMapModelOpen(true)}
          >
            Map
          </Button>
          <Button
            my={4}
            variant={ButtonVariants.Link}
            leftIcon={<FaStreetView />}
            onClick={() => setIsStreetViewOpen(true)}
          >
            Street View
          </Button>
        </Box>
      )}

      <Box position="relative">
        <Box ref={carouselRef} overflow="hidden" width="100%" borderRadius="lg">
          <Grid
            templateColumns={`repeat(${columns}, 1fr)`}
            templateRows={`repeat(${rows}, 1fr)`}
            gap={`${gap}px`}
            className="pswp-gallery"
            id="gallery"
            ref={galleryRef}
          >
            {images.map((image, index) => (
              <GridItem
                key={index}
                colSpan={hasLeadingImage && index === 0 ? 2 : 1}
                rowSpan={hasLeadingImage && index === 0 ? 2 : 1}
                // Add a bit of extra height to the leading image to account for the gap
                height={`${height / rows + (hasLeadingImage && index === 0 ? gap : 0)}px`}
                width={`${hasLeadingImage && index === 0 ? columnWidth * 2 : columnWidth}px`}
                position={"relative"}
              >
                <a href={image} key={"gallery" + "-" + index} target="_blank" rel="noreferrer">
                  <LazyImage
                    src={image}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      width: hasLeadingImage && index === 0 ? "200%" : "100%",
                      height: hasLeadingImage && index === 0 ? "200%" : "100%",
                    }}
                    alt=""
                    wrapperProps={{
                      style: {
                        display: "contents",
                      },
                    }}
                    visibleByDefault={shouldSkipLazyLoad(index)}
                    loading={shouldSkipLazyLoad(index) ? "eager" : "lazy"}
                  />
                </a>
              </GridItem>
            ))}
          </Grid>
        </Box>

        {!isMobile && (
          <>
            <Box position="absolute" bottom={2} left={2}>
              <Button
                variant={ButtonVariants.Floating}
                leftIcon={<IoMapSharp />}
                mr={2}
                onClick={() => setIsMapModelOpen(true)}
              >
                Map
              </Button>
              <Button
                variant={ButtonVariants.Floating}
                leftIcon={<FaStreetView />}
                onClick={() => setIsStreetViewOpen(true)}
              >
                Street View
              </Button>
            </Box>
          </>
        )}

        <Box position="absolute" bottom={2} right={2}>
          <Button
            variant={ButtonVariants.Floating}
            rightIcon={<FaImages />}
            onClick={() => openPopup(0)}
          >{`${getRange()} of ${images.length}`}</Button>
        </Box>
        {isMobile && (
          <>
            <Box
              pointerEvents="none"
              position="absolute"
              height="100%"
              bottom={0}
              left={0}
              color="white"
              display="flex"
              alignItems="center"
            >
              {leftFloatButton}
            </Box>
            <Box
              pointerEvents="none"
              position="absolute"
              height="100%"
              bottom={0}
              right={0}
              color="white"
              display="flex"
              alignItems="center"
            >
              {rightFloatButton}
            </Box>
          </>
        )}
      </Box>

      {/*<ImageCarouselMobileModal*/}
      {/*  images={images}*/}
      {/*  isOpen={isCarouselModalOpen && isMobile}*/}
      {/*  onClose={() => setIsCarouselModalOpen(false)}*/}
      {/*/>*/}
      <StreetViewModal
        address={addressFormattedFull}
        coordinates={coordinates}
        isOpen={isStreetViewOpen}
        onClose={() => setIsStreetViewOpen(false)}
      />
      <MapModal
        isOpen={isMapModalOpen}
        coordinates={coordinates}
        onClose={() => setIsMapModelOpen(false)}
      />
    </SectionContainer>
  )
}
