import React, { FC } from "react"
import { Box, Button, Card, Flex, Img, Text } from "@chakra-ui/react";
import { ButtonVariants, CardVariants, TextVariants } from "~/theme/components"
import { GiFamilyHouse } from "react-icons/gi"
import theme from "~/theme"
import { InternalLink } from "~/components/elements"
import gtag from "~/utils/lib/gtag"
import { FiArrowUpRight } from "react-icons/fi"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"
import { EsProperty, useCreatePrivateLeadMutation } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"
import { useUser } from "~/components/hooks"

const pros = [
  {
    title: "Save big on commission fees",
  },
  {
    title: "No hidden costs",
  },
  {
    title: "Advertised everywhere online",
  },
  {
    title: "Full transparency",
  },
]

interface ISellWithHd {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty
}

export const SellWithHd: FC<ISellWithHd> = ({ property }) => {
  const { user } = useUser()
  const { mutateAsync: createPrivateLead } = useCreatePrivateLeadMutation()

  return (
    <Card variant={CardVariants.CallToAction} flexGrow={1} position="relative" p={0}>
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="gold.500"
        width="8px"
        height="100%"
        display={["none", "none", "flex"]}
      />
      <Flex justifyContent="start" alignItems="center" direction={["column", "column", "row"]}>
        <Flex
          flexShrink={0}
          width={["100%", "100%", "initial"]}
          mt={["24px", "24px", "0"]}
          justifyContent="center"
        >
          <Img src="/images/list-page-frame.jpg" borderRadius="8px" htmlWidth={200} htmlHeight={200} />
        </Flex>

        <Flex
          direction="column"
          mx="24px"
          mt={["16px", "16px", 0]}
          py="16px"
          w={["auto", "auto", "50%"]}
        >
          <Flex>
            <GiFamilyHouse size={20} color={theme.colors.green["500"]} />
            <Text mb="16px" ml="8px" variant={TextVariants.heading4}>
              Sell with HonestDoor
            </Text>
          </Flex>

          <Text mb={4}>HonestDoor can now list your home for you on Realtor.ca and the MLS®.</Text>

          <InternalLink
            href="/sell-with-honestdoor"
            linkProps={{ textAlign: "center" }}
            onClick={async () => {
              gtag.event({
                category: "MLS",
                action: "property_list_on_mls_banner",
              })

              if (user) {
                await createPrivateLead({
                  input: {
                    source: PrivateLeadSource.SellPropertyBanner,
                    type: PrivateLeadType.Sell,
                    propertyId: property.id,
                    slug: property.slug,
                    streetAddress: property.unparsedAddress,
                    cityId: property.city,
                    cityName: property.cityName,
                    neighbourhoodId: property.neighbourhood,
                    neighbourhoodName: property.neighbourhoodName,
                    province: property.province,
                    hdPrice: property.predictedValue,
                  },
                })
              }
            }}
          >
            <Button variant={ButtonVariants.Link} rightIcon={<FiArrowUpRight size={20} />}>
              Learn More
            </Button>
          </InternalLink>
        </Flex>

        <Flex
          wrap={["nowrap", "nowrap", "wrap"]}
          direction={["column", "column", "row"]}
          mt="-16px"
          mb={["16px", "16px", 0]}
          py="16px"
          px={["24px", "24px", 0]}
          mx={["24px", "24px", 0]}
          w="100%"
        >
          {pros.map((item, i) => (
            <Flex key={i} direction="column" mr={[0, 0, "24px"]} mt={["16px"]}>
              <Flex direction={["row", "row", "row"]} alignItems={["center", "center", "start"]}>
                <Img src="/icons/verified.svg" htmlWidth={25} htmlHeight={25} />
                <Text
                  mt={["0", "0px", 0]}
                  ml={["8px", "8px", "8px"]}
                  fontSize={["18px", "18px", "18px"]}
                  lineHeight={["22px", "22px", "24px"]}
                  fontWeight={["600", "600", "400"]}
                  textAlign={["left", "left", "left"]}
                >
                  {item.title}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Card>
  )
}
