import dynamic from "next/dynamic"
import { observer } from "mobx-react-lite"
import { useMapFiltersStore } from "~/store/StoreProvider"
import { FilterCategory } from "~/store/mapFilters"

const CreaDrawer = dynamic(() => import("./CreaDrawer"), {
  ssr: true,
})

const RecentlySoldDrawer = dynamic(() => import("./RecentlySoldDrawer"), {
  ssr: true,
})

const PropertiesDrawer = dynamic(() => import("./PropertiesDrawer"), {
  ssr: true,
})

const MapDrawer = (): JSX.Element => {
  const { filterCategory } = useMapFiltersStore()

  if (filterCategory === FilterCategory.ForSale) {
    return <CreaDrawer />
  }

  if (filterCategory === FilterCategory.Properties) {
    return <PropertiesDrawer />
  }

  if (filterCategory === FilterCategory.RecentlySold) {
    return <RecentlySoldDrawer />
  }

  return null
}

export default observer(MapDrawer)
