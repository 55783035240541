import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useInView } from "react-intersection-observer"
import { Flex, Link } from "@chakra-ui/react"
import { getCreateUserUUID } from "~/utils"
import {
  AdEventType,
  AdItem,
  AdType,
  AdItemTargetingInput,
  useCreateAdEventMutation,
  useQueryAdItemQuery,
} from "~/generated/graphql"
import { Image } from "~/components/modules"

type AdProps = {
  vendorNames?: string[]
  variant: AdType
  bottomMargin?: boolean
  // TODO: define cases and think on flexible and universal solution for ad targeting
  targeting?: AdItemTargetingInput
}

export const VendorAd = (props: AdProps): JSX.Element => {
  const { ref, inView } = useInView()

  const [viewed, setViewed] = useState(false)

  const router = useRouter()
  const { asPath } = router

  const { data: adItemData, isLoading } = useQueryAdItemQuery(
    {
      input: {
        vendorNames: props.vendorNames,
        targeting: props.targeting,
        adType: props.variant,
        isActive: true,
      },
    },
    { cacheTime: 500 },
  )

  const { mutateAsync } = useCreateAdEventMutation()

  const adItem: AdItem | null =
    adItemData?.queryAdItem[Math.floor(Math.random() * adItemData?.queryAdItem.length)]

  useEffect(() => {
    if (inView) {
      setViewed(true)
    }
  }, [inView])

  useEffect(() => {
    setViewed(false)
  }, [asPath])

  useEffect(() => {
    if (inView && adItem) {
      mutateAsync({
        input: {
          eventType: AdEventType.Impression,
          adItemId: adItem.id,
          sourceUrl: asPath,
          userIdentifier: getCreateUserUUID(),
        },
      })
    }
  }, [viewed, asPath, adItem?.adType])

  if (isLoading) {
    return null
  }

  if (!adItem) {
    return null
  }

  return (
    <Link
      href={adItem?.targetUrl}
      target="_blank"
      onClick={() =>
        mutateAsync({
          input: {
            eventType: AdEventType.Click,
            adItemId: adItem.id,
            sourceUrl: router.asPath,
            userIdentifier: getCreateUserUUID(),
          },
        })
      }
      w="100%"
      aria-label={adItem.vendor?.name}
    >
      <Flex w="100%" h="100%" mb={props.bottomMargin ? "4" : null} ref={ref} justify="center">
        {/*
        <Image
          src={adItem?.s3Url}
          w="100%"
          h="100%"
          maxWidth={adItem.width}
          maxHeight={adItem.height}
          alt={adItem.vendor?.name}
        />
        */}
        <Image
          src={adItem?.s3Url}
          width="100%"
          height="100%"
          style={{ maxWidth: adItem.width, maxHeight: adItem.height, objectFit: "cover", objectPosition: "center", }}
          wrapperProps={{
            style: {
              display: "contents",
            },
          }}
          loading="lazy"
          alt={adItem.vendor?.name}
        />
      </Flex>
    </Link>
  )
}

VendorAd.defaultProps = {
  targeting: {},
} as AdProps
