import { FC, Fragment, useMemo, useState } from "react"
import { useRouter } from "next/router"
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  TabIndicator,
  Text,
  VStack,
} from "@chakra-ui/react"
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs"
import { FaQuestionCircle } from "react-icons/fa"
import { compareDesc, format, formatDistanceStrict } from "date-fns"
import { SectionHeader } from "~/components/layouts/property/sections"
import { TextVariants, ButtonVariants } from "~/theme/components"
import { formatTaxValue, isUnlisted } from "~/utils/helpers"
import { useAuth, useUser } from "~/components/hooks"
import Blur from "~/components/helpers/Blur"
import { calcTax } from "~/utils"
import { EsProperty } from "~/generated/graphql"
import { Tooltip } from "~/components/modules"

const MAX_LENGTH = 3

interface ISaleTaxHistoryProps {
  property: EsProperty
}
export const SaleTaxHistory: FC<ISaleTaxHistoryProps> = ({ property }) => {
  const { openSignInModal } = useAuth()
  const router = useRouter()
  const { user } = useUser()
  const [showMoreLastSoldPrices, setSowMoreLastSoldPrices] = useState(false)
  const [showMoreTaxes, setShowMoreTaxes] = useState(false)

  const lastSoldPricesData = useMemo(() => {
    return isUnlisted(property)
      ? property?.closes
          .filter(sale => sale?.price)
          .sort((a, b) => compareDesc(new Date(a?.date), new Date(b?.date)))
          .map(item => {
            return {
              ...item,
              dateAgo: formatDistanceStrict(new Date(item?.date), new Date()),
              dateFormatted: format(new Date(item?.date), "MMM do yyyy"),
            }
          })
      : []
  }, [property])

  const lastSoldPrices = useMemo(
    () => (showMoreLastSoldPrices ? lastSoldPricesData : lastSoldPricesData.slice(0, MAX_LENGTH)),
    [lastSoldPricesData, showMoreLastSoldPrices],
  )

  const taxesData = useMemo(() => {
    return isUnlisted(property)
      ? (calcTax(property) || [])
          .filter(tax => !isNaN(Number(tax.value)))
          .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
          .map(item => {
            return {
              ...item,
              valueFormatted: formatTaxValue(item.value),
            }
          })
      : []
  }, [property])

  const taxes = useMemo(() => (showMoreTaxes ? taxesData : taxesData.slice(0, MAX_LENGTH)), [
    taxesData,
    showMoreTaxes,
  ])

  return (
    <div>
      <SectionHeader
        title="Sale and Tax History"
        showUpdateButton
        onClickUpdateButton={() =>
          router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
        }
      />
      <Tabs variant="v2">
        <TabList>
          <Tab>
            Last Sold Prices
            <Tooltip
              label={
                "The transactions listed represent the last time a property sold or changed ownership. In a small percentage (i.e. 5%) of homes, this is not a true home sale. For example, the sold price could represent its price at the time it transfers ownership to a spouse if the homeowners divorced or married"
              }
              placement="right"
              hasArrow
            >
              <Box ml={2} color="gray.500">
                <FaQuestionCircle size={15} />
              </Box>
            </Tooltip>
          </Tab>

          <Tab>Estimated Property Taxes</Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="#282F45" borderRadius="2px" />

        <TabPanels>
          <TabPanel>
            <Blur
              minH="110px"
              blurred={!user}
              overlay={
                <VStack h="100%" w="100%" justifyContent="center">
                  <Button variant={ButtonVariants.Primary} onClick={() => openSignInModal()}>
                    Sign up for free
                  </Button>
                  <Text textAlign="center" my="1em">
                    or
                  </Text>

                  <Text textAlign="center">
                    <Link fontWeight="bold" onClick={() => openSignInModal()}>
                      Sign in
                    </Link>
                    &nbsp;to view transactions
                  </Text>
                </VStack>
              }
            >
              {lastSoldPrices?.map((item, i) => (
                <Fragment key={i}>
                  {i > 0 && <Divider marginY={6} />}
                  <Flex
                    position="relative"
                    paddingY={2}
                    paddingLeft={6}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box bgColor="gold.500" position="absolute" top="0" left="0" h="100%" w={1} />
                    <Text variant={TextVariants.heading4}>
                      ${item.price ? item.price?.toLocaleString() : "Unknown"}
                    </Text>

                    <Box
                      display="flex"
                      flexDirection={["column-reverse", "column-reverse", "column-reverse", "row"]}
                    >
                      <Text as="span" variant={TextVariants.body2} color="hdBlack.faded100" mr={7}>
                        {item.dateAgo} ago
                      </Text>
                      <Text as="span" variant={TextVariants.body2}>
                        {item.dateFormatted}
                      </Text>
                    </Box>
                  </Flex>
                </Fragment>
              ))}
              {lastSoldPricesData?.length > MAX_LENGTH && (
                <Button
                  mt={6}
                  variant={ButtonVariants.Link}
                  onClick={() => setSowMoreLastSoldPrices(!showMoreLastSoldPrices)}
                >
                  {showMoreLastSoldPrices ? "Show Less" : "Show More"}
                </Button>
              )}
              {lastSoldPrices.length === 0 && <Text variant={TextVariants.body2}>No data</Text>}
            </Blur>
          </TabPanel>

          <TabPanel>
            {taxes.map((item, i) => (
              <Fragment key={i}>
                {i > 0 && <Divider marginY={6} />}
                <Flex
                  position="relative"
                  paddingY={2}
                  paddingLeft={6}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box bgColor="gold.500" position="absolute" top="0" left="0" h="100%" w={1} />
                  <Text variant={TextVariants.heading4}>{item.date}</Text>
                  <Text variant={TextVariants.body2} color="hdBlack.faded100">
                    {item.valueFormatted}
                  </Text>
                </Flex>
              </Fragment>
            ))}
            {taxesData?.length > MAX_LENGTH && (
              <Button
                mt={6}
                variant={ButtonVariants.Link}
                onClick={() => setShowMoreTaxes(!showMoreTaxes)}
              >
                {showMoreTaxes ? "Show Less" : "Show More"}
              </Button>
            )}
            {taxes.length === 0 && <Text variant={TextVariants.body2}>No data</Text>}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}
