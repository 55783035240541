import { FC, useMemo } from "react"
import { useRouter } from "next/router"
import { Box, Button, Flex, Spacer, Text, useBreakpointValue } from "@chakra-ui/react"
import { formatISO, subMonths } from "date-fns"
import { FiArrowUpRight } from "react-icons/fi"
import { PropertyCard } from "~/components/helpers/cards"
import { Carousel } from "components/helpers/carousels"
import { SectionHeader } from "~/components/layouts/property/sections"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { createUrl, provinceLongToProvinceAbbr } from "~/utils"
import { getAmenities, getLocation, getPrices } from "~/utils/helpers"
import { usePageModalStore } from "~/store/StoreProvider"
import { useMediaQuerySSR } from "~/components/hooks"
import {
  CreaListing,
  EsProperty,
  PrivateListing,
  useFilterListPropertiesQuery,
  useSimilarCreaListingsQuery,
} from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

interface IMorePropertiesProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty | PrivateListing | CreaListing
}
export const MoreProperties: FC<IMorePropertiesProps> = ({  property }) => {
  const router = useRouter()
  const { isOpen: isOpenInModal } = usePageModalStore()
  const { neighbourhood, city, province, coordinates, type } = useMemo(
    () => getLocation(property),
    [property],
  )
  const { bedrooms, bathrooms } = useMemo(() => getAmenities(property), [property])
  const { listedPrice } = useMemo(() => getPrices(property), [property])
  const provinceAbbr = useMemo(() => provinceLongToProvinceAbbr(province), [province])
  const isMobile = useBreakpointValue([true, true, false])
  const isSmallerThan1140 = useMediaQuerySSR(1140)

  const { data: listedData, isLoading: isListedLoading } = useSimilarCreaListingsQuery(
    {
      size: 6,
      provinceName: province,
      cityName: city,
      price: listedPrice,
      neighbourhoodName: neighbourhood,
      bedrooms,
      bathrooms,
      propertyType: type,
      location: { lat: coordinates.latitude, lon: coordinates.longitude },
      slug: property.slug,
    },
    {
      enabled: Boolean(coordinates.latitude && coordinates.longitude),
    },
  )

  const { data: unlistedData, isLoading: isUnlistedLoading } = useFilterListPropertiesQuery({
    size: 6,
    province,
    city,
    neighbourhoodName: neighbourhood,
    bedroomsTotal: bedrooms,
    bathroomsTotal: bathrooms,
    closeDate: [formatISO(subMonths(Date.now(), 4))],
  })

  if (isListedLoading || isUnlistedLoading) return null

  const unlistedProperties = unlistedData?.filterListProperties?.propertiesList
  const listedProperties = listedData?.similarCreaListings?.creaListings

  if (unlistedProperties?.length === 0 && listedProperties?.length === 0) return null

  return (
    <>
      <SectionHeader title="More Properties" />
      {unlistedProperties?.length > 0 && (
        <>
          <Flex alignItems="baseline">
            <Text mb={10} variant={TextVariants.heading4}>
              Recently Sold Properties
            </Text>
            <Spacer />
            <Button
              variant={ButtonVariants.Primary}
              rightIcon={<FiArrowUpRight size={"1rem"} />}
              onClick={() => {
                router.push(
                  createUrl({
                    variant: "recently-sold",
                    city,
                    provinceAbbr,
                    neighbourhood,
                  }),
                )
              }

              }
            >
              See all
            </Button>
          </Flex>
          <Box
            width={isMobile || isSmallerThan1140 || isOpenInModal ? "78%" : undefined}
            margin={isMobile || isSmallerThan1140 || isOpenInModal ? "auto" : undefined}
          >
            <Carousel columns={[1, 1, 2, 3]} showDots={isMobile}>
              {unlistedProperties?.slice(0, 8).map(unlistedProperty => (
                <Box key={unlistedProperty.id} width="100%">
                  <PropertyCard.RecentlySold
                    property={unlistedProperty}
                    onClick={() => router.push(`/property/${unlistedProperty.slug}`)}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
        </>
      )}

      {listedProperties?.length > 0 && (
        <>
          <Flex mt={14} alignItems="baseline">
            <Text mb={10} variant={TextVariants.heading4}>
              Similar Properties For Sale
            </Text>
            <Spacer />
            <Button
              variant={ButtonVariants.Primary}
              rightIcon={<FiArrowUpRight size={"1rem"} />}
              onClick={() =>
                router.push(
                  createUrl({
                    variant: "real-estate",
                    city,
                    provinceAbbr,
                    neighbourhood,
                  }),
                )
              }
            >
              See all
            </Button>
          </Flex>

          <Carousel columns={[1, 1, 2, 3]} showDots={isMobile}>
            {listedProperties?.slice(0, 8).map(listedProperty => (
              <Box key={listedProperty.slug}>
                <PropertyCard.ForSale
                  publicListing={listedProperty}
                  onClick={() => router.push(`/listing/${listedProperty.slug}`)}
                />
              </Box>
            ))}
          </Carousel>
        </>
      )}
    </>
  )
}
