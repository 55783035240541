import { FC } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react"
import { PermitCard } from "components/helpers/cards"
import { ButtonVariants } from "~/theme/components"
import { Permit } from "~/generated/graphql"

interface IPermitModal {
  permit: Permit
  onClose: () => void
  isOpen: boolean
}

export const PermitModal: FC<IPermitModal> = ({ permit, onClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Permit Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PermitCard permit={permit} flex showOnlyBody />
        </ModalBody>
        <ModalFooter>
          <Button variant={ButtonVariants.Primary} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
