import { FC, ReactNode } from "react"
import router from "next/router"
import { Box, Text, Button, SimpleGrid } from "@chakra-ui/react"
import { FaEdit, FaQuestionCircle } from "react-icons/fa"
import { FiArrowUpRight } from "react-icons/fi"
import { IconHonestdoor } from "~/components/icons"
import { ButtonVariants, TextVariants } from "~/theme/components"
import { Tooltip } from "~/components/modules"

interface SectionHeaderProps {
  /**
   * Title of the section
   */
  title?: string
  showUpdateButton?: boolean
  /**
   * Label of the update button, shown adjacent to the header title. If set overrides the default of "Update"
   */
  updateButtonLabel?: string
  /**
   * If set overrides the default update button behavior of navigating to the edit page
   */
  onClickUpdateButton?: () => void

  /**
   * Label of the primary button, shown at the far right
   */
  primaryButtonLabel?: string
  onClickPrimaryButton?: () => void
  tootTip?: string
  showHonestDoorLogo?: boolean
  children?: ReactNode
}
/**
 * A header to be shown at the top of a section
 */
export const SectionHeader: FC<SectionHeaderProps> = ({
  updateButtonLabel = "Update",
  showUpdateButton,
  onClickUpdateButton,
  tootTip,
  primaryButtonLabel,
  onClickPrimaryButton,
  showHonestDoorLogo,
  title,
  children,
}) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: primaryButtonLabel ? 2 : 1 }}
      gap={3}
      justifyContent="space-between"
      marginBottom={6}
      minHeight="40px"
    >
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {showHonestDoorLogo && (
          <Box height={7} width={7} mr={4}>
            <IconHonestdoor />
          </Box>
        )}
        <Text variant={TextVariants.heading3}>{title || children}</Text>
        {tootTip && (
          <Tooltip label={tootTip} placement={"right"} hasArrow>
            <Box ml={2} color="gray.500">
              <FaQuestionCircle />
            </Box>
          </Tooltip>
        )}
        {showUpdateButton && (
          <Button
            ml={6}
            variant={ButtonVariants.Link}
            leftIcon={<FaEdit />}
            onClick={
              onClickUpdateButton
                ? onClickUpdateButton
                : () => {
                    router.push(router.asPath + "/edit")
                  }
            }
          >
            {updateButtonLabel}
          </Button>
        )}
      </Box>

      {onClickPrimaryButton && (
        <Box justifySelf={{ base: "start", md:  "end" }}>
          <Button
            rightIcon={<FiArrowUpRight size={"1rem"} />}
            variant={ButtonVariants.Primary}
            onClick={onClickPrimaryButton}
          >
            {primaryButtonLabel}
          </Button>
        </Box>
      )}
    </SimpleGrid>
  )
}
