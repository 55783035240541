import React, { FC, useMemo } from "react"
import { Box, Button, Divider, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react"
import capitalize from "lodash/capitalize"
import { FaQuestionCircle } from "react-icons/fa"
import { getAmenities, getLocation, getPrices } from "~/utils/helpers"
import { TextVariants } from "~/theme/components"
import { SummaryAmenities } from "./summary-amenities"
import {
  AskAboutPropertyButton,
  Tooltip,
  TrackNeighbourhoodButton,
  WatchPriceButton,
} from "~/components/modules"
import { InternalLink } from "~/components/elements"
import { CreaListing, EsProperty } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"

export interface ISummaryPublic {
  publicListing: CreaListing
  property?: EsProperty
}

export const SummaryPublic: FC<ISummaryPublic> = ({ publicListing, property }) => {
  const isMobile = useBreakpointValue([true, true, false])
  const condenseSaleLabel = useBreakpointValue([false, false, true, false])

  const {
    addressFormattedMain,
    priceFormatted,
    propertyListedPriceFormatted,
    isForSale,
    bedrooms,
    bathrooms,
    floorSpace,
  } = useMemo(() => {
    const { addressFormattedFull, addressFormattedMain } = getLocation(publicListing)
    const { bedrooms, bathrooms, floorSpace } = getAmenities(publicListing)
    const { estimatedPrice, estimatedPriceDate, listedPrice, isForSale } = getPrices(publicListing)
    const price = isForSale ? listedPrice : estimatedPrice

    let propertyValues = {
      propertyListedPriceFormatted: null,
    }
    if (property) {
      const { listedPrice: propertyListedPrice } = getPrices(property)
      propertyValues = {
        propertyListedPriceFormatted: propertyListedPrice
          ? propertyListedPrice.toLocaleString(undefined, { maximumFractionDigits: 0 })
          : null,
      }
    }

    return {
      addressFormattedFull,
      addressFormattedMain,
      bedrooms,
      bathrooms,
      floorSpace,
      estimatedPrice,
      price,
      priceFormatted: price ? price.toLocaleString(undefined, { maximumFractionDigits: 0 }) : null,
      isForSale,
      month: estimatedPriceDate
        ? new Date(estimatedPriceDate).toLocaleString("default", { month: "long" })
        : undefined,
      ...propertyValues,
    }
  }, [publicListing, property])

  return (
    <Box
      bgColor="white"
      p={6}
      borderRadius="lg"
      borderColor="#C9CDD9"
      boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      position="relative"
      overflow="hidden"
    >
      <Flex justifyContent={["center", "center", "space-between"]}>
        <Flex
          alignItems="center"
          justifyContent={["center", "center", "start"]}
          marginTop={["8px", "8px", 0]}
        >
          <Box
            width={2}
            height={2}
            borderRadius="full"
            bgColor={isForSale ? "green.500" : "red.500"}
            marginRight={2}
            flexShrink={0}
          />
          <Text noOfLines={1} variant={TextVariants.heading5}>
            {capitalize(
              (condenseSaleLabel ? "" : "this property is ") +
                (isForSale ? "" : "not ") +
                "for sale",
            )}
          </Text>
        </Flex>

        {property && (
          <Flex justifyContent="end" display={["none", "none", "flex"]} w="442px">
            <WatchPriceButton
              variant="listing"
              property={property}
              unwatchedText="Track Home Value"
              watchedText="Home Value is Tracked"
              buttonProps={{
                width: "100%",
              }}
            />

            <InternalLink href={`/property/${property.slug}`} linkProps={{ ml: 2, w: "100%" }}>
              <Button variant="secondary" w="100%">
                View HonestDoor Data
              </Button>
            </InternalLink>
          </Flex>
        )}
      </Flex>

      <Flex
        justifyContent={["center", "center", "start", "space-between"]}
        direction={["column", "row", "row"]}
      >
        <Flex
          direction={["column", "column", "row", "row"]}
          alignItems={["center", "center", "start", "center"]}
          marginTop={"16px"}
        >
          <Text as="div" variant={TextVariants.heading1} lineHeight="38px">
            {priceFormatted ? `$${priceFormatted}` : "N/A"}
          </Text>

          {propertyListedPriceFormatted && (
            <Flex
              flexDirection={["column", "column", "row"]}
              alignItems={["start", "start", "center"]}
              marginTop={["16px", "16px", 0, 0]}
            >
              <Flex flexDirection="row" alignItems="center" marginLeft={[0, 0, "21px"]}>
                <Text>HonestDoor Price</Text>
                <Tooltip
                  label={
                    "The HonestDoor Price is our estimate determined by our proprietary advanced statistical & machine learning methods. It is a starting point in determining a home's value and is not an official appraisal. Comparing your home to recently sold properties can also help you understand what your home is worth."
                  }
                  placement="right"
                  hasArrow
                >
                  <Box ml={2} color="gray.500">
                    <FaQuestionCircle size={15} />
                  </Box>
                </Tooltip>
              </Flex>

              <Box marginLeft={[0, 0, "21px"]}>
                <Text as="div" variant={TextVariants.heading1} lineHeight="38px" color="gold.500">
                  ${propertyListedPriceFormatted}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
        <Flex
          justifyContent="end"
          display={["none", "none", "flex"]}
          direction="row"
          alignItems="center"
        >
          <AskAboutPropertyButton
            input={{
              source: PrivateLeadSource.BuyListPage,
              type: PrivateLeadType.Buy,
              propertyId: publicListing.listingId,
              slug: publicListing.slug,
              streetAddress: publicListing.address.streetAddress,
              cityId: publicListing.address.cityId,
              cityName: publicListing.address.city,
              neighbourhoodId: publicListing.address.neighbourhoodId,
              neighbourhoodName: publicListing.address.neighbourhood,
              province: publicListing.address.province,
              listPrice: publicListing.price,
            }}
            buttonProps={{
              mt: [2, 0],
              mr: [0, 2],
              minW: ["100%", 0],
            }}
          />

          <TrackNeighbourhoodButton
            board={PROPERTY_BOARDS.CREA}
            neighbourhoodId={property?.neighbourhood}
            neighbourhoodName={property?.neighbourhoodName}
          />
        </Flex>
      </Flex>

      <Divider marginY={6} />

      <Flex justifyContent="space-between" direction={["column", "column", "column", "row"]}>
        <Box>
          <Flex
            alignItems={["center", "center", "center", "start"]}
            direction={["column", "column", "row", "row"]}
            marginTop={"6px"}
            textAlign="center"
          >
            <Heading as="h1" fontSize="17px" fontWeight="normal">
              {addressFormattedMain}
            </Heading>
          </Flex>
        </Box>

        <Divider marginY={6} display={["block", "block", "block", "none"]} />

        <Flex
          justifyContent={["center", "center", "start", "end"]}
          ml={[0, 0, 0, 6]}
          mt={["8px", "8px", "8px", 0]}
        >
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        </Flex>

        {property && <Divider marginY={6} display={["block", "block", "none"]} />}

        {isMobile && (
          <>
            {property && (
              <Flex
                direction={["column", "row"]}
                justifyContent="center"
                alignItems="center"
                minW={["249px", 0]}
              >
                <WatchPriceButton
                  variant="listing"
                  property={property}
                  unwatchedText="Track Home Value"
                  watchedText="Home Value is Tracked"
                  buttonProps={{
                    minW: "249px",
                  }}
                />

                <InternalLink
                  href={`/property/${property.slug}`}
                  linkProps={{
                    ml: [0, 2],
                    mt: [2, 0],
                    minW: "249px",
                  }}
                >
                  <Button variant="secondary" w={"100%"}>
                    View HonestDoor Data
                  </Button>
                </InternalLink>
              </Flex>
            )}

            <Flex direction={["column", "row"]} alignItems="center" mt={2}>
              <AskAboutPropertyButton
                input={{
                  source: PrivateLeadSource.BuyListPage,
                  type: PrivateLeadType.Buy,
                  propertyId: publicListing.listingId,
                  slug: publicListing.slug,
                  streetAddress: publicListing.address.streetAddress,
                  cityId: publicListing.address.cityId,
                  cityName: publicListing.address.city,
                  neighbourhoodId: publicListing.address.neighbourhoodId,
                  neighbourhoodName: publicListing.address.neighbourhood,
                  province: publicListing.address.province,
                  listPrice: publicListing.price,
                }}
                buttonProps={{
                  mb: [2, 0],
                  mr: [0, 2],
                  minW: ["249px", 0],
                }}
              />

              <TrackNeighbourhoodButton
                board={PROPERTY_BOARDS.CREA}
                neighbourhoodId={property?.neighbourhood}
                neighbourhoodName={property?.neighbourhoodName}
              />
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  )
}
