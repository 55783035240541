import { FC, useEffect, useRef } from "react"
import { Box, SimpleGrid, Text, useTheme } from "@chakra-ui/react"
import { FaBath, FaBed, FaRuler } from "react-icons/fa"
import ReactEChartsCore from "echarts-for-react/lib/core"
import { BarChart } from "echarts/charts"
import * as echarts from "echarts/core"
import type { EChartsOption } from "echarts"
import { SVGRenderer } from "echarts/renderers"
import { BsArrowDownCircle, BsArrowUpCircle, BsDashCircle } from "react-icons/bs"
import { MdHouse } from "react-icons/md"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components"
import { TextVariants } from "~/theme/components/Text"
import { ValueHighlight } from "./value-highlight"
import { SectionHeader } from "~/components/layouts/property/sections"
import {
  EsProperty,
  useGetCityAttributesQuery,
  useGetNeighbourhoodAttributesQuery,
} from "~/generated/graphql"

echarts.use([
  TitleComponent,
  TooltipComponent,
  BarChart,
  GridComponent,
  LegendComponent,
  SVGRenderer,
])

type ChartData = { city: number; neighbourhood: number; property: number }

const defaultIcons = {
  bedrooms: <FaBed />,
  bathrooms: <FaBath />,
  floorSpace: <FaRuler />,
  lotSize: <MdHouse size={23} />,
}

type AnalysisData = {
  icon: JSX.Element
  title: string
  data: ChartData
  qualityCompared: string
}

interface IPropertyAnalysisProps {
  property: EsProperty
}
export const PropertyAnalysis: FC<IPropertyAnalysisProps> = ({ property }) => {
  const { city, neighbourhood, assessmentClass } = property
  const {
    data: { getCityAttributes: [cityAttributes] = [] } = {},
    isLoading: cityAttributesLoading,
  } = useGetCityAttributesQuery(
    {
      filter: {
        cityId: city,
        assessmentClass: assessmentClass,
      },
    },
    { enabled: !!city },
  )

  const {
    data: { getNeighbourhoodAttributes: [neighbourhoodAttributes] = [] } = {},
    isLoading: neighbourhoodLoading,
  } = useGetNeighbourhoodAttributesQuery(
    {
      filter: {
        neighbourhoodId: neighbourhood,
        assessmentClass: assessmentClass,
      },
      take: 1,
    },
    { enabled: !!neighbourhood },
  )

  if (cityAttributesLoading || neighbourhoodLoading) return null

  const address = property?.fullAddress || property?.unparsedAddress

  const bedrooms = {
    property: property?.bedroomsTotal,
    city: cityAttributes?.bedsAvg,
    neighbourhood: neighbourhoodAttributes?.bedsAvg,
  }

  const bathrooms = {
    property: property?.bathroomsTotal,
    city: cityAttributes?.bathsAvg,
    neighbourhood: neighbourhoodAttributes?.bathsAvg,
  }

  const floorSpace = {
    property: property?.livingArea || property?.livingAreaEst,
    neighbourhood: neighbourhoodAttributes?.livingAreaAvg,
    city: cityAttributes?.livingAreaAvg,
  }

  const lotSize = {
    property: property?.lotSizeArea || property?.lotSizeAreaEst,
    neighbourhood: neighbourhoodAttributes?.lotSizeAvg,
    city: cityAttributes?.lotSizeAvg,
  }

  let data: AnalysisData[] = [
    {
      icon: defaultIcons.bedrooms,
      title: "Bedroom Analysis",
      data: bedrooms,
      qualityCompared: "bedrooms",
    },
    {
      icon: defaultIcons.bathrooms,
      title: "Bathroom Analysis",
      data: bathrooms,
      qualityCompared: "bathrooms",
    },
    {
      icon: defaultIcons.floorSpace,
      title: "Floor Space Analysis",
      data: floorSpace,
      qualityCompared: "floor space",
    },
    {
      icon: defaultIcons.lotSize,
      title: "Lot Size Analysis",
      data: lotSize,
      qualityCompared: "lot size",
    },
  ]
  data = data.filter(item => Boolean(item.data.property))

  if (data.length === 0) return null

  return (
    <>
      <SectionHeader>Property Analysis {address && `for ${address}`}</SectionHeader>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={[6, 6, 6, 12]}>
        {data.map((item, index) => (
          <AnalysisBarGraph
            key={index}
            neighbourhoodName={property.neighbourhoodName}
            cityName={property.cityName}
            headerIcon={item.icon}
            headerText={item.title}
            qualityCompared={item.qualityCompared}
            chartData={item.data}
          />
        ))}
      </SimpleGrid>
    </>
  )
}

type AnalysisBarGraphProps = {
  headerIcon?: JSX.Element
  headerText?: string
  qualityCompared?: string
  chartData: ChartData
  neighbourhoodName?: string
  cityName?: string
}
const AnalysisBarGraph = (props: AnalysisBarGraphProps): JSX.Element => {
  const {
    headerIcon,
    headerText,
    qualityCompared,
    chartData,
    neighbourhoodName: neighbourhood,
    cityName: city,
  } = props

  const ref = useRef<any>()
  const resizeCharts = () => {
    if (ref.current) {
      const echartInstance = ref.current.getEchartsInstance()
      echartInstance?.resize()
    }
  }

  useEffect(() => {
    window.addEventListener("resize", resizeCharts)
    return () => {
      window.removeEventListener("resize", resizeCharts)
    }
  }, [ref])

  const theme = useTheme()

  const colorPalette = [
    theme.colors.hdGold.full,
    neighbourhood ? theme.colors.hdSky.full : null,
    city ? theme.colors.hdMoney.full : null,
  ]

  const chartOptions: EChartsOption = {
    grid: {
      left: 0,
      right: 0,
      bottom: 10,
      top: 10,
      containLabel: true,
    },

    xAxis: {
      type: "category",
      data: ["This Property", `${neighbourhood || ""}`, `${city || ""}`],
      axisTick: { show: false },
      axisLabel: {
        color: "#282F45",
        fontWeight: "bold",
        margin: 8,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      boundaryGap: [0, 0.01],
      axisLabel: {
        margin: 0,
        align: "left",
        inside: true,
        padding: [0, 8, 0, 0],
        backgroundColor: "#F7FAFC",
        formatter: function (value) {
          return value >= 1000 ? value / 1000 + "K" : value.toString()
        },
      },
    },

    barWidth: 30,
    colorBy: "data",
    series: [
      {
        type: "bar",
        color: colorPalette,
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
        },
        data: [
          {
            name: "This Property",
            value: chartData.property,
          },
          !neighbourhood
            ? undefined
            : {
                name: neighbourhood,
                value: chartData.neighbourhood,
              },
          !city
            ? undefined
            : {
                name: city,
                value: chartData.city,
              },
        ],
      },
    ],
  }

  const neighbourhoodHighlight =
    chartData.property > chartData.neighbourhood
      ? "positive"
      : chartData.property < chartData.neighbourhood
      ? "negative"
      : "neutral"
  const cityHighlight =
    chartData.property > chartData.city
      ? "positive"
      : chartData.property < chartData.city
      ? "negative"
      : "neutral"

  return (
    <Box width="100%" minWidth={0}>
      <Box display="flex" alignItems="center" mb={6}>
        {!headerIcon ? null : <Box mr={3}>{headerIcon}</Box>}
        <Text variant={TextVariants.heading4}>{headerText}</Text>
      </Box>
      <Box width={"100%"}>
        <ReactEChartsCore
          echarts={echarts}
          option={chartOptions}
          ref={e => {
            ref.current = e
          }}
        />
        {chartData.neighbourhood && (
          <AnalysisDescription
            highlight={neighbourhoodHighlight}
            location={props.neighbourhoodName}
            qualityCompared={qualityCompared}
          />
        )}
        {chartData.city && (
          <AnalysisDescription
            highlight={cityHighlight}
            location={props.cityName}
            qualityCompared={qualityCompared}
          />
        )}
      </Box>
    </Box>
  )
}

type AnalysisDescriptionProps = {
  highlight: "positive" | "negative" | "neutral"
  location: string
  qualityCompared: string
  fewer?: boolean
}
const AnalysisDescription = (props: AnalysisDescriptionProps): JSX.Element => {
  const relativeQuantifier =
    props.highlight === "positive"
      ? "more"
      : props.highlight === "negative"
      ? props.fewer
        ? "fewer"
        : "less"
      : "equal"

  const icon =
    props.highlight === "positive" ? (
      <BsArrowUpCircle size={28} />
    ) : props.highlight === "negative" ? (
      <BsArrowDownCircle size={28} />
    ) : (
      <BsDashCircle size={28} />
    )

  const color = (highlight: any) => {
    switch (highlight) {
      case "negative":
        return "red.500"
      case "positive":
        return "green.500"
      default:
        return "gray.500"
    }
  }

  return (
    <Box display="flex" alignItems="center" mb={4}>
      <Box mr={2} color={color(props.highlight)}>
        {icon}
      </Box>
      <Text>
        This property has{" "}
        <ValueHighlight highlight={props.highlight}>{relativeQuantifier}</ValueHighlight>
        {` ${props.qualityCompared} compared to the average property in ${props.location}`}
      </Text>
    </Box>
  )
}
