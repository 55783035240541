import { Box, Flex } from "@chakra-ui/layout"
import { chakra } from "@chakra-ui/system"
import theme from "~/theme"

type Rank = {
  rank: Record<string, number>
}

type Bonus = {
  bonus: Record<string, JSX.Element>
}

type FormattedStatArray = [number, Rank, Bonus]

export const growthRateWithYearFormatter = (datum: Record<string, any>): FormattedStatArray => [
  datum.year,
  { rank: { [datum.assessmentClass]: datum.growthRateRank } },
  {
    bonus: {
      [datum.assessmentClass]: (
        <Flex w={"100%"} align={"center"} justify={"center"} flexDir={"column"}>
          <chakra.span fontSize={"20px"} textAlign={"center"}>
            <Box
              as={"span"}
              color={
                datum.growthRate > 0 ? theme.colors.hdMoney["full"] : theme.colors.hdFire["full"]
              }
              fontWeight={"bold"}
            >{`${Number(datum.growthRate * 100).toFixed(2)}%`}</Box>
            {" increase year over year"}
          </chakra.span>
        </Flex>
      ),
    },
  },
]

export const avgAssessedWithYearFormatter = (datum: Record<string, any>): FormattedStatArray => [
  datum.year,
  { rank: { [datum.assessmentClass]: datum.avgAssessedRank } },
  {
    bonus: {
      [datum.assessmentClass]: (
        <Flex w={"100%"} align={"center"} justify={"center"} flexDir={"column"}>
          <chakra.span fontSize={"20px"} w={"100%"} textAlign={"center"}>
            {`Average ${
              datum.assessmentClass == "Both"
                ? "property"
                : datum.assessmentClass == "Condo"
                ? "Condo"
                : "House"
            } price`}
            <Box as={"span"} color={"hdGold.full"} fontWeight={"bold"}>
              {` $${datum.avgAssessedValue?.toLocaleString()}`}
            </Box>
          </chakra.span>
        </Flex>
      ),
    },
  },
]

export const transactionWithYearFormatter = (datum: Record<string, any>): FormattedStatArray => [
  datum.year,
  { rank: { [datum.assessmentClass]: datum.transactionCountRank } },
  {
    bonus: {
      [datum.assessmentClass]: (
        <Flex w={"100%"} align={"center"} justify={"center"} flexDir={"column"}>
          <chakra.span fontSize={"20px"} w={"100%"} textAlign={"center"}>
            <Box
              as={"span"}
              color={"hdGold.full"}
              fontWeight={"bold"}
            >{`${datum?.totalTransactionCount} `}</Box>
            {`transactions in ${datum?.year}`}
          </chakra.span>
        </Flex>
      ),
    },
  },
]

export const getFormattedStatData = (dataWithYear: FormattedStatArray[]): Record<string, any> => {
  const formatted = {}
  dataWithYear.forEach(entry => {
    formatted[entry[0]] = {
      rank: { ...entry[1]?.rank, ...formatted?.[entry[0]]?.rank },
      bonus: { ...entry[2]?.bonus, ...formatted?.[entry[0]]?.bonus },
    }
  })

  const keySet = new Set()
  Object.keys(formatted).forEach(year => {
    Object.keys(formatted[year].rank).forEach(key => keySet.add(key))
  })

  formatted["availablePropertyTypes"] = Array.from(keySet)

  if (formatted["availablePropertyTypes"].length == 0) {
    return null
  }

  return formatted
}
