import Head from "next/head"
// import theme from "~/theme"

export interface MetaProps {
  canonicalUrl?: string
  description?: string
  imageUrl?: string
  title?: string
  twitterUsername?: string
}

Meta.defaultProps = {
  description:
    "Free real estate data on residential and commercial properties - sold prices, estimated value of homes, bed and bath. Exclusive houses for sale",
  imageUrl: "https://d39r1nkmfgfvl8.cloudfront.net/hdOnLaptop.jpg",
  originUrl: "https://www.honestdoor.com",
  title: "Your Real Estate Starting Point",
  twitterUsername: "@honest_door",
}

export function Meta(props: MetaProps): JSX.Element {
  const { canonicalUrl, description, imageUrl, title, twitterUsername } = props

  return (
    <Head>
      <title>{title} • HonestDoor</title>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <meta key="charSet" charSet="utf-8" />
      <meta name="description" content={description} />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:site" name="twitter:site" content={twitterUsername} />
      <meta
        key="twitter:image:alt"
        name="twitter:image:alt"
        content="User visiting HonestDoor on a laptop"
      />
      <meta key="twitter:site" name="twitter:site" content={twitterUsername} />
      <meta key="twitter:image" property="twitter:image" content={imageUrl} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:image" property="og:image" content={imageUrl} />
      <meta key="og:site_name" property="og:site_name" content="HonestDoor" />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:url" property="og:url" content={canonicalUrl} />

      {canonicalUrl ? <link key="canonical-link" rel="canonical" href={canonicalUrl} /> : null}
    </Head>
  )
}
