import { useState, Fragment, FC, useMemo } from "react"
import { Button, Divider, Flex, Spacer, Text } from "@chakra-ui/react"
import { SectionHeader } from "~/components/layouts/property/sections"
import { ButtonVariants } from "~/theme/components"
import { CreaListing } from "~/generated/graphql"

// @TODO: move to some config file
const MAX_LENGTH = 3

interface IRoomsProps {
  property: CreaListing
}
export const Rooms: FC<IRoomsProps> = ({ property }) => {
  const [showMore, setShowMore] = useState(false)
  const data = useMemo(() => property?.building?.rooms?.room || [], [property])
  const items = useMemo(() => (showMore ? data : data.slice(0, MAX_LENGTH)), [data, showMore])

  if (data?.length === 0) return null

  return (
    <div>
      <SectionHeader title="Rooms" />
      {items?.map((room, i) => (
        <Fragment key={i}>
          {i > 0 && <Divider marginY={3} />}
          <Flex flexDirection="column">
            <Flex>
              <Text>{room?.type}</Text>
              <Spacer />
              <Text>{room?.dimension}</Text>
            </Flex>
          </Flex>
        </Fragment>
      ))}

      {data?.length > MAX_LENGTH && (
        <Button mt={6} variant={ButtonVariants.Link} onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show Less" : "Show More"}
        </Button>
      )}
    </div>
  )
}
