import React, { FC } from "react"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Img,
  Text,
} from "@chakra-ui/react"
import getConfig from "next/config"
import { Widget } from "@honestdoor/lead-gen-widget"
import "@honestdoor/lead-gen-widget/dist/widget.es.css"
import { AskAboutPropertyButton } from "~/components/modules"
import { PrivateLeadSource, PrivateLeadType } from "~/utils/constants/private-lead"

const { HD_WIDGET_API_KEY } = getConfig().publicRuntimeConfig

const styles = {
  heading: {
    fontWeight: "600",
    fontSize: "34px",
    lineHeight: "42px",
    color: "#282F45",
    // maxW: "472px",
  },
  button: {
    w: "fit-content",
    padding: "8px 14px",
    color: "#fff",
    backgroundColor: "#C4985F",
    borderRadius: "8px",
    _hover: {},
  },
  textBox: {
    // maxW: "600px",
    mt: "16px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#282F45",
  },
}

const qa = [
  {
    question: "How does it work?",
    answer: (
      <>
        <Text>
          HonestDoor will list your property on the MLS® System, on REALTOR.ca (and many other
          websites). We list it as a flat-fee &quot;Mere Posting&quot;.
        </Text>
        <Text mt={3}>
          With a Mere Posting potential buyers and buyers agents will contact you directly for
          showings and will present offers directly to you.
        </Text>
      </>
    ),
  },
  {
    question: "How much does this cost?",
    answer: (
      <>
        <Text>
          It’s a flat fee of $2K per year. You can pay monthly, quarterly, or annually. No hidden costs or paying per lead.
        </Text>
        <Text mt={3}>
          We love promos, so we often have them running. Ask us if you’re interested!
        </Text>
      </>
    ),
  },
  {
    question: "Do I have to pay per lead?",
    answer: (
      <>
        <Text>
          No. It’s a flat fee. You can pay monthly, quarterly, or annually.
        </Text>
      </>
    ),
  },
  {
    question: "Does it integrate with my CRM?",
    answer: (
      <>
        <Text>
          Maybe! Tell us what CRM you’re using. When you get the tool, you can use our CRM that we built. You
          can log into HonestDoor.com and see a list of all the leads that have inquired about their home values
          using the tool.
        </Text>
        <Text mt={3}>
          Some of our realtors have integrated it with their existing CRMs.
        </Text>
      </>
    ),
  },
  {
    question: "I run a team / brokerage. Can all my agents use it?",
    answer: (
      <>
        <Text>
          Of course. Each tool is customized to one agent or one team/brokerage. So, if you want to add one tool
          multiple websites, then each website inquiry will have the same customization. This is ideal if you want
          to display a team or brokerage.
        </Text>
        <Text mt={3}>
          However, if you want to have each agent have their own contact information for their individual
          websites, each will need to have their own tool. We can provide volume discounts. Just ask us!
        </Text>
      </>
    ),
  },
  {
    question: "Are the monthly/quarterly emails to leads included in the price?",
    answer: (
      <>
        <Text>
          Yes! Every month or quarter (you choose), your leads will receive an automatic home estimate update
          that is customized with your information. These high value touchpoints for leads are relevant to them,
          as homeowners, and, thus, make you relevant to them, too. You don’t have to do anything for them to
          receive this. That way, your contact information will stay top of mind.
        </Text>
      </>
    ),
  },
  {
    question: "How can I generate a QR code?",
    answer: (
      <>
        <Text>
          There are tons of free QR code generators online. We like using this company. Please be aware that free
          QR codes do have some limitations. Read up on what these are before you commit to printing a QR code
          out!
        </Text>
      </>
    ),
  },
  {
    question: "How do I get it on my website?",
    answer: (
      <>
        <Text>
          We’ve helped clients add it to many different websites. We’ll help you along the way!
        </Text>
      </>
    ),
  },
  {
    question: "I don’t have a website. Can I still use a tool like this?",
    answer: (
      <>
        <Text>
          Yes! If you don’t have a website, no problem. We can create a simple landing page for you that will
          contain your information and your tool. You can still generate a QR code that directs leads to this page
          and you can distribute the URL online. We don’t charge extra for this!
        </Text>
      </>
    ),
  },
  {
    question: "Who owns the leads?",
    answer: (
      <>
        <Text>
          You do! Any seller lead that enters their information into your tool on your website, is yours. If you want
          to remove your tool from your site, you can always login to HonestDoor.com and download a csv of your
          leads.
        </Text>
      </>
    ),
  },
]

export const HomeValuationToolLayout: FC = () => {
  return (
    <>
      <Container maxW="1152px" my="16px" px="16px">
        <Flex
          gap="30px"
          mt="33px"
          justifyContent="space-between"
          alignItems="start"
          direction={["column", "column", "row"]}
        >
          <Box>
            <Heading
              as="h2"
              {...styles.heading}
              fontSize={["34px", "34px", "56px"]}
              lineHeight={["42px", "42px", "58px"]}
            >
              Home Valuation Tool
            </Heading>
            <Box {...styles.textBox} fontSize="22px" my={[4, 4, 8]}>
              An easy{" "}
              <Text as="span" fontWeight="bold">
                seller lead generation tool
              </Text>{" "}
              for your website.
            </Box>
            <Box {...styles.textBox} fontSize="22px" mb={[4, 4, 8]} pl={4}>
              <ul>
                <li>Attract sellers with an instant gratification</li>
                <li>Follow up with leads quickly</li>
                <li>Nurture until they’re ready to sell</li>
              </ul>
            </Box>

            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Sign Up"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
              <AskAboutPropertyButton
                text="Book a Demo"
                input={{ source: PrivateLeadSource.WidgetDemo, type: PrivateLeadType.Business, message: ""}}
                buttonProps={styles.button}
              />
            </Box>
          </Box>

          <Box mt={["12px", "12px", 0]}>
            <Heading fontSize={"large"} color={"hdGold.full"} mb="1em">
              Try it here:
            </Heading>
            <Widget apiKey={HD_WIDGET_API_KEY || "amwGJJcFia2w6EmcN56kb7BBqXriq1yR9KS1MTJM"} />
          </Box>
        </Flex>

        <Flex
          mt="33px"
          alignItems="center"
          direction={["column", "column", "row"]}
          gap="30px"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h3" {...styles.heading}>
              Add it to your website
            </Heading>
            <Box {...styles.textBox}>
              Capture seller leads on your site by giving homeowners an instant estimate delivered
              straight to their inbox.
            </Box>

            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Contact Us"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
            </Box>
          </Box>
          <Box mt={["12px", "12px", 0]}>
            <Img src="/images/home-estimage-widget/website-widget.jpg" w="100%" />
          </Box>
        </Flex>

        <Flex
          mt="33px"
          alignItems="center"
          direction={["column", "column", "row"]}
          gap="30px"
          justifyContent="space-between"
        >
          <Box mt={["12px", "12px", 0]} order={[1, 1, 0]}>
            <Img src="/images/home-estimage-widget/customize-emails.png" w="800px" />
          </Box>
          <Box order={[0, 0, 1]}>
            <Heading as="h3" {...styles.heading}>
              Customize emails with your contact information
            </Heading>
            <Box {...styles.textBox}>
              Seller leads know exactly who to respond to if they have questions, want to sell, or
              need more information.
            </Box>
            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Contact Us"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
            </Box>
          </Box>
        </Flex>

        <Flex
          mt="33px"
          alignItems="center"
          direction={["column", "column", "row"]}
          gap="30px"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h3" {...styles.heading}>
              Generate a QR code for print
            </Heading>
            <Box {...styles.textBox}>
              Seller leads know exactly who to respond to if they have questions, want to sell, or
              need more information.
            </Box>
            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Contact Us"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
            </Box>
          </Box>
          <Box w={["100%", "100%", "50%"]} mt={["12px", "12px", 0]}>
            <Img
              src="/images/home-estimage-widget/40d0d837-1a79-4c01-acfc-4bc231bf115c.jpeg"
              w="100%"
            />
          </Box>
        </Flex>

        <Flex
          mt="33px"
          alignItems="center"
          direction={["column", "column", "row"]}
          gap="30px"
          justifyContent="space-between"
        >
          <Box w={["100%", "100%", "100%"]} mt={["12px", "12px", 0]} order={[1, 1, 0]}>
            <Img src="/images/home-estimage-widget/phone-calendar.jpg" w="100%" />
          </Box>
          <Box order={[0, 0, 1]}>
            <Heading as="h3" {...styles.heading}>
              Turn contacts into clients with personalized monthly emails
            </Heading>
            <Box {...styles.textBox}>
              Each seller lead that requests a home estimate from your widget can automatically
              receive an updated property value each month or quarter customized with your contact
              information.
            </Box>
            <Box {...styles.textBox}>
              Have existing seller leads in an Excel doc or CRM? Nurture those leads with updated
              home values.
            </Box>

            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Contact Us"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
            </Box>
          </Box>
        </Flex>

        <Flex
          mt="33px"
          alignItems="center"
          direction={["column", "column", "row"]}
          gap="30px"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h3" {...styles.heading}>
              Don’t have a site? We can create a landing page for you.
            </Heading>
            <Box {...styles.textBox}>
              Websites are hard to maintain. We can create a landing page for you so you don’t miss
              out on the opportunity.
            </Box>

            <Box {...styles.textBox}>
              <AskAboutPropertyButton
                text="Contact Us"
                input={{ source: PrivateLeadSource.WidgetSignUp, type: PrivateLeadType.Business, message: "" }}
                buttonProps={{ ...styles.button, mr: 4 }}
              />
            </Box>
          </Box>

          <Box w={["100%", "100%", "30%"]} mt={["12px", "12px", 0]}>
            <Img src="/images/home-estimage-widget/dont-have-a-site.png" w="100%" />
          </Box>
        </Flex>

        {/*
        <Box mt="24px">
          <TestimonialsSlider />
        </Box>
        */}

        <Heading as="h3" size="lg" mt={["24px", "24px", 20]} mb={3}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowMultiple mb={8}>
          {qa.map(({ question, answer }, i) => (
            <AccordionItem key={i} py="10px">
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={["1.2em", "1.2em", "1.5em"]}>
                  {question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel fontSize={["1em", "1em", "1.2em"]} px={["1em", "1em", "2em", "3em"]}>
                {answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </>
  )
}
