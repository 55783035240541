import * as React from "react"
import { Box, BoxProps, Divider, useDimensions } from "@chakra-ui/react"
import { usePageModalStore } from "~/store/StoreProvider"

type SectionContainerProps = BoxProps & {
  /**
   * If true a divider will be shown above the header
   */
  showDivider?: boolean
  /**
   * A component that floats to the left of the container, outside of typical alignment
   */
  floatLeft?: JSX.Element
  /**
   * A component that floats to the right of the container, outside of typical alignment
   */
  floatRight?: JSX.Element
}
/**
 * A generic content container that wraps content to fit comfortably on the page
 * with options for floating components outside of the usual alignment (such as
 * scroll buttons)
 */
const SectionContainer = ({
  showDivider = true,
  floatLeft,
  floatRight,
  ...props
}: SectionContainerProps): JSX.Element => {
  const { isOpen } = usePageModalStore()
  const leftRef = React.useRef<HTMLDivElement>()
  const rightRef = React.useRef<HTMLDivElement>()
  const leftWidth = useDimensions(leftRef, true)?.borderBox?.width
  const rightWidth = useDimensions(rightRef, true)?.borderBox?.width
  // const container = React.useRef<HTMLDivElement>()
  // const containerWidth = useDimensions(container, true)?.borderBox?.width

  return (
    // <Box ref={container}>
    <Box>
      <Box
        maxWidth="1600px"
        // marginX={[6, 6, 20, 20, 40]}
        // marginX={[5]}
        marginX={isOpen ? [5] : [6, 6, 20, 20, 40]}
        // TODO: instead of media query we do a query of the parent size
        // This would be preferably, but causes strange shifting behavior
        // marginX={containerWidth > 1536 ? 40 : containerWidth > 992 ? 20 : 6}
        marginBottom={10}
        {...props}
        position="relative"
      >
        <Box
          position="absolute"
          height="100%"
          ref={leftRef}
          left={`-${leftWidth}px`}
          display="flex"
          alignItems="center"
        >
          {floatLeft}
        </Box>

        <Box
          position="absolute"
          height="100%"
          ref={rightRef}
          right={`-${rightWidth}px`}
          display="flex"
          alignItems="center"
        >
          {floatRight}
        </Box>

        {showDivider && <Divider marginBottom={10} />}
        {props.children}
      </Box>
    </Box>
  )
}

export default SectionContainer
