import { FC, useMemo } from "react"
import { Text } from "@chakra-ui/react"
import { format } from "date-fns"
import { TextVariants } from "~/theme/components"
import { formatStreet } from "~/utils/lib/string"
import { formatBaths, massageValuationValueText } from "~/utils"
import { getLocation } from "~/utils/helpers"
import { EsProperty, PrivateListing } from "~/generated/graphql";

const assessmentNames = {
  Commercial: "commercial property",
  Condo: "condo",
  Residential: "home",
}

interface IDisclaimerProps {
  property: EsProperty
  privateListing?: PrivateListing
}
export const Disclaimer: FC<IDisclaimerProps> = ({ property, privateListing }) => {
  const { address, city } = getLocation(property)

  const {
    province,
    livingArea,
    bedroomsTotal,
    bathroomsTotal,
    yearBuiltActual,
  } = privateListing || property
  const {
    assessmentClass,
    closePrice,
    closeDate,
    predictedDate,
    predictedValue,
    assessments,
  } = property


  const data = useMemo(() => {
    const sortedAssessments = assessments
    const firstAssessment = sortedAssessments[0]
    const lastAssessment = sortedAssessments[sortedAssessments.length - 1]
    const assessmentYears =
      lastAssessment && firstAssessment ? lastAssessment.year - firstAssessment.year : null
    const avgAppreciation =
      lastAssessment && firstAssessment
        ? (lastAssessment.value - firstAssessment.value) / firstAssessment.value / assessmentYears
        : null

    return {
      propertyType: assessmentNames[assessmentClass],
      interiorStats: livingArea && (bedroomsTotal || bathroomsTotal),
      hasLastSold: closePrice && closeDate,
      hasPredictedPrice: predictedValue && predictedDate && predictedValue >= 50_000,
      sortedAssessments,
      firstAssessment,
      lastAssessment,
      assessmentYears,
      avgAppreciation,
      annualGrowth: avgAppreciation ? `${(Math.abs(avgAppreciation) * 100).toFixed(2)}%` : null,
      totalGrowth:
        lastAssessment && firstAssessment ? lastAssessment.value - firstAssessment.value : null,
      airbnbRentalText: buildAirbnbRentalText(property),
      floorBedBathYearText: buildFloorBedBathYearText(property),
    }
  }, [property])

  function buildAirbnbRentalText(property: EsProperty) {
    const { airbnbActual, rentalActual } = property
    const airBnbDough = airbnbActual?.airbnbEstimate ? `$${airbnbActual.airbnbEstimate}` : null
    const rentalDough = rentalActual?.rentEstimate ? `$${rentalActual.rentEstimate}` : null
    if (airBnbDough && rentalDough) {
      return `It is estimated that it would make ${airBnbDough} per day on Airbnb and rent out for ${rentalDough} per month. `
    } else if (airBnbDough) {
      return `It is estimated that it would make ${airBnbDough} per day on Airbnb. `
    } else if (rentalDough) {
      return `It is estimated that it would rent out for ${rentalDough} per month. `
    } else {
      return " "
    }
  }

  function buildFloorBedBathYearText(property: EsProperty) {
    const {
      livingArea,
      livingAreaEst,
      bedroomsTotal,
      bedroomsTotalEst,
      bathroomsTotal,
      bathroomsTotalEst,
      yearBuilt,
      yearBuiltActual,
    } = property
    const floorSpace = livingArea ?? livingAreaEst
    const year = yearBuiltActual ?? yearBuilt
    const baths = bathroomsTotal ?? bathroomsTotalEst
    const beds = bedroomsTotal ?? bedroomsTotalEst
    const m = new Map<`${boolean}-${boolean}-${boolean}-${boolean}`, string>()
    m.set(
      `${true}-${true}-${true}-${true}`,
      `It is estimated to be ${floorSpace} sq ft and built in ${year} with ${formatBaths(
        baths,
        "and",
      )} baths and ${beds} bedrooms.`,
    )
    m.set(
      `${true}-${true}-${true}-${false}`,
      `It is estimated to be ${floorSpace} sq ft and built in ${year} with ${formatBaths(
        baths,
        "and",
      )} baths.`,
    )
    m.set(
      `${true}-${true}-${false}-${true}`,
      `It is estimated to be ${floorSpace} sq ft and built in ${year} with ${beds} bedrooms.`,
    )
    m.set(
      `${true}-${true}-${false}-${false}`,
      `It is estimated to be ${floorSpace} sq ft and built in ${year}.`,
    )
    m.set(
      `${true}-${false}-${true}-${true}`,
      `It is estimated to be ${floorSpace} sq ft with ${formatBaths(
        baths,
        "and",
      )} baths and ${beds} bedrooms.`,
    )
    m.set(
      `${true}-${false}-${true}-${false}`,
      `It is estimated to be ${floorSpace} sq ft with ${formatBaths(baths, "and")} baths.`,
    )
    m.set(
      `${true}-${false}-${false}-${true}`,
      `It is estimated to be ${floorSpace} sq ft with ${beds} bedrooms.`,
    )
    m.set(`${true}-${false}-${false}-${false}`, `It is estimated to be ${floorSpace} sq ft.`)

    m.set(
      `${false}-${true}-${true}-${true}`,
      `It is estimated to have been built in ${year} with ${formatBaths(
        baths,
        "and",
      )} baths and ${beds} bedrooms.`,
    )
    m.set(
      `${false}-${true}-${true}-${false}`,
      `It is estimated to have been built in ${year} with ${formatBaths(baths, "and")} baths.`,
    )
    m.set(
      `${false}-${true}-${false}-${true}`,
      `It is estimated to have been built in ${year} with ${beds} bedrooms.`,
    )
    m.set(`${false}-${true}-${false}-${false}`, `It is estimated to have been built in ${year}.`)
    m.set(
      `${false}-${false}-${true}-${true}`,
      `It is estimated to have ${formatBaths(baths, "and")} baths and ${beds} bedrooms.`,
    )
    m.set(
      `${false}-${false}-${true}-${false}`,
      `It is estimated to have ${formatBaths(baths, "and")} baths.`,
    )
    m.set(`${false}-${false}-${false}-${true}`, `It is estimated to have ${beds} bedrooms.`)
    m.set(`${false}-${false}-${false}-${false}`, "")

    return m.get(`${Boolean(floorSpace)}-${Boolean(year)}-${Boolean(baths)}-${Boolean(beds)}`)
  }

  return (
    <Text variant={TextVariants.body2} color="gray.500">
      <span>
        {formatStreet(address)} is a {data.propertyType} in {city},&nbsp;
        {province}.
      </span>
      &nbsp;
      {<span>{data.floorBedBathYearText} </span>}
      {data.interiorStats && (
        <span>
          This {livingArea.toFixed(0)} square foot {data.propertyType}&nbsp;
          {yearBuiltActual && `was built in ${yearBuiltActual} and `}
          features&nbsp;
          {bedroomsTotal && `${bedroomsTotal} bedrooms and`}&nbsp;
          {bathroomsTotal && `${bathroomsTotal} bathrooms`}.&nbsp;
        </span>
      )}
      {data.hasLastSold && (
        <span>
          It last sold for {`$${closePrice.toLocaleString()}`} on&nbsp;
          {format(new Date(closeDate), "MMM dd, yyyy")}.&nbsp;
        </span>
      )}
      {data.hasPredictedPrice && (
        <span>
          The most recent HonestDoor Price for this {data.propertyType}
          &nbsp;is&nbsp;
          {massageValuationValueText(predictedValue)} and was last estimated on&nbsp;
          {format(new Date(predictedDate), "MMM dd, yyyy")}.&nbsp;
        </span>
      )}
      {data.annualGrowth && data.totalGrowth && (
        <span>
          Assessment growth for this {data.propertyType} is&nbsp;
          {data.totalGrowth < 0 ? "-" : "+"}
          {data.annualGrowth} annually, with a total growth of&nbsp;
          {data.totalGrowth < 0 ? "-" : "+"}${Math.abs(data.totalGrowth).toLocaleString()}
          &nbsp;since&nbsp;
          {data.firstAssessment.year}
          .&nbsp;
        </span>
      )}
      {data.airbnbRentalText && <span>{data.airbnbRentalText}</span>}
      {province === "Nova Scotia" && (
        <Text mt="1em">
          This product includes data which is the property of the Province of Nova Scotia. Copyright
          of the data and all intellectual property rights in the data shall at all times remain the
          property of the Province of Nova Scotia. All rights reserved.
        </Text>
      )}
    </Text>
  )
}
