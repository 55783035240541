import { useRef } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import BeatLoader from "react-spinners/BeatLoader"
import {
  NotificationSubscription,
  NotificationSubscriptionEvent,
  NotificationSubscriptionStatus,
  useGetMyNotificationSubscriptionsQuery,
  useRemoveNotificationSubscriptionMutation,
  useUpdateNotificationSubscriptionMutation
} from "~/generated/graphql"
import { NotificationsPageProps } from "~/pages/notifications/settings"

import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Button,
  Container,
  Heading,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"

const NotificationSettings = (props: NotificationsPageProps): JSX.Element => {
  const { base64email } = props
  const email = Buffer.from(base64email, "base64").toString("ascii")
  const { data, isLoading, refetch } = useGetMyNotificationSubscriptionsQuery()

  const propertySubs = data?.getMyNotificationSubscriptions
    ?.filter((sub: NotificationSubscription) => sub.eventType === NotificationSubscriptionEvent.PropertyUpdated)
    .sort((a: NotificationSubscription, b: NotificationSubscription) => {
      if (a.status === NotificationSubscriptionStatus.Active && b.status === NotificationSubscriptionStatus.Inactive) {
        return -1
      }
      if (a.status === NotificationSubscriptionStatus.Inactive && b.status === NotificationSubscriptionStatus.Active) {
        return 1
      }
      return 0
    })

  const listingSubs = data?.getMyNotificationSubscriptions
    ?.filter((sub: NotificationSubscription) => sub.eventType === NotificationSubscriptionEvent.ListingsCreated)
    .sort((a: NotificationSubscription, b: NotificationSubscription) => {
      if (a.status === NotificationSubscriptionStatus.Active && b.status === NotificationSubscriptionStatus.Inactive) {
        return -1
      }
      if (a.status === NotificationSubscriptionStatus.Inactive && b.status === NotificationSubscriptionStatus.Active) {
        return 1
      }
      return 0
    })

  return (
    <Container my={["12", null, "24"]} maxW="container.md">
      <VStack spacing={14} align={"start"} w={"full"}>
        <Heading as={"h1"}>Notification Settings</Heading>
        {isLoading && <Spinner />}
        {!isLoading && (
          <VStack spacing={8} align={"start"} w={"full"}>
            <SubscriptionSection
              eventType={NotificationSubscriptionEvent.PropertyUpdated}
              subscriptions={propertySubs}
              email={email}
              onUpdate={refetch}
            />
            <SubscriptionSection
              eventType={NotificationSubscriptionEvent.ListingsCreated}
              subscriptions={listingSubs}
              email={email}
              onUpdate={refetch}
            />
          </VStack>
        )}
      </VStack>
    </Container>
  )
}

type SubscriptionSectionProps = {
  eventType: NotificationSubscriptionEvent
  subscriptions: NotificationSubscription[]
  email: string
  onUpdate: () => void
}

const SubscriptionSection = (props: SubscriptionSectionProps): JSX.Element => {
  const { eventType, subscriptions, email, onUpdate } = props

  const title =
    eventType === NotificationSubscriptionEvent.PropertyUpdated
      ? "Property Subscriptions"
      : "Listing Subscriptions"

  return (
    <Stack spacing="5" w={"100%"}>
      <Stack spacing="1">
        <Heading as={"h2"} fontSize={"lg"}>
          {title}
        </Heading>
      </Stack>
      {subscriptions?.length ? (
        <VStack align={"start"} spacing={2}>
          {subscriptions?.map(sub => (
            <SubscriptionListItem
              key={sub.id}
              subscription={sub}
              email={email}
              onUpdate={onUpdate}
            />
          ))}
        </VStack>
      ) : (
        <Alert status={"info"}>
          <AlertIcon />
          No {title.toLocaleLowerCase()} found.
        </Alert>
      )}
    </Stack>
  )
}

type SubscriptionListItemProps = {
  subscription: NotificationSubscription
  email: string
  onUpdate: () => void
}

const SubscriptionListItem = (props: SubscriptionListItemProps): JSX.Element => {
  const { subscription, onUpdate } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    mutateAsync: updateNotificationSubscription,
    isLoading: updateLoading,
  } = useUpdateNotificationSubscriptionMutation()
  const {
    mutateAsync: removeNotificationSubscription,
    isLoading: deleteLoading,
  } = useRemoveNotificationSubscriptionMutation()

  const handleDisable = async (): Promise<void> => {
    await updateNotificationSubscription({
      id: subscription.id,
      input: {
        status: NotificationSubscriptionStatus.Inactive,
      },
    })

    return onUpdate()
  }

  const handleEnable = async (): Promise<void> => {
    await updateNotificationSubscription({
      id: subscription.id,
      input: {
        status: NotificationSubscriptionStatus.Active,
      },
    })

    return onUpdate()
  }

  const handleDelete = async (): Promise<void> => {
    await removeNotificationSubscription({
      id: subscription.id,
    })

    return onUpdate()
  }

  const event = eventTypeToBadge(subscription.eventType)
  const isActive = subscription.status === NotificationSubscriptionStatus.Active

  return (
    <Box borderWidth={"1px"} p={{ base: 2, md: 4 }} borderRadius="lg" w={"100%"} shadow={"sm"}>
      <Stack justify="space-between" direction={"row"} spacing="5" align={"center"}>
        <Box fontSize="sm" opacity={!isActive && 0.35}>
          <Text fontSize={"md"} fontWeight="medium" noOfLines={1}>
            {subscription.name}
          </Text>
          {event && (
            <Badge variant={"solid"} rounded={"md"} colorScheme={"hdMoney"} textTransform={"none"}>
              {event}
            </Badge>
          )}
        </Box>
        <Stack spacing="3" direction={{ base: "column", md: "row" }}>
          <Button
            size={"sm"}
            variant={"solid"}
            colorScheme={isActive ? "hdFire" : "hdMoney"}
            onClick={isActive ? handleDisable : handleEnable}
            isLoading={updateLoading}
            isDisabled={updateLoading || deleteLoading}
            spinner={<BeatLoader size={6} color={"white"} />}
          >
            {isActive ? "Disable" : "Enable"}
          </Button>
          <IconButton
            size={"sm"}
            icon={<AiOutlineDelete />}
            aria-label="delete"
            onClick={onOpen}
            isDisabled={updateLoading || deleteLoading}
          />
        </Stack>
      </Stack>
      <DeleteConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
        isLoading={deleteLoading}
        isDisabled={deleteLoading}
      />
    </Box>
  )
}

type DeleteConfirmDialogProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
  isDisabled: boolean
}

const DeleteConfirmDialog = (props: DeleteConfirmDialogProps) => {
  const { isOpen, onClose, onConfirm, isLoading, isDisabled } = props
  const cancelRef = useRef()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      trapFocus={isLoading || isDisabled}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Subscription?
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure you want to delete this subscription?</AlertDialogBody>

          <AlertDialogFooter>
            <Button size={"sm"} ref={cancelRef} onClick={onClose} isDisabled={isDisabled}>
              Cancel
            </Button>
            <Button
              size={"sm"}
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
              isLoading={isLoading}
              isDisabled={isDisabled}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const eventTypeToBadge = (eventType: NotificationSubscriptionEvent): string => {
  switch (eventType) {
    case NotificationSubscriptionEvent.PropertyUpdated:
      return "HonestDoor Price"
    case NotificationSubscriptionEvent.ListingsCreated:
      return "New Listings"
    default:
      return null
  }
}

export default NotificationSettings
