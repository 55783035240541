import {
  avgAssessedWithYearFormatter,
  getFormattedStatData,
  growthRateWithYearFormatter,
  transactionWithYearFormatter,
} from "~/components/modules/top-stat/TopStat.helpers"

export class NeighbourhoodStats {
  private _rawGrowth: Record<string, any>[]
  private _rawAvgAssessed: Record<string, any>[]
  private _rawTransactions: Record<string, any>[]
  private _rawPredictionData: Record<string, any>[]
  private _rawRentalEstimate: Record<string, any>[]
  private _rawDolSqft: Record<string, any>[]

  constructor(neighbourhoodStats: Record<string, any>) {
    this._rawGrowth = neighbourhoodStats.filter(
      datum => datum.growthRateRank,
    )

    this._rawAvgAssessed = neighbourhoodStats?.filter(
      datum => datum.avgAssessedRank,
    )

    this._rawTransactions = neighbourhoodStats?.filter(
      datum => datum.transactionCountRank,
    )

    this._rawPredictionData = neighbourhoodStats?.filter(
      datum => datum.currentPredictedValue,
    )

    this._rawRentalEstimate = neighbourhoodStats?.filter(
      datum => datum.rentalEstimate,
    )

    this._rawDolSqft = neighbourhoodStats?.filter(
      datum => datum.dollarPerSqft,
    )
  }

  get rawGrowth(): Record<string, any>[] {
    return this._rawGrowth
  }
  get rawAvgAssessed(): Record<string, any>[] {
    return this._rawAvgAssessed
  }
  get rawTransactions(): Record<string, any>[] {
    return this._rawTransactions
  }
  get rawPredicted(): Record<string, any>[] {
    return this._rawPredictionData.filter(datum => datum.year == new Date().getFullYear())
  }
  get rawRentalEstimate(): Record<string, any>[] {
    return this._rawRentalEstimate.filter(datum => datum.year == new Date().getFullYear())
  }
  get rawDolSqft(): Record<string, any>[] {
    return this._rawDolSqft.filter(datum => datum.year == new Date().getFullYear())
  }

  get formattedGrowth(): Record<string, any> {
    const growthWithYear = this._rawGrowth.map(growthRateWithYearFormatter)
    return getFormattedStatData(growthWithYear)
  }

  get formattedAvgAssessed(): Record<string, any> {
    const avgAssessedWithYear = this._rawAvgAssessed.map(avgAssessedWithYearFormatter)
    return getFormattedStatData(avgAssessedWithYear)
  }

  get formattedTransactions(): Record<string, any> {
    const transactionsWithYear = this._rawTransactions.map(transactionWithYearFormatter)
    return getFormattedStatData(transactionsWithYear)
  }

  get formattedPredicted(): Record<string, any> {
    const condo = this._rawPredictionData
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Condo")
    const residential = this._rawPredictionData
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Residential")

    const result = {
      Condo: condo
        ? (({ currentPredictedValue, pricePercentDifference }) => ({
            currentPredictedValue,
            pricePercentDifference,
          }))(condo)
        : [],
      Residential: residential
        ? (({ currentPredictedValue, pricePercentDifference }) => ({
            currentPredictedValue,
            pricePercentDifference,
          }))(residential)
        : [],
    }

    if (
      Object.keys(result["Condo"]).length == 0 &&
      Object.keys(result["Residential"]).length == 0
    ) {
      return null
    }

    return result
  }

  get formattedRentalEstimate(): Record<string, any> {
    const condo = this._rawRentalEstimate
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Condo")

    const residential = this._rawRentalEstimate
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Residential")

    const result = {
      Condo: condo
        ? (({ rentalEstimate }) => ({
            rentalEstimate,
          }))(condo)
        : [],
      Residential: residential
        ? (({ rentalEstimate }) => ({
            rentalEstimate,
          }))(residential)
        : [],
    }

    if (
      Object.keys(result["Condo"]).length == 0 &&
      Object.keys(result["Residential"]).length == 0
    ) {
      return null
    }

    return result
  }

  get formattedDolSqft(): Record<string, any> {
    const condo = this._rawDolSqft
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Condo")

    const residential = this._rawDolSqft
      .filter(datum => datum.year == new Date().getFullYear())
      .find(d => d.assessmentClass == "Residential")

    const result = {
      Condo: condo
        ? (({ dollarPerSqft }) => ({
            dollarPerSqft,
          }))(condo)
        : [],
      Residential: residential
        ? (({ dollarPerSqft }) => ({
            dollarPerSqft,
          }))(residential)
        : [],
    }

    if (
      Object.keys(result["Condo"]).length == 0 &&
      Object.keys(result["Residential"]).length == 0
    ) {
      return null
    }

    return result
  }
}
