import { FC } from "react"
import { useSession } from "next-auth/react"
import {
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { InputControl, SelectControl, SubmitButton, TextareaControl } from "formik-chakra-ui"
import * as Yup from "yup"
import startCase from "lodash/startCase"
import toLower from "lodash/toLower"
import { HonestDoorLogoSvg } from "~/components/logos"
import gtag from "~/utils/lib/gtag"
import { ProvinceName } from "~/utils"
import { provinces } from "~/utils/constants"
import { ButtonVariants } from "~/theme/components"
import { useCreateContactFormSubmissionMutation } from "~/generated/graphql"
import { ContactFormSubmissionType } from "~/generated/graphql"

const formSchema = Yup.object().shape({
  Name: Yup.string().required(),
  Email: Yup.string().email().required(),
  Province: Yup.string().required(),
  Message: Yup.string().min(10).required(),
})

interface IBuyingOrSellingCTAModalProps {
  province: ProvinceName | string
  address: string
  isOpen: boolean
  onClose: () => void
}

export const BuyingOrSellingCTAModal: FC<IBuyingOrSellingCTAModalProps> = ({
  province,
  address,
  isOpen,
  onClose,
}) => {
  const { data } = useSession()
  const toast = useToast()
  const { isLoading, mutateAsync: sendContactSubmission } = useCreateContactFormSubmissionMutation()

  const formInitialValues = {
    Name: data?.user?.name ?? "",
    Email: data?.user?.email ?? "",
    Province: province ?? "",
    Address: address ? startCase(toLower(address)) : "",
    Message: "",
  }

  const onSubmit = async (values: typeof formInitialValues) => {
    try {
      await sendContactSubmission({
        input: {
          email: values.Email,
          name: values.Name,
          message: values.Message,
          type: ContactFormSubmissionType.AgentConnectRequest,
          additionalData: {
            province: values.Province,
            address: values.Address,
          },
        },
      })
      toast({
        title: "Message sent!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      })
    } catch (e) {
      toast({
        title: "Something went wrong, please try again",
        status: "error",
        duration: 5000,
        position: "top",
      })
    } finally {
      gtag.event({
        action: "submitted ask for intro",
        category: "right sidebar",
      })
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex w="100%" justify="center">
            <HonestDoorLogoSvg />
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="flex-start" spacing={8}>
            <Heading fontSize={"lg"}>Ask Us About This Property</Heading>
            <Text>
              Have questions about this property or any other property?
              <br />
              Feel free to ask us.
            </Text>
            <Formik
              initialValues={formInitialValues}
              onSubmit={onSubmit}
              validationSchema={formSchema}
            >
              {({ handleSubmit, errors }) => (
                <>
                  <InputControl name="Name" label="Name" />
                  <InputControl name="Email" label="Email" />
                  <SelectControl name="Province" label="Province">
                    {Object.values(provinces).map((p, i) => {
                      return (
                        <option value={p} key={i}>
                          {p}
                        </option>
                      )
                    })}
                  </SelectControl>
                  <InputControl name="Address" label="Address" />
                  <TextareaControl
                    name="Message"
                    label="Message"
                    textareaProps={{ minH: "120px" }}
                  />
                  <Flex w={"100%"}>
                    <ButtonGroup mb={4}>
                      <SubmitButton
                        variant={ButtonVariants.HDMoneyRounded}
                        onClick={handleSubmit as any}
                        disabled={isLoading || Object.keys(errors).length > 0}
                        isLoading={isLoading}
                      >
                        Submit
                      </SubmitButton>
                    </ButtonGroup>
                  </Flex>
                </>
              )}
            </Formik>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
