import { FC } from "react"
import { Box, Card, Flex, Icon, Text } from "@chakra-ui/react"
import { FaEnvelopeOpenText } from "react-icons/fa"
import { CardVariants, TextVariants } from "~/theme/components"
import { GetPrivateListingsQuery, PrivateListing } from "~/generated/graphql"
import { formatPhoneNumber } from "~/utils"
import { PROPERTY_BOARDS } from "~/utils/constants"

export type ContactOwnerProps = {
  board: ValueOf<typeof PROPERTY_BOARDS>
  privateListing: PrivateListing | GetPrivateListingsQuery['getPrivateListings'][0]
}

export const ContactOwner: FC<ContactOwnerProps> = ({ privateListing }) => {
  return (
    <Card
      variant={CardVariants.CallToAction}
      position="relative"
      maxWidth={{ lg: "600px" }}
      margin="0 auto"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        backgroundColor="blue.500"
        width={1}
        height="100%"
      />
      <Flex>
        <Box px={[0, 0, "32px"]}>
          <Icon as={FaEnvelopeOpenText} fontSize={[20, 20, 64]} />
        </Box>

        <Box maxW="100%" pl={[4, 4, 0]}>
          <Text fontWeight="bold" as="span" variant={TextVariants.heading4}>
            Interested in this property?
          </Text>{" "}
          <Text fontWeight="bold" as="span" color="gold.500" variant={TextVariants.heading4}>
            Contact owner:
          </Text>
          {(privateListing.contactPhone || privateListing.user.phone) && (
            <Text>
              <a href={`tel:${privateListing.contactPhone || privateListing.user.phone}`}>
                {formatPhoneNumber(privateListing.contactPhone || privateListing.user.phone)}
              </a>
            </Text>
          )}
          <Text>
            <a href={`mailto:${privateListing.contactEmail || privateListing.user.email}`}>
              {privateListing.contactEmail || privateListing.user.email}
            </a>
          </Text>
        </Box>
      </Flex>
    </Card>
  )
}
