import { FC } from "react"
import { useRouter } from "next/router"
import { observer } from "mobx-react-lite"
import { Box, Breadcrumb, BreadcrumbItem, Button, HStack, IconButton, Text } from "@chakra-ui/react"
import { IoMapSharp } from "react-icons/io5"
import { FaArrowLeft } from "react-icons/fa"
import { ButtonVariants } from "~/theme/components/Button"
import InternalLink from "~/components/elements/InternalLink"
import { MapView } from "~/store/map"
import { NetworkName } from "../social-links"
import { ShareButton } from "../share-button"
import { useNavigation } from "~/components/hooks"
import { usePageModalStore } from "~/store/StoreProvider"
import { CreaListing, EsProperty, PrivateListing } from "~/generated/graphql"
import { PROPERTY_BOARDS } from "~/utils/constants"

export type TCrumbItem = {
  value: string
  href?: string
  noLink?: boolean
}

interface ISubNavbar {
  board: ValueOf<typeof PROPERTY_BOARDS>
  crumbItems: TCrumbItem[]
  span?: number
  slug?: string
  privateListing?: PrivateListing
  property?: EsProperty
  creaListing?: CreaListing
}

const SubNavbar: FC<ISubNavbar> = ({
  board,
  crumbItems,
  slug,
  privateListing,
  property,
  creaListing,
}) => {
  const filteredCrumbItems = crumbItems.filter(c => c.value && c.value.length)
  const { navigate } = useNavigation()
  const { onClose, isOpen } = usePageModalStore()
  const router = useRouter()
  const thisPageUrl = `https://honestdoor.com${router.asPath}`
  const NETWORK_URLS = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${thisPageUrl}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${thisPageUrl}`,
    twitter: `https://twitter.com/intent/tweet?text=${thisPageUrl}`,
    mail: `mailto:?&subject=Check%20this%20out&body=${thisPageUrl}`,
  }
  const networkNames = Object.keys(NETWORK_URLS) as NetworkName[]

  const handleMapBtnClick = () => {
    if (slug && (property || privateListing)) {
      navigate.map({
        state: {
          view: MapView.MAP,
          viewport: {
            center: [
              property?.location?.lon || privateListing?.longitude,
              property?.location?.lat || privateListing?.latitude,
            ],
            zoom: [16],
          },
          highlightedFeatures: [property?.id || privateListing?.propertyId],
        },
      })
    }

    if (creaListing) {
      navigate.map({
        state: {
          view: MapView.MAP,
          viewport: { center: [creaListing.location.lon, creaListing.location.lat], zoom: [16] },
          highlightedFeatures: [creaListing.meta.id],
        },
      })
    }
  }

  const handleCloseButton = () => {
    if (isOpen) {
      return onClose()
    }

    return router.back()
  }

  const backButtonComponent = (
    <IconButton
      variant={"ghost"}
      borderWidth={null}
      borderRadius={null}
      borderColor={null}
      backgroundColor={null}
      mr={"15px"}
      aria-label={"go back"}
      icon={<FaArrowLeft />}
      onClick={handleCloseButton}
    >
      Back
    </IconButton>
  )

  const mapButtonComponent = (
    <Button variant={ButtonVariants.Primary} onClick={handleMapBtnClick} leftIcon={<IoMapSharp />}>
      Map
    </Button>
  )

  const breadCrumbComponent = (
    <Breadcrumb color={"gray.600"} spacing={5}>
      {filteredCrumbItems.map((crumbItem, i) => {
        return crumbItem.noLink || !crumbItem.href ? (
          <BreadcrumbItem key={i}>
            <Text>{crumbItem.value}</Text>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={i}>
            <InternalLink href={crumbItem.href}>{crumbItem.value}</InternalLink>
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )

  return (
    <HStack w={"100%"} mb={"30px"} justifyContent={"space-between"} alignItems={"center"}>
      <Box w={"150px"}>
        {backButtonComponent}
        {mapButtonComponent}
      </Box>

      <Box display={["none", "none", "none", "inline-block"]}>{breadCrumbComponent}</Box>

      <Box pr={["15px", "15px", "0px"]}>
        <ShareButton
          board={board}
          networkNames={networkNames}
          dark
          urls={NETWORK_URLS}
          creaListing={creaListing}
          property={property}
        />
      </Box>
    </HStack>
  )
}

export default observer(SubNavbar)
