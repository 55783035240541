import { FC } from "react"
import { useRouter } from "next/router"
import { Box, Button, Flex, SimpleGrid, Text, Card } from "@chakra-ui/react"
import { AiOutlineRise } from "react-icons/ai"
import { FaHome } from "react-icons/fa"
import { FiArrowUpRight } from "react-icons/fi"
import theme from "~/theme"
import { ButtonVariants, CardVariants, TextVariants } from "~/theme/components"
import { WatchPriceButton } from "~/components/modules"
import { EsProperty } from "~/generated/graphql"
import gtag from "~/utils/lib/gtag"
import { PROPERTY_BOARDS } from "~/utils/constants"

interface ICallsToActionProps {
  board: ValueOf<typeof PROPERTY_BOARDS>
  property: EsProperty
}

export const CallsToAction: FC<ICallsToActionProps> = ({ board, property }) => {
  const router = useRouter()

  return (
    <SimpleGrid columns={[1, 1, 1, 2]} gap={6}>
      <Card variant={CardVariants.CallToAction} flexGrow={1} position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          backgroundColor="gold.500"
          width={1}
          height="100%"
        />
        <Flex>
          <FaHome size={20} color={theme.colors.gold["500"]} />
          <Text mb={4} ml={4} variant={TextVariants.heading4}>
            Update the HonestDoor Price
          </Text>
        </Flex>
        <Text mb={4}>Tell us why! Just renovated the kitchen? Developed your basement?</Text>
        <Button
          variant={ButtonVariants.Link}
          rightIcon={<FiArrowUpRight size={20} />}
          onClick={() => {
            if (board === PROPERTY_BOARDS.UNLISTED) {
              gtag.event({
                action: "Property_box_update_price",
              })
            }
            router.push(`/property/${encodeURIComponent(property.slug)}/edit`)
          }}
        >
          Update Price
        </Button>
      </Card>

      {/*<Card variant={CardVariants.CallToAction} flexGrow={1} position="relative">*/}
      {/*  <Box*/}
      {/*    position="absolute"*/}
      {/*    top={0}*/}
      {/*    left={0}*/}
      {/*    backgroundColor="green.500"*/}
      {/*    width={1}*/}
      {/*    height="100%"*/}
      {/*  />*/}
      {/*  <Flex>*/}
      {/*    <FaLandmark size={20} color={theme.colors.green["500"]} />*/}
      {/*    <Text mb={4} ml={4} variant={TextVariants.heading4}>*/}
      {/*      Need a mortgage?*/}
      {/*    </Text>*/}
      {/*  </Flex>*/}

      {/*  <Text mb={4}>*/}
      {/*    We&apos;ve partnered with Rocket Mortgage for mortgages, renewals, and lines of credit.*/}
      {/*  </Text>*/}
      {/*  <InternalLink*/}
      {/*    href="/rocket-mortgage"*/}
      {/*    linkProps={{ textAlign: "center" }}*/}
      {/*    onClick={() => {*/}
      {/*      if (board === PROPERTY_BOARDS.UNLISTED) {*/}
      {/*        gtag.event({*/}
      {/*          action: "Property_box_mortgage",*/}
      {/*        })*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Button variant={ButtonVariants.Link} rightIcon={<FiArrowUpRight size={20} />}>*/}
      {/*      Complete the 1-Minute Form*/}
      {/*    </Button>*/}
      {/*  </InternalLink>*/}
      {/*</Card>*/}

      <Card variant={CardVariants.CallToAction} flexGrow={1} position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          backgroundColor="blue.500"
          width={1}
          height="100%"
        />
        <Flex>
          <AiOutlineRise size={25} color={theme.colors.blue["500"]} />
          <Text mb={4} ml={3} variant={TextVariants.heading4}>
            Track the value of your home
          </Text>
        </Flex>

        <Text mb={4}>Receive monthly emails updating you on the value of your home.</Text>

        <Flex justifyContent="center">
          <WatchPriceButton
            variant="cta"
            property={property}
            unwatchedText="Watch Property"
            watchedText="Home Value is Tracked"
            onClick={() => {
              if (board === PROPERTY_BOARDS.UNLISTED) {
                gtag.event({
                  action: "Property_box_track_value",
                })
              }
            }}
          />
        </Flex>
      </Card>
    </SimpleGrid>
  )
}
