import { FC, useMemo } from "react"
import { SimpleGrid } from "@chakra-ui/react"
import { VendorAd } from "~/components/helpers/ads/VendorAd"
import { SectionContainer } from "~/components/elements"
import {
  BuyingOrSellingCTA,
  CallsToAction,
  Disclaimer,
  EstimatedRentalInformation,
  ImageGallery,
  MoreProperties,
  MortgageCTA,
  Navbar,
  NeighbourhoodStats,
  NeverMissAListingCTA,
  Permits,
  PriceHistory,
  PropertyAnalysis,
  PropertyDetails,
  SaleTaxHistory,
  SellWithHd,
  Summary,
} from "~/components/layouts/property/sections"
import { PROPERTY_BOARDS, PROPERTY_TYPES } from "~/utils/constants"
import { AdType, CreaListing } from "~/generated/graphql"
import { AdPageType, EsProperty, PrivateListing } from "~/generated/graphql"

interface IUnlistedPropertyLayout {
  property: EsProperty
  publicListing?: CreaListing
  privateListing?: PrivateListing
}

export const UnlistedPropertyLayout: FC<IUnlistedPropertyLayout> = ({
  property,
  publicListing,
  privateListing,
}) => {

  const show = useMemo(
    () => ({
      summary: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      vendorAd: Boolean(property.cityName),
      callsToAction: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      estimatedRental: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      neverMissAListingCTA:
        property.neighbourhood &&
        [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(property.assessmentClass),
      neighbourhoodStats: [
        PROPERTY_TYPES.RESIDENTIAL,
        PROPERTY_TYPES.CONDO,
        PROPERTY_TYPES.COMMERCIAL,
      ].includes(property.assessmentClass),
      moreProperties: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
      propertyAnalysis: [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.CONDO].includes(
        property.assessmentClass,
      ),
    }),
    [property],
  )

  return (
    <>
      <Navbar board={PROPERTY_BOARDS.UNLISTED} property={property} />
      {privateListing && privateListing.images.length > 0 && (
        <ImageGallery property={privateListing} />
      )}

      <SectionContainer showDivider={false}>
        {publicListing ? (
          <Summary.UnlistedMatched property={property} publicListing={publicListing} />
        ) : (
          <Summary.Unlisted property={property} />
        )}
      </SectionContainer>

      {/*{show.vendorAd && (*/}
      {/*  <SectionContainer showDivider={false}>*/}
      {/*    <VendorAd*/}
      {/*      variant={AdType.Leaderboard}*/}
      {/*      targeting={{*/}
      {/*        cityNames: [property.cityName],*/}
      {/*        provinceNames: [property.province],*/}
      {/*        pageTypes: [AdPageType.UnlistedProperty],*/}
      {/*      }}*/}
      {/*      bottomMargin*/}
      {/*    />*/}
      {/*  </SectionContainer>*/}
      {/*)}*/}

      <SectionContainer showDivider={false}>
        <SellWithHd board={PROPERTY_BOARDS.UNLISTED} property={property} />
      </SectionContainer>

      {show.callsToAction && (
        <SectionContainer showDivider={false}>
          <CallsToAction board={PROPERTY_BOARDS.UNLISTED} property={property} />
        </SectionContainer>
      )}

      <SectionContainer>
        <PropertyDetails board={PROPERTY_BOARDS.UNLISTED} property={property} />
      </SectionContainer>

      <SectionContainer>
        <MortgageCTA board={PROPERTY_BOARDS.UNLISTED} property={property} />
      </SectionContainer>

      <SectionContainer>
        <PriceHistory board={PROPERTY_BOARDS.UNLISTED} property={property} />
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid columns={[1, 1, 1, 2]} gap={[6, 6, 6, 12]}>
          <SaleTaxHistory property={property} />
          <Permits property={property} />
        </SimpleGrid>
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: show.estimatedRental ? 2 : 1 }}
          gap={[6, 6, 6, 12]}
        >
          {show.estimatedRental ? (
            <>
              <EstimatedRentalInformation property={property} />
              <BuyingOrSellingCTA
                board={PROPERTY_BOARDS.UNLISTED}
                province={property.province}
                address={property.unparsedAddress}
                variant="inline"
              />
            </>
          ) : (
            <BuyingOrSellingCTA
              board={PROPERTY_BOARDS.UNLISTED}
              province={property.province}
              address={property.unparsedAddress}
              variant="full"
            />
          )}
        </SimpleGrid>
      </SectionContainer>

      <SectionContainer>
        <SimpleGrid columns={{ base: 1, md: show.neverMissAListingCTA ? 2 : 1 }} gap={[6, 6, 0]}>
          {show.neverMissAListingCTA && (
            <NeverMissAListingCTA
              board={PROPERTY_BOARDS.UNLISTED}
              neighbourhoodId={property.neighbourhood}
              neighbourhoodName={property?.neighbourhoodName}
            />
          )}
          <VendorAd
            variant={AdType.InlineRectangle}
            targeting={{
              cityNames: [property.cityName],
              provinceNames: [property.province],
              pageTypes: [AdPageType.UnlistedProperty],
            }}
          />
        </SimpleGrid>
      </SectionContainer>

      {show.neighbourhoodStats && (
        <NeighbourhoodStats board={PROPERTY_BOARDS.UNLISTED} property={property} />
      )}

      {show.moreProperties && (
        <SectionContainer>
          <MoreProperties board={PROPERTY_BOARDS.UNLISTED} property={property} />
        </SectionContainer>
      )}

      {show.propertyAnalysis && (
        <SectionContainer>
          <PropertyAnalysis property={property} />
        </SectionContainer>
      )}

      <SectionContainer>
        <Disclaimer property={property} />
      </SectionContainer>
    </>
  )
}
