import { FC, useMemo } from "react";
import { Box, Button, Divider, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import capitalize from "lodash/capitalize";
import { FaQuestionCircle } from "react-icons/fa";
import { getAmenities, getLocation, getPrices } from "~/utils/helpers";
import { TextVariants } from "~/theme/components";
import { SummaryAmenities } from "./summary-amenities";
import { Tooltip, WatchPriceButton } from "~/components/modules";
import { InternalLink } from "~/components/elements";
import { CreaListing, EsProperty, PrivateListing, PrivateListingSaleType } from "~/generated/graphql";

export interface ISummaryPrivate {
  privateListing: PrivateListing
  property?: EsProperty
  publicListing?: CreaListing
}

export const SummaryPrivate: FC<ISummaryPrivate> = ({ privateListing, property, publicListing }) => {
  const isMobile = useBreakpointValue([true, true, false])
  const condenseSaleLabel = useBreakpointValue([false, false, true, false])

  const {
    addressFormattedMain,
    priceFormatted,
    propertyListedPriceFormatted,
    isForSale,
    bedrooms,
    bathrooms,
    floorSpace,
  } = useMemo(() => {
    const {
      addressFormattedFull,
      addressFormattedMain,
    } = getLocation(privateListing)
    const { bedrooms, bathrooms, floorSpace } = getAmenities(privateListing)
    const { estimatedPrice, estimatedPriceDate, listedPrice, isForSale } = getPrices(privateListing)
    const price = isForSale ? listedPrice : estimatedPrice

    let propertyValues = {
      propertyListedPriceFormatted: null,
    }
    if (property) {
      const { listedPrice: propertyListedPrice } = getPrices(property)
      propertyValues = {
        propertyListedPriceFormatted: propertyListedPrice
          ? propertyListedPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })
          : null,
      }
    }

    return {
      addressFormattedFull,
      addressFormattedMain,
      bedrooms,
      bathrooms,
      floorSpace,
      estimatedPrice,
      price,
      priceFormatted: price ? price.toLocaleString(undefined, { maximumFractionDigits: 2 }) : null,
      isForSale,
      month: estimatedPriceDate
        ? new Date(estimatedPriceDate).toLocaleString("default", { month: "long" })
        : undefined,
      ...propertyValues,
    }
  }, [privateListing, property])

  return (
    <Box
      bgColor="white"
      p={6}
      borderRadius="lg"
      borderColor="#C9CDD9"
      boxShadow="0px 4px 16px rgba(0, 0, 0, 0.12)"
      position="relative"
      overflow="hidden"
    >
      <Flex justifyContent={["center", "center", "space-between"]}>
        <Flex
          alignItems="center"
          justifyContent={["center", "center", "start"]}
          marginTop={["8px", "8px", 0]}
        >
          <Box
            width={2}
            height={2}
            borderRadius="full"
            bgColor={isForSale ? "green.500" : "red.500"}
            marginRight={2}
            flexShrink={0}
          />
          <Text noOfLines={1} variant={TextVariants.heading5}>
            {capitalize(
              (condenseSaleLabel ? "" : "this property is ") +
              (isForSale ? "" : "not ") +
              (privateListing.saleType.toLowerCase().replace(/_/g, " ")),
            )}
          </Text>
        </Flex>

        {property && (
          <Flex justifyContent="end" display={["none", "none", "flex"]}>
            {publicListing && (
              <Box>
                <InternalLink href={`/listing/${publicListing.slug}`} linkProps={{ mr: 2 }}>
                  <Button variant="secondary">View listing</Button>
                </InternalLink>
              </Box>
            )}
            <Box>
              <WatchPriceButton
                variant="listing"
                property={property}
                unwatchedText="Track Home Value"
                watchedText="Home Value is Tracked"
              />
            </Box>

            {/*
            <Box ml={2}>
              <InternalLink href={`/property/${property.slug}`}>
                <Button variant="secondary">View HonestDoor Data</Button>
              </InternalLink>
            </Box>
            */}
          </Flex>
        )}
      </Flex>

      <Flex
        justifyContent={["center", "center", "start", "space-between"]}
        direction={["column", "row", "row"]}
      >
        <Flex
          direction={["column", "column", "row", "row"]}
          alignItems={["center", "center", "start", "center"]}
          marginTop={"16px"}
        >
          <Text as="div" variant={TextVariants.heading1} lineHeight="38px">
            {priceFormatted ? `$${priceFormatted}${privateListing.saleType === PrivateListingSaleType.ForLease ? " / sqft" : ""}` : "N/A"}
          </Text>

          {propertyListedPriceFormatted && (
            <Flex
              flexDirection={["column", "column", "row"]}
              alignItems={["start", "start", "center"]}
              marginTop={["16px", "16px", 0, 0]}
            >
              <Flex flexDirection="row" alignItems="center" marginLeft={[0, 0, "21px"]}>
                <Text>HonestDoor Price</Text>
                <Tooltip
                  label={
                    "The HonestDoor Price is our estimate determined by our proprietary advanced statistical & machine learning methods. It is a starting point in determining a home's value and is not an official appraisal. Comparing your home to recently sold properties can also help you understand what your home is worth."
                  }
                  placement="right"
                  hasArrow
                >
                  <Box ml={2} color="gray.500">
                    <FaQuestionCircle size={15} />
                  </Box>
                </Tooltip>
              </Flex>

              <Box marginLeft={[0, 0, "21px"]}>
                <Text as="div" variant={TextVariants.heading1} lineHeight="38px" color="gold.500">
                  ${propertyListedPriceFormatted}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Divider marginY={6} />

      <Flex justifyContent="space-between" direction={["column", "column", "column", "row"]}>
        <Box>
          <Flex
            alignItems={["center", "center", "center", "start"]}
            direction={["column", "column", "row", "row"]}
            marginTop={"6px"}
            textAlign="center"
          >
            <Heading as="h1" fontSize="17px" fontWeight="normal">
              {addressFormattedMain}
            </Heading>
          </Flex>
        </Box>

        <Divider marginY={6} display={["block", "block", "block", "none"]} />

        <Flex
          justifyContent={["center", "center", "start", "end"]}
          ml={[0, 0, 0, 6]}
          mt={["8px", "8px", "8px", 0]}
        >
          <SummaryAmenities bedrooms={bedrooms} floorSpace={floorSpace} bathrooms={bathrooms} />
        </Flex>

        {property && <Divider marginY={6} display={["block", "block", "none"]} />}

        {isMobile && property && (
          <Flex direction={["column", "row"]} justifyContent="center" alignItems="center">
            <Box>
              <WatchPriceButton
                variant="listing"
                property={property}
                unwatchedText="Track Home Value"
                watchedText="Home Value is Tracked"
              />
            </Box>

            {/*<Box ml={[0, 2]} mt={[2, 0]}>*/}
            {/*  <InternalLink href={`/property/${property.slug}`}>*/}
            {/*    <Button variant="secondary">View HonestDoor Data</Button>*/}
            {/*  </InternalLink>*/}
            {/*</Box>*/}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
