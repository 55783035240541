import { FC, useMemo } from "react"
import { Box, Text, useBreakpointValue } from "@chakra-ui/react"
import { BsMoonStarsFill } from "react-icons/bs"
import { FaRegCalendarAlt } from "react-icons/fa"
import { TextVariants } from "~/theme/components"
import { SectionHeader } from "~/components/layouts/property/sections"
import { DividedGrid } from "~/components/elements"
import { EsProperty } from "~/generated/graphql"

const defaultIcons = {
  airbnb: <BsMoonStarsFill />,
  rental: <FaRegCalendarAlt />,
}

interface IEstimatedRentalInformationProps {
  property?: EsProperty
}
export const EstimatedRentalInformation: FC<IEstimatedRentalInformationProps> = ({ property }) => {
  const wrapRate = useBreakpointValue([true, true, false])

  const items = useMemo(() => {
    const airbnb = property?.airbnbActual?.airbnbEstimate
    const rental = property?.rentalActual?.rentEstimate
    return [
      {
        label: airbnb && "Airbnb Nightly Rate",
        value: `$${airbnb}/day`,
        icon: defaultIcons.airbnb,
      },
      {
        label: rental && "Monthly Rental Rate",
        value: `$${rental}/month`,
        icon: defaultIcons.rental,
      },
    ].filter(info => !!info.label)
  }, [property])

  if (items.length === 0) return null

  return (
    <div>
      <SectionHeader title="Estimated Rental Information" />
      <DividedGrid columns={[1, 1, 1]} hasDivider={false} gap={[5]}>
        {items.map(item => (
          <Box key={item.label} display="flex" flexWrap="wrap" alignItems="center">
            <Box width={6} height={6} display="flex" alignItems="center" justifyContent="center">
              {item.icon}
            </Box>
            <Text variant={TextVariants.body2} marginX={4}>
              {item.label}
            </Text>
            <Text
              variant={TextVariants.heading3}
              marginTop={wrapRate ? 4 : 0}
              width={wrapRate ? "100%" : undefined}
            >
              {item.value}
            </Text>
          </Box>
        ))}
      </DividedGrid>
    </div>
  )
}
